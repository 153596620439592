
// export function groupPostFisheryRecsByFishery(postFisheryRecs) {
//     const stateObj = {}
//     postFisheryRecs.forEach((eachRec) => {
//         // console.warn('groupPostFisheryRecsByFishery eachRec', eachRec);
//         if (eachRec.fishery) {
//             if (eachRec.fishery.routerPath) {
//                 const recRouterPath = eachRec.fishery.routerPath;
//                 // console.log('recRouterPath', recRouterPath)
//                 // console.log('eachRec.post', eachRec.post);
//                 if (stateObj.hasOwnProperty(recRouterPath)) {
//                     // stateObj[recRouterPath] = stateObj[recRouterPath].push(eachRec.post)
//                     // console.log('recRouterPath', recRouterPath);
//                     const routerPathPosts = stateObj[recRouterPath];
//                     const eachRecPost = eachRec.post;
//                     routerPathPosts.push(eachRecPost);
//                     stateObj[recRouterPath] = routerPathPosts;
//                     // console.log('routerPathPosts', routerPathPosts);
//                 } else {
//                     stateObj[recRouterPath] = [eachRec.post]
//                 }
//             }
//         }
//     });
//     // console.error('POST FISHERY stateObj', stateObj);
//     return stateObj;
// }

export async function groupPostFisheryRecsByFishery(postFisheryRecs) {
    const stateObj = {};

    for (let eachRec of postFisheryRecs) {
        // console.log('eachRec', eachRec);

        const fishery = await eachRec.fishery; // Resolve the promise

        if (fishery && fishery.routerPath) {
            const recRouterPath = fishery.routerPath;
            // console.log('recRouterPath', recRouterPath);

            const post = await eachRec.post; // Resolve the promise

            if (stateObj.hasOwnProperty(recRouterPath)) {
                stateObj[recRouterPath].push(post);
            } else {
                stateObj[recRouterPath] = [post];
            }
        }
    }
    // console.error('stateObj', stateObj);
    return stateObj;
}
