import React, {useContext, useEffect, useState} from "react";
import SubscriptionPreviewCard from "../components/cards/SubscriptionPreviewCard";
import {useLocation, useNavigate} from "react-router-dom";
import {Button, Box} from "@mui/material";
import {loadStripe} from '@stripe/stripe-js'
// import {devStateNow} from "../consts";
import {userSubDataObj, generateHash, updateAmpUserLastPaymentHash} from "../consts";
import BaseNavigateButton from "../components/links/BaseNavigateButton";
import DevStateContext from "../data/contexts/DevStateContext";


export default function UserSubPreviewScreen(props) {
    const [ampUser, setAmpUser] = useState({});
    const [subLength, setSubLength] = useState('');
    const [priceKey, setPriceKey] = useState('');
    // const [contextDevState, setContextDevState] = useState({})
    // const [devStateStatus, setDevStateStatus] = useState({})
    const [showLogs, setShowLogs] = useState(false);
    const [userSubData, setUserSubData] = useState(userSubDataObj);
    const [hash, setHash] = useState('');

    let navigate = useNavigate();

    // let navigate = useNavigate();
    let location = useLocation();

    const {devStateObj} = useContext(DevStateContext);

    // useEffect(() => { setShowLogs(devStateObj.showLogs);
    useEffect(() => {
        if (devStateObj?.showLogs) {
            setShowLogs(devStateObj.showLogs);
        }
    }, [devStateObj]);

    // useEffect(() => { console.log('location', location);
    useEffect(() => {
        if (showLogs) {
            console.log('location', location);
        }
    }, [location, showLogs]);

    // useEffect(() => {
    //     console.warn('showLogs', showLogs);
    // }, [showLogs]);

    // TODO - UPDATE AMPUSER REC WITH THIS FIELD,
    //  THEN USE THIS ON SUCCESS PAGE TO UPDATE AMPUSER WITH SUBSCRIPTION
    // useEffect(() => {
    //     const generate = async () => {
    //         const generatedHash = await generateHash(`${ampUser.id}${subLength}${priceKey}`);
    //         setHash(generatedHash);
    //     };
    //
    //     generate();
    // }, [ampUser]); // Re-run the effect if inputString changes

    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('hash', hash);
    //     }
    // }, [hash, showLogs]);

    // TODO - MAKE SURE PAYMENTS, PRODUCTS ARE STILL CORRECT & LIVE ON STRIPE SIDE
    const handleSubscribeClick = async e => {
        const generatedHash = await generateHash(`${ampUser.id}${subLength}${priceKey}`);
        // setHash(generatedHash);
        await updateAmpUserLastPaymentHash(ampUser, generatedHash);
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY)
        const {error} = await stripe.redirectToCheckout({
            lineItems: [{
                price: priceKey || 'missing price',
                quantity: 1
            }],
            mode: 'subscription',
            // successUrl: 'http://localhost:3000/subscription-success',
            // successUrl: `${devStateNow.stripeSuccessURL}`,
            // successUrl: `${devStateObj?.stripeSuccessURL}`,
            // successUrl: `${devStateObj?.stripeSuccessURL}?session_id=${hash}`,
            successUrl: `${devStateObj?.stripeSuccessURL}?session_id=${generatedHash}`,
            // cancelUrl: 'http://localhost:3000/subscription-cancel',
            // cancelUrl: `${devStateNow.stripeCancelURL}`,
            cancelUrl: `${devStateObj?.stripeCancelURL}`,
            clientReferenceId: ampUser.id,
            customerEmail: ampUser.email
            // metadata: {ampUserID: ampUser ? ampUser.id : 'ampUserID'}
        })
    }

    // useEffect( setAmpUser(props.ampUser);
    useEffect(() => {
        if (props.ampUser) {
            setAmpUser(props.ampUser);
        }
    }, [props]);

    // useEffect( setAmpUser(ampUserRec);
    // useEffect(() => {
    //     if (ampUserRec) {
    //         setAmpUser(ampUserRec);
    //     }
    // }, []);

    // useEffect( console.log('UserSubPreviewScreen(props) ampUser', ampUser);
    useEffect(() => {
        if (showLogs) {
            console.log('UserSubPreviewScreen(props) ampUser', ampUser);
        }
    }, [ampUser, showLogs]);

    // useEffect( setSubLength(location.state.subLength);
    useEffect(() => {
        if (location.state?.subLength) {
            setSubLength(location.state.subLength);
            // TODO - START HERE, USE ENV VARS FOR THESE TOO
            switch (location.state.subLength) {
                // case 'year':
                case userSubData.Year.subText:
                    setPriceKey(process.env.REACT_APP_STRIPE_YEAR_PRICE_KEY);
                    break
                // case 'quarter':
                case userSubData.Quarter.subText:
                    setPriceKey(process.env.REACT_APP_STRIPE_QUARTER_PRICE_KEY);
                    break
                case userSubData.Month.subText:
                    setPriceKey(process.env.REACT_APP_STRIPE_MONTH_PRICE_KEY);
                    break
                default:
                    setPriceKey(null)
                    break
            }
        }
    }, [location]);

    // useEffect( console.log('UserSubPreviewScreen(props) subLength', subLength);
    useEffect(() => {
        if (showLogs) {
            console.log('UserSubPreviewScreen(props) subLength', subLength);
        }
    }, [subLength, showLogs]);

    // TODO - make sure correct values are passed to card
    return (
        <div style={{height: '90vh'}}>
            <div>
                <h2>Subscription Preview</h2>
            </div>
            <div className={'Subscribe-preview-card'}>
                <SubscriptionPreviewCard
                    showLogs={showLogs}
                    ampUser={ampUser}
                    subLength={subLength}
                />
                {/*<div>*/}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 0, p: 0 }}>
                <Button
                    variant="contained"
                    onClick={handleSubscribeClick}
                    sx={{
                        textTransform: 'none',
                        fontSize: ['5vw', '3vw', '2vw', '1.5vw'],
                        // margin: '1rem',
                        backgroundColor: '#5F9EA0',
                        mt: '1rem', // margin-top
                        mb: '1rem', // margin-bottom
                        // Remove horizontal margins
                        mr: 0, // margin-right
                        ml: 0, // margin-left
                    }}
                >
                    Subscribe
                </Button>
                <BaseNavigateButton
                    navTarget={'/subscription-cancel'}
                    label='Cancel'
                />
                    </Box>
                {/*</div>*/}
            </div>
        </div>
    )
}