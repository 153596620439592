/**
 *
 * @param {Array} portRecs
 * @returns {{}}
 */
export function groupPortsByRegion(portRecs) {
    const portsByRegion = {};
    portRecs.forEach((eachPort) => {
       const portRegionId = eachPort.regionID;
        if (portsByRegion.hasOwnProperty(portRegionId)) {
            const regionPorts = portsByRegion[portRegionId];
            regionPorts.push(eachPort);
            portsByRegion[portRegionId] = regionPorts;
        } else {
            portsByRegion[portRegionId] = [eachPort];
        }
    });
    return portsByRegion;
}

/**
 *
 * @param {Array} portRecs
 * @returns {{}}
 */
export function groupPortNamesByRegion(portRecs) {
    const portNamesByRegion = {};
    portRecs.forEach((eachPort) => {
        const portRegionId = eachPort.regionID;
        if (portNamesByRegion.hasOwnProperty(portRegionId)) {
            const regionPorts = portNamesByRegion[portRegionId];
            regionPorts.push(eachPort.name);
            portNamesByRegion[portRegionId] = regionPorts;
        } else {
            portNamesByRegion[portRegionId] = [eachPort.name];
        }
    });
    return portNamesByRegion;
}

export function groupPostPortRecsByPort(postPortRecs) {
    const stateObj = {}
    postPortRecs.forEach((eachRec) => {
        // console.warn('eachRec', eachRec);
        if (eachRec.port) {
            if (eachRec.port.routerPath) {
                const recRouterPath = eachRec.port.routerPath;
                // console.log('recRouterPath', recRouterPath)
                // console.log('eachRec.post', eachRec.post);
                if (stateObj.hasOwnProperty(recRouterPath)) {
                    // stateObj[recRouterPath] = stateObj[recRouterPath].push(eachRec.post)
                    // console.log('recRouterPath', recRouterPath);
                    const routerPathPosts = stateObj[recRouterPath];
                    const eachRecPost = eachRec.post;
                    routerPathPosts.push(eachRecPost);
                    stateObj[recRouterPath] = routerPathPosts;
                    // console.log('routerPathPosts', routerPathPosts);
                } else {
                    stateObj[recRouterPath] = [eachRec.post]
                }
            }
        }
    });
    // console.error('POST PORT stateObj', stateObj);
    return stateObj;
}