// Import necessary libraries and components
import React from 'react';
import GearTypeDataProvider from '../../data/providers/GearTypeDataProvider';
import RegionDataProvider from '../../data/providers/RegionDataProvider';
import FisheryDataProvider from '../../data/providers/FisheryDataProvider';
import PortDataProvider from '../../data/providers/PortDataProvider';
import {RequireAuth} from '../auth/RequireAuth';
import {RequireSub} from '../auth/RequireSub';
import CreatePostScreen from '../../screens/CreatePostScreen';
import DevStateProvider from "../../data/providers/DevStateProvider";

// Define the CreatePostWrapper component
const CreatePostWrapper = ({ routerPaths, ampUser }) => {
    return (
        <DevStateProvider>
        <GearTypeDataProvider>
            <RegionDataProvider>
                <FisheryDataProvider>
                    <PortDataProvider>
                        <RequireAuth>
                            <RequireSub ampUser={ampUser}>
                                <CreatePostScreen routerPaths={routerPaths} ampUser={ampUser} />
                            </RequireSub>
                        </RequireAuth>
                    </PortDataProvider>
                </FisheryDataProvider>
            </RegionDataProvider>
        </GearTypeDataProvider>
        </DevStateProvider>
    );
}

// Export the component
export default CreatePostWrapper;
