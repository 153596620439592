
// export function groupPostPortRecsByPort(postPortRecs) {
//     const stateObj = {}
//     postPortRecs.forEach((eachRec) => {
//         // console.warn('groupPostPortRecsByPort eachRec', eachRec);
//         if (eachRec.port) {
//             if (eachRec.port.routerPath) {
//                 const recRouterPath = eachRec.port.routerPath;
//                 // console.log('recRouterPath', recRouterPath)
//                 // console.log('eachRec.post', eachRec.post);
//                 if (stateObj.hasOwnProperty(recRouterPath)) {
//                     // console.warn('stateObj.hasOwnProperty(recRouterPath)', recRouterPath)
//                     // stateObj[recRouterPath] = stateObj[recRouterPath].push(eachRec.post)
//                     // console.log('recRouterPath', recRouterPath);
//                     const routerPathPosts = stateObj[recRouterPath];
//                     // console.log('routerPathPosts', routerPathPosts);
//                     const eachRecPost = eachRec.post;
//                     routerPathPosts.push(eachRecPost);
//                     stateObj[recRouterPath] = routerPathPosts;
//                     // console.log('routerPathPosts', routerPathPosts);
//                 } else {
//                     stateObj[recRouterPath] = [eachRec.post]
//                 }
//             }
//         }
//     });
//     // console.error('POST PORT stateObj', stateObj);
//     return stateObj;
// }

export async function groupPostPortRecsByPort(postPortRecs) {
    const stateObj = {};

    for (let eachRec of postPortRecs) {
        // console.log('eachRec', eachRec);

        const port = await eachRec.port; // Resolve the promise

        if (port && port.routerPath) {
            const recRouterPath = port.routerPath;
            // console.log('recRouterPath', recRouterPath);

            const post = await eachRec.post; // Resolve the promise

            if (stateObj.hasOwnProperty(recRouterPath)) {
                stateObj[recRouterPath].push(post);
            } else {
                stateObj[recRouterPath] = [post];
            }
        }
    }
    // console.error('stateObj', stateObj);
    return stateObj;
}
