import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Country } from '../../models';
import CountryContext from "../contexts/CountryContext";

const CountryDataProvider = ({ children }) => {
    const [countryRecs, setCountryRecs] = useState([]);

    useEffect(() => {
        const subscription = DataStore.observeQuery(Country)
            .subscribe(msg => {
                // Update the posts data
                setCountryRecs(msg.items);
            });

        // Remember to unsubscribe when the component unmounts
        return () => subscription.unsubscribe();
    }, []);

    return (
        <CountryContext.Provider value={{ countryRecs: countryRecs }}>
            {children}
        </CountryContext.Provider>
    );
};

export default CountryDataProvider;
