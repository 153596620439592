import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { SortDirection } from "aws-amplify";
import { Port } from '../../models';
import PortContext from "../contexts/PortContext";

const PortDataProvider = ({ children }) => {
    const [portRecs, setPortRecs] = useState([]);

    useEffect(() => {
        const subscription = DataStore.observeQuery(Port,
                p => p,
            {
                sort: s => s.name(SortDirection.ASCENDING)}
            )
            .subscribe(msg => {
                const { items, isSynced } = msg;
                // console.log(`Port pr [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                // console.log('Port pr [Snapshot] items', items);
                setPortRecs(msg.items);
            });

        // Remember to unsubscribe when the component unmounts
        return () => subscription.unsubscribe();
    }, []);

    return (
        <PortContext.Provider value={{ portRecs: portRecs }}>
            {children}
        </PortContext.Provider>
    );
};

export default PortDataProvider;
