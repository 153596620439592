import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { SortDirection } from "aws-amplify";
import {Post, PostGearType} from '../../models';
import PostGearTypeContext from "../contexts/PostGearTypeContext";

const PostGearTypeDataProvider = ({ children, gearTypeId }) => {
    const [postGearTypeRecs, setPostGearTypeRecs] = useState([]);

    useEffect(() => {
        // console.log('PostGearTypeDataProvider gearTypeId', gearTypeId);
        if (gearTypeId) {
            const subscription = DataStore.observeQuery(PostGearType,
                pr => pr.and(pr => [
                    pr.gearTypeId.eq(gearTypeId),
                ]),
                {
                    sort: s => s.createdAt(SortDirection.ASCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    // console.log(`PostGearType pr [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                    // console.log('PostGearType pr [Snapshot] items', items);
                    if (isSynced) {
                        // Filter the items based on the related model's postStatus
                        const filteredItems = await Promise.all(items.filter(async item => {
                            const post = await DataStore.query(Post, item.postID);
                            return post.postStatus === "LIVE";
                        }));
                        // Update the posts data
                        setPostGearTypeRecs(filteredItems);
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
    }, [gearTypeId]); // Dependency array now includes gearTypeId

    return (
        <PostGearTypeContext.Provider value={{ postGearTypeRecs: postGearTypeRecs }}>
            {children}
        </PostGearTypeContext.Provider>
    );
};

export default PostGearTypeDataProvider;
