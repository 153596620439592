import React, {useState, useEffect} from 'react';
import { DataStore } from '@aws-amplify/datastore';
import SubPaymentContext from "../contexts/SubPaymentContext";
import {SubPayment, SubPaymentStatus, UserSub} from '../../models';
import {subPaymentModelIsExpired, updateSubPaymentStatus} from "../../consts";
import {SortDirection} from "aws-amplify";

// TODO - MAKE SURE THIS RESETS ON SIGNOUT
const SubPaymentDataProvider = ({ children }) => {
    const [ampUserRecSubPayment, setAmpUserRecSubPayment] = useState(null);
    const [currentSubPaymentRec, setCurrentSubPaymentRec] = useState(null);
    const [ampUserSubPaymentRecs, setAmpUserSubPaymentRecs] = useState([]);
    const [showLogsSubPayment, setShowLogsSubPayment] = useState(false);
    const [ampUserUserSubRecs, setAmpUserUserSubRecs] = useState([]);
    const [currentUserSub, setCurrentUserSub] = useState(null);
    const [updatePaymentSubRecs, setUpdatePaymentSubRecs] = useState(null);

    function triggerUpdatePaymentSubRecs(triggerUpdate) {
        if (showLogsSubPayment) {
            console.log('triggerUpdatePaymentSubRecs triggerUpdate', triggerUpdate);
        }
        setUpdatePaymentSubRecs(triggerUpdate);
    }

    useEffect(() => {
        if (showLogsSubPayment) {
            console.log('updatePaymentSubRecs', updatePaymentSubRecs);
        }
    }, [updatePaymentSubRecs, showLogsSubPayment]);

    function resetOnSignOut(triggerReset) {
        if (showLogsSubPayment) {
            console.warn('resetOnSignOut triggerReset', triggerReset);
        }
        if (triggerReset) {
            setAmpUserRecSubPayment(null);
            setCurrentSubPaymentRec(null);
            setAmpUserSubPaymentRecs([]);
            setAmpUserUserSubRecs([]);
            setCurrentUserSub(null);
        } else {
            console.warn('NO resetOnSignOut triggerReset', triggerReset);
        }
    }

    useEffect(() => {
        if (ampUserRecSubPayment) {
            const ampUserId = ampUserRecSubPayment.id
            const subscription = DataStore.observeQuery(
                SubPayment,
                // sb => sb,
                sb => sb.ampuserID.eq(ampUserId),
                {
                    sort: s => s.createdAt(SortDirection.ASCENDING)
                }
            ).subscribe(snapshot => {
                const { items, isSynced } = snapshot;
                if (showLogsSubPayment) {
                    console.log(`SubPayment pr [Snapshot] item count: ${items.length}, isSynced: ${isSynced}`);
                    console.log('SubPayment pr [Snapshot] items', items);
                }
                if (isSynced) {
                    setAmpUserSubPaymentRecs(items);
                    // setUpdatePaymentSubRecs(false);
                }
            });
            // TODO - MAYBE DELETE THIS?
            // Remember to unsubscribe when the component unmounts
            // return () => subscription.unsubscribe();
        }
    }, [ampUserRecSubPayment, showLogsSubPayment, updatePaymentSubRecs]);

    // useEffect( setCurrentSubPayment(newestLivePayment[0]);
    useEffect(() => {
        const successLivePayments = ampUserSubPaymentRecs.filter(eachRec => eachRec.success === true);
        // TODO - IF > 1 UNEXPIRED ITEM IN ARRAY,
        // console.log('successLivePayments', successLivePayments);
        // TODO - VALIDATE THIS ACTUALLY RETURNS THE MOST RECENT REC (IF NOT, CHANGE THE OPERANDS)
        const newestLivePayment = successLivePayments.sort((a, b) => {
            if (a.processorEventCreated < b.processorEventCreated) {
                return -1;
            }
            if (a.processorEventCreated > b.processorEventCreated) {
                return 1;
            }
            return 0;
        });
        if (showLogsSubPayment) {
            console.log('newestLivePayment', newestLivePayment);
        }
        if (successLivePayments && newestLivePayment.length > 0) {
            setCurrentSubPaymentRec(newestLivePayment[0]);
            successLivePayments.slice(1).forEach(async (eachElem) => {
                // if model is expired, update .status to EXPIRED
                const paymentModelIsExpired = subPaymentModelIsExpired(eachElem);
                if (paymentModelIsExpired) {
                    await updateSubPaymentStatus(eachElem, SubPaymentStatus.EXPIRED);
                }
            });
        }
    }, [ampUserSubPaymentRecs, showLogsSubPayment]);

    // TODO - FIX ISSUES ON USER ATTRS PAGE, START USING USER SUB AS NEEDED, HANDLE CASE OF EXPIRED USER SUB,
    //  NEED TO QUERY ON NOT JUST "LIVE" RECS,
    //  CAN PROBABLY DELETE USERSUB PROVIDER AND CONTEXT

    // TODO - !!! SOLVE WHY THIS NEEDS A DATASTORE .CLEAR() TO WORK
    useEffect (() => {
        if (ampUserRecSubPayment && currentSubPaymentRec) {
            // setUserSubSynced(false);
            const ampUserRecUserSubId = ampUserRecSubPayment.id;
            const currentSubPaymentId = currentSubPaymentRec.id;
            const subscription = DataStore.observeQuery(
                UserSub,
                // us => us,
                // us => us.ampuserID("eq", ampUserId),
                us => us.and(us => [
                    us.ampuserID.eq(ampUserRecUserSubId),
                    us.subPaymentID.eq(currentSubPaymentId),
                    us.userSubStatus.eq('LIVE')
                ]),
                {
                    sort: s => s.createdAt(SortDirection.ASCENDING)
                }
            ).subscribe(snapshot => {
                const { items, isSynced } = snapshot;
                if (showLogsSubPayment) {
                    console.log(`$$$ UserSub pr [Snapshot] item count: ${items.length}, isSynced: ${isSynced}`);
                    console.log('$$$ UserSub pr [Snapshot] items', items);
                }
                if (isSynced) {
                    // setAmpUserUserSubRecs(items);
                    if (items.length > 0) {
                        setCurrentUserSub(items[0]);
                        // setAmpUserUserSubRecs(items);
                    }
                    // setUserSubSynced(true);
                }
            });
            // return () => subscription.unsubscribe();
        }
    }, [ampUserRecSubPayment, currentSubPaymentRec, showLogsSubPayment]);

    return (
        <SubPaymentContext.Provider value={{ currentSubPaymentRec,
            setCurrentSubPaymentRec,
            setShowLogsSubPayment,
            setAmpUserRecSubPayment,
            currentUserSub,
            triggerUpdatePaymentSubRecs,
            resetOnSignOut
        }}>
            {children}
        </SubPaymentContext.Provider>
    );
};

export default SubPaymentDataProvider;
