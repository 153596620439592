import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import PostCard from "../cards/PostCard";
import {lookingDoneNamesFactory, userShareAttrsFactory} from "../../consts";

export default function GearTypePostsCardList(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [userShareAttrs, setUserShareAttrs] = useState(null);
    const [ampUserId, setAmpUserId] = useState(null);

    const [gearTypeName, setGearTypeName] = useState('');
    const [gearTypePostRecs, setGearTypePostRecs] = useState([]);
    const [gearTypeIdNames, setGearTypeIdNames] = useState({});

    // useEffect(() => { setAmpUser(props.ampUser); }, [props.ampUser]);
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
            setAmpUserId(props.ampUser.id);
        }
    }, [props.ampUser]);

    // useEffect( setUserShareAttrs(userShareAttrs);
    useEffect(() => {
        if (ampUser) {
            const userShareAttrs = userShareAttrsFactory(ampUser);
            setUserShareAttrs(userShareAttrs);
        }
    }, [ampUser]);

    // useEffect( setGearTypeIdNames(props.gearTypeIdNames);
    useEffect(() => {
        if (props?.gearTypeIdNames) {
            setGearTypeIdNames(props.gearTypeIdNames);
        }
    }, [props.gearTypeIdNames]);

    // useEffect(() => { setGearTypePostRecs(props.thisGearTypesPosts);
    useEffect(() => {
        if (props?.thisGearTypesPosts) {
            setGearTypePostRecs(props.thisGearTypesPosts);
        }
    }, [props]);

    // useEffect(() => { setRegionName(props.regionName);
    useEffect(() => {
        if (props.gearTypeName) {
            setGearTypeName(props.gearTypeName);
        }
    }, [props])

    function gearTypePostCards(gearTypePostRecs) {
        return(
            gearTypePostRecs.map((postRec) => {
                    return(
                        // <ListItem key={postRec.id}>
                        <div
                            key={postRec.id}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                // justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                textAlign: 'left',
                            }}
                        >
                            <PostCard
                                showLogs={props?.showLogs || false}
                                ampUserId={ampUserId}
                                userShareAttrs={userShareAttrs}
                                post={postRec}
                                doneGearTypeNames={lookingDoneNamesFactory(postRec.doneGearTypeIDs, props.gearTypeIdNames)}
                                lookingGearTypeNames={lookingDoneNamesFactory(postRec.lookingGearTypeIDs, props.gearTypeIdNames)}
                                doneFisheryNames={lookingDoneNamesFactory(postRec.doneFisheryIDs, props.fisheryIdNames)}
                                lookingFisheryNames={lookingDoneNamesFactory(postRec.lookingFisheryIDs, props.fisheryIdNames)}
                                doneRegionNames={lookingDoneNamesFactory(postRec.doneRegionIDs, props.regionIdNames)}
                                lookingRegionNames={lookingDoneNamesFactory(postRec.lookingRegionIDs, props.regionIdNames)}
                                donePortNames={lookingDoneNamesFactory(postRec.donePortIDs, props.portIdNames)}
                                lookingPortNames={lookingDoneNamesFactory(postRec.lookingPortIDs, props.portIdNames)}
                            />
                        </div>
                        // </ListItem>
                    )
                }
            )
        )
    }


    return (
        // <Box sx={{ width: '100%', maxWidth: 360, bgcolor: '#282c34' }}>
        <Box sx={{
            width: '100%',
            minWidth: 420,
            color: 'black'
        }}>
            <h2>{gearTypeName} Posts</h2>
            <nav aria-label="region posts cards list">
                <List>
                    {gearTypePostCards(gearTypePostRecs)}
                </List>
            </nav>
        </Box>
    );
}
