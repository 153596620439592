import React from 'react';
import Box from '@mui/material/Box';

const StyledListBox = ({ children, ...props }) => {

    return (
        <Box
            sx={{
                width: props.width || '100%',
                bgcolor: '#282c34',
                marginX: props.width !== '100%' ? 'auto' : 0, // Set horizontal margins to 'auto' if width is less than 100%
                maxWidth: {
                    xs: 360,  // max-width for xs breakpoint and up
                    sm: 480,  // max-width for sm breakpoint and up
                    md: 600,  // max-width for md breakpoint and up
                    lg: 960,  // max-width for lg breakpoint and up
                    xl: 1280, // max-width for xl breakpoint and up
                },
                // Add more default styles if necessary
                // Overwrite with props.sx to allow for custom styles
                ...props.style,
            }}
            {...props} // Spread the rest of the props to the Box component
        >
            {children}
        </Box>
    );
};

export default StyledListBox;
