// WrappedComponents.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PostGearTypeDataProvider from '../../data/providers/PostGearTypeDataProvider';
import {RequireAuth} from '../auth/RequireAuth';
import GearTypePostsScreen from '../../screens/GearTypePostsScreen';
import { DataStore } from 'aws-amplify';
import { GearType } from '../../models';
import GearTypeDataProvider from "../../data/providers/GearTypeDataProvider";
import PortDataProvider from "../../data/providers/PortDataProvider";
import FisheryDataProvider from "../../data/providers/FisheryDataProvider";
import DevStateProvider from "../../data/providers/DevStateProvider";

function WrappedGearTypePosts(props) {
    const { gearTypeName } = useParams();
    const [gearTypeId, setGearTypeId] = useState(null);

    // useEffect(() => {
    //     console.log('gearTypeName', gearTypeName);
    //     console.log('props.ampUser', props.ampUser);
    // }, [gearTypeName, props.ampUser]);

    useEffect(() => {
        async function fetchGearTypeId() {
            // const regions = await DataStore.query(Region, r => r.name('eq', regionName));
            const gearTypes = await DataStore.query(GearType, r => r.routerPath.eq(gearTypeName));
            if (gearTypes.length > 0) {
                setGearTypeId(gearTypes[0].id);
            }
        }

        fetchGearTypeId();
    }, [gearTypeName]);

    return (
        <DevStateProvider>
        <GearTypeDataProvider>
            <PortDataProvider>
                <FisheryDataProvider>
                    <PostGearTypeDataProvider gearTypeId={gearTypeId}>
                        <RequireAuth>
                            <GearTypePostsScreen routerPaths={props.routerPaths} ampUser={props.ampUser} />
                        </RequireAuth>
                    </PostGearTypeDataProvider>
                </FisheryDataProvider>
            </PortDataProvider>
        </GearTypeDataProvider>
        </DevStateProvider>
    );
}

export default WrappedGearTypePosts;
