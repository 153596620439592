import { PostGearType, PostFishery, PostRegion, PostPort} from "../../models";
import {Amplify, DataStore} from "aws-amplify";
import aws_exports from '../../aws-exports';
Amplify.configure(aws_exports);

export async function createPostFisheryModelsByIds(newPostRec, selectedLookingFisheries, selectedDoneFisheries, showLogs) {
    const fisheryIdSet = new Set();
    if (selectedDoneFisheries && selectedDoneFisheries.length > 0) {
        selectedDoneFisheries.forEach((eachId) => {
            fisheryIdSet.add(eachId)
        });
    }
    if (selectedLookingFisheries && selectedLookingFisheries.length > 0) {
        selectedLookingFisheries.forEach((eachId) => {
            fisheryIdSet.add(eachId)
        });
    }
    // console.log('fisheryIdSet', fisheryIdSet);
    if (fisheryIdSet && fisheryIdSet.size > 0) {
        fisheryIdSet.forEach((eachId) => {
            // console.log('eachId', eachId);
            DataStore.save(
                new PostFishery({
                    post: newPostRec,
                    postId: newPostRec.id,
                    fisheryId: eachId
                })
            ).then((res) => {
                if (showLogs) {
                    console.log('new createPostFisheryModelsByIds PostFishery res', res);
                }
            }).catch((err) => {
                if (showLogs) {
                    console.error('createPostFisheryModelsByIds err', err);
                }
            })
        })
    }
}


export async function createPostGearTypeModelsByIds(newPostRec, selectedLookingGearTypes, selectedDoneGearTypes, showLogs) {
    const gearTypeIdSet = new Set();
    if (selectedDoneGearTypes && selectedDoneGearTypes.length > 0) {
        selectedDoneGearTypes.forEach((eachId) => {
            gearTypeIdSet.add(eachId)
        });
    }
    if (selectedDoneGearTypes && selectedDoneGearTypes.length > 0) {
        selectedLookingGearTypes.forEach((eachId) => {
            gearTypeIdSet.add(eachId)
        });
    }
    // console.log('gearTypeIdSet', gearTypeIdSet);
    if (gearTypeIdSet && gearTypeIdSet.size > 0) {
        gearTypeIdSet.forEach((eachId) => {
            // console.log('eachId', eachId);
            DataStore.save(
                new PostGearType({
                    postId: newPostRec.id,
                    gearTypeId: eachId
                })
            ).then((res) => {
                if (showLogs) {
                    console.log('new createPostGearTypeModelsByIds PostGearType res', res);
                }
            }).catch((err) => {
                if (showLogs) {
                    console.error('createPostGearTypeModelsByIds err', err);
                }
            })
        })
    }
}


export async function createPostRegionsByIds(newPostRec, selectedDoneRegionIds, selectedLookingRegionIds, regions, showLogs) {
    // console.log('createPostRegionsByIds newPostRec', newPostRec);
    // console.log('createPostRegionsByIds selectedDoneRegionIds', selectedDoneRegionIds);
    // console.log('createPostRegionsByIds selectedLookingRegionIds', selectedLookingRegionIds);
    // console.log('createPostRegionsByIds regions', regions);
    const regionIdSet = new Set();
    if (selectedDoneRegionIds && selectedDoneRegionIds.length > 0) {
        selectedDoneRegionIds.forEach((eachId) => {
            const splitArr = eachId.split('-');
            if (splitArr.length > 5) {
                const modelIdElements = splitArr.slice(0, -1);
                const modelId = modelIdElements.join('-');
                regionIdSet.add(modelId);
            } else {
                regionIdSet.add(eachId);
            }
        });
    }
    if (selectedLookingRegionIds && selectedLookingRegionIds.length > 0) {
        selectedLookingRegionIds.forEach((eachId) => {
            const splitArr = eachId.split('-');
            if (splitArr.length > 5) {
                const modelIdElements = splitArr.slice(0, -1);
                const modelId = modelIdElements.join('-');
                regionIdSet.add(modelId);
            } else {
                regionIdSet.add(eachId);
            }
        });
    }
    if (regionIdSet && regionIdSet.size > 0) {
        const regionIdArr = Array.from(regionIdSet);
        for (const eachRegionId of regionIdArr) {
            let matchedRegion = null;
            regions.forEach((eachRegion) => {
                const eachRegionIdAttr = eachRegion.id;
                if (eachRegionIdAttr === eachRegionId) {
                    matchedRegion = eachRegion;
                }
            })
            // console.log('newPostRec', newPostRec)
            // console.log('matchedRegion', matchedRegion)
            // TODO - START HERE, FIND OUT WHY THIS THROWS ON 110, NULL NO .id
            DataStore.save(
                new PostRegion({
                    postId: newPostRec.id,
                    regionId: matchedRegion.id,
                })
            ).then((res) => {
                if (showLogs) {
                    console.log('new createPostRegionsByIds PostRegion res', res);
                }
            }).catch((err) => {
                if (showLogs) {
                    console.error('createPostRegionsByIds err', err);
                    console.log('eachRegionId', eachRegionId);
                }
            })
        }
    }
}

export async function createPostPortByIds(newPostRec, allSelectedPortIds, showLogs) {
    // console.log('createPostPortByIds newPostRec', newPostRec);
    // console.log('createPostPortByIds allSelectedPortIds', allSelectedPortIds);
    // const newPostRecId = newPostRec.id;
    // console.log('createPostPortByIds newPostRecId', newPostRecId);
    // console.log('typeof newPostRecId', typeof newPostRecId);
    // console.log('createPostPortByIds allSelectedPortIds', allSelectedPortIds);
    if (allSelectedPortIds.length > 0) {
        allSelectedPortIds.forEach((eachPortId) => {
            // console.log('eachPortId', eachPortId);
            DataStore.save(
                new PostPort({
                    postId: newPostRec.id,
                    portId: eachPortId
                })
            ).then((res) => {
                if (showLogs) {
                    console.log('new createPostPortByIds PostPort res', res);
                }
            }).catch((err) => {
                if (showLogs) {
                    console.error('createPostPortByIds err', err);
                }
            })
        })
    }
}
