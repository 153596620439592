import React, { useEffect, useState, useContext } from "react";
import RouterBreadcrumbs from "../components/router/RouterBreadcrumbs";
import { useParams } from "react-router-dom";
import {routerPathsToNames, breadcrumbRoutesSingleGearType, breadcrumbRoutesGearTypesScreen} from "../consts";
import BasicRouterMenu from "../components/router/BasicRouterMenu";
import { useLocation } from "react-router-dom";
import GearTypePostsCardList from "../components/lists/GearTypePostsCardList";
import {modelIdNameFactory} from "../consts";
import {groupPostGearTypeRecsByGearType} from "../data/group/postGearTypes";
import PortContext from "../data/contexts/PortContext";
import FisheryContext from "../data/contexts/FisheryContext";
import GearTypeContext from "../data/contexts/GearTypeContext";
import RegionContext from "../data/contexts/RegionContext";
import PostGearTypeContext from "../data/contexts/PostGearTypeContext";
import DevStateContext from "../data/contexts/DevStateContext";

export default function GearTypePostsScreen(props) {
    const [gearTypeRouterPaths, setGearTypeRouterPaths] = useState({})
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([])
    const [gearTypeName, setGearTypeName] = useState('')
    const [thisGearTypePosts, setThisGearTypePosts] = useState([])
    const [regionIdNames, setRegionIdNames] = useState({});
    const [fisheryIdNames, setFisheryIdNames] = useState({});
    const [portIdNames, setPortIdNames] = useState([]);
    const [gearTypeIdNames, setGearTypeIdNames] = useState({});
    const [postRecsByGearType, setPostRecsByGearType] = useState({});
    const [showLogs, setShowLogs] = useState(false);

    const { portRecs } = useContext(PortContext);
    const { fisheryRecs } = useContext(FisheryContext);
    const { gearTypeRecs } = useContext(GearTypeContext);
    const { regionRecs } = useContext(RegionContext);
    const { postGearTypeRecs } = useContext(PostGearTypeContext);
    const { devStateObj } = useContext(DevStateContext);

    const location = useLocation();

    // useEffect(() => { setShowLogs(devStateObj.showLogs);
    useEffect(() => {
        if (devStateObj) {
            setShowLogs(devStateObj.showLogs);
        }
    }, [devStateObj]);

    // setFisheryName(location.state.fisheryRec.name);
    useEffect(() => {
        if (location.state) {
            setGearTypeName(location.state.gearTypeRec.name);
        }
    }, [props, location]);

    // setFisheryRouterPaths(routerPathsToNamesRes['fishery']);
    useEffect(() => {
        const routerPathsToNamesRes = routerPathsToNames(props.routerPaths.routerPaths)
        setGearTypeRouterPaths(routerPathsToNamesRes['gear-type']);
    }, [props]);

    const params = useParams();
    const gearTypePath = params['gearTypeName'];

    // setThisFisheryPosts(thisFisheryPostsVal);
    useEffect(() => {
        if (params) {
            const thisGearTypePostsVal = postRecsByGearType[gearTypePath];
            setThisGearTypePosts(thisGearTypePostsVal);
        }
    // }, [postGearTypeRecs, location]);
    }, [postRecsByGearType, location]);

    // setBreadcrumbRoutes(breadcrumbRoutesSingleRegion(fisheryPath)
    useEffect(() => {
        if (gearTypePath) {
            setBreadcrumbRoutes(breadcrumbRoutesSingleGearType(gearTypePath) || [])
        } else {
            setBreadcrumbRoutes(breadcrumbRoutesGearTypesScreen() || [])
        }
    }, [props]);

    //useEffect( setFisheries(props.fisheries); setFisheryIdNames(modelIdNameFactory(props.fisheries));
    useEffect(() => {
        if (fisheryRecs) {
            setFisheryIdNames(modelIdNameFactory(fisheryRecs));
        }
    }, [fisheryRecs]);

    // useEffect( setRegions(props.regions); setRegionIdNames(modelIdNameFactory(props.regions));
    useEffect(() => {
        if (regionRecs) {
            setRegionIdNames(modelIdNameFactory(regionRecs));
        }
    }, [regionRecs]);

    // useEffect( setPorts(props.ports); setPortIdNames(modelIdNameFactory(props.ports));
    useEffect(() => {
        if (portRecs) {
            setPortIdNames(modelIdNameFactory(portRecs));
        }
    }, [portRecs]);

    // useEffect( setGearTypes(props.gearTypes); setGearTypeIdNames(modelIdNameFactory(props.gearTypes));
    useEffect(() => {
        if (gearTypeRecs) {
            setGearTypeIdNames(modelIdNameFactory(gearTypeRecs));
        }
    }, [gearTypeRecs]);

    // useEffect( setPostRecsByGearType(recsByGearType);
    useEffect(() => {
        if (postGearTypeRecs) {
            (async () => {
                const recsByGearType = await groupPostGearTypeRecsByGearType(postGearTypeRecs);
                setPostRecsByGearType(recsByGearType);
            })();
        }
    }, [postGearTypeRecs]);

    // useEffect( console.log('postRecsByGearType', postRecsByGearType);
    useEffect(() => {
        if (showLogs) {
            console.log('postRecsByGearType', postRecsByGearType);
        }
    }, [postRecsByGearType, showLogs]);

    // <FisheryPostsCardList
    function gearTypeUserPostsList() {
        if (postGearTypeRecs) {
            return (
                <GearTypePostsCardList
                    showLogs={showLogs}
                    ampUser={props.ampUser}
                    gearTypeName={gearTypeName}
                    gearTypePosts={postRecsByGearType}
                    thisGearTypesPosts={thisGearTypePosts}
                    fisheryIdNames={fisheryIdNames}
                    regionIdNames={regionIdNames}
                    portIdNames={portIdNames}
                    gearTypeIdNames={gearTypeIdNames}
                />
            )
        } else {
            return (
                <div>
                    <p>
                        default transition element
                    </p>
                </div>
            )
        }
    }

    return (
        // <div className={"Posts-body"}>
        <div style={{
            backgroundColor: 'lightgray',
            minHeight: '15vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 'calc(10px + 2vmin)',
            color: 'white',
            marginBottom: '5vh'
        }}>
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes}/>
            <div>
            </div>
            <BasicRouterMenu menuRouterPaths={gearTypeRouterPaths}/>
            <div>
                {gearTypeUserPostsList()}
            </div>
        </div>
    )
}