// WrappedComponents.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PostRegionDataProvider from '../../data/providers/PostRegionDataProvider';
import {RequireAuth} from '../auth/RequireAuth';
import RegionPostsScreen from '../../screens/RegionPostsScreen';
import { DataStore } from 'aws-amplify';
import { Region } from '../../models';
import RegionDataProvider from "../../data/providers/RegionDataProvider";
import FisheryDataProvider from "../../data/providers/FisheryDataProvider";
import GearTypeDataProvider from "../../data/providers/GearTypeDataProvider";
import PortDataProvider from "../../data/providers/PortDataProvider";
import DevStateProvider from "../../data/providers/DevStateProvider";

function WrappedRegionPosts(props) {
    const { regionName } = useParams();
    const [regionId, setRegionId] = useState(null);

    useEffect(() => {
        // console.log('regionName', regionName);
        // console.log('props.ampUser', props.ampUser);
    }, [regionName, props.ampUser]);

    useEffect(() => {
        async function fetchRegionId() {
            // const regions = await DataStore.query(Region, r => r.name('eq', regionName));
            const regions = await DataStore.query(Region, r => r.routerPath.eq(regionName));
            if (regions.length > 0) {
                setRegionId(regions[0].id);
            }
        }

        fetchRegionId();
    }, [regionName]);

    return (
        <DevStateProvider>
        <RegionDataProvider>
            <PortDataProvider>
            <FisheryDataProvider>
                <GearTypeDataProvider>
                    <PostRegionDataProvider regionId={regionId}>
                        <RequireAuth>
                            <RegionPostsScreen routerPaths={props.routerPaths} ampUser={props.ampUser} />
                        </RequireAuth>
                    </PostRegionDataProvider>
                </GearTypeDataProvider>
            </FisheryDataProvider>
            </PortDataProvider>
        </RegionDataProvider>
            </DevStateProvider>
    );
}

export default WrappedRegionPosts;
