import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import PostCard from "../cards/PostCard";
import {lookingDoneNamesFactory, userShareAttrsFactory} from "../../consts";


export default function PortPostsCardList(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [userShareAttrs, setUserShareAttrs] = useState(null);
    const [ampUserId, setAmpUserId] = useState(null);
    const [portName, setPortName] = useState('');
    const [portPostRecs, setPortPostRecs] = useState([]);
    const [fisheryIdNames, setFisheryIdNames] = useState({});
    const [portIdNames, setPortIdNames] = useState({});
    const [gearTypeIdNames, setGearTypeIdNames] = useState({});
    // const [networkConnected, setNetworkConnected] = useState( true)

    /**
     TODO - ADD DEFAULT LOADING ELEMENT HERE, AS DEFAULT ARR FOR PORTPOSTS
     */

    // useEffect(() => { setAmpUser(props.ampUser); }, [props.ampUser]);
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
            setAmpUserId(props.ampUser.id);
        }
    }, [props.ampUser]);

    // useEffect( setUserShareAttrs(userShareAttrs);
    useEffect(() => {
        if (ampUser) {
            const userShareAttrs = userShareAttrsFactory(ampUser);
            setUserShareAttrs(userShareAttrs);
        }
    }, [ampUser]);

    // useEffect(() => { setPortPostRecs(props.thisPortPosts);
    useEffect(() => {
        if (props?.thisPortPosts) {
            setPortPostRecs(props.thisPortPosts);
        }
    }, [props]);

    // useEffect(() => { setPortName(props.portName);
    useEffect(() => {
        if (props.portName) {
            setPortName(props.portName);
        }
    }, [props])

    // useEffect( setFisheryIdNames(props.fisheryIdNames);
    useEffect(() => {
        if (props?.fisheryIdNames) {
            setFisheryIdNames(props.fisheryIdNames);
        }
    }, [props.fisheryIdNames]);

    // useEffect( setPortIdNames(props.portIdNames);
    useEffect(() => {
        if (props?.portIdNames) {
            setPortIdNames(props.portIdNames);
        }
    }, [props.portIdNames]);

    // useEffect( setGearTypeIdNames(props.gearTypeIdNames);
    useEffect(() => {
        if (props?.gearTypeIdNames) {
            setGearTypeIdNames(props.gearTypeIdNames);
        }
    }, [props.gearTypeIdNames]);

    function portPostCards(portPostRecs) {
        return(
            portPostRecs.map((postRec) => {
                    return(
                        // <ListItem key={postRec.id}>
                        <div
                            key={postRec.id}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                // justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                textAlign: 'left',
                            }}
                        >
                            <PostCard
                                showLogs={props?.showLogs || false}
                                ampUserId={ampUserId}
                                userShareAttrs={userShareAttrs}
                                post={postRec}
                                doneFisheryNames={lookingDoneNamesFactory(postRec.doneFisheryIDs, fisheryIdNames)}
                                lookingFisheryNames={lookingDoneNamesFactory(postRec.lookingFisheryIDs, fisheryIdNames)}
                                doneGearTypeNames={lookingDoneNamesFactory(postRec.doneGearTypeIDs, gearTypeIdNames)}
                                lookingGearTypeNames={lookingDoneNamesFactory(postRec.lookingGearTypeIDs, gearTypeIdNames)}
                                donePortNames={lookingDoneNamesFactory(postRec.donePortIDs, portIdNames)}
                                lookingPortNames={lookingDoneNamesFactory(postRec.lookingPortIDs, portIdNames)}
                            />
                        </div>
                        // </ListItem>
                    )
                }
            )
        )
    }


    return (
        // <Box sx={{ width: '100%', maxWidth: 360, bgcolor: '#282c34' }}>
        <Box sx={{
            width: '100%',
            minWidth: 420,
            color: 'black'
        }}>
            <h2>{portName} Posts</h2>
            {/*<NetworkConnPostsFetchModal openModal={networkConnected} />*/}
            <nav aria-label="port posts cards list">
                <List>
                    {portPostCards(portPostRecs)}
                </List>
            </nav>
        </Box>
    );
}
