import React, { useEffect, useState } from "react";
import Stack from '@mui/material/Stack';
import RouterBreadcrumbs from "../components/router/RouterBreadcrumbs";
import {breadcrumbRoutesGearTypesScreen, deployState} from "../consts"
import GearTypeCardList from "../components/lists/GearTypeCardList";
import WaitlistUserCreateForm from "../ui-components/WaitlistUserCreateForm";

export default function GearTypesScreen(props) {
    const [gearTypes, setGearTypes] = useState([])
    const [countries, setCountries] = useState([])
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([])
    const [routerPaths, setRouterPaths] = useState({})
    const [postRecsByGearType, setPostRecsByGearType] = useState({})

    // setBreadcrumbRoutes
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesGearTypesScreen() || [])
        // console.error('breadcrumbRoutes', breadcrumbRoutes)
    }, [props]);

    // setRouterPaths(allPaths)
    useEffect(() => {
        const allPaths = {
            'country': countries,
            // 'region': regions
            'gear-type': gearTypes
        }
        setRouterPaths(allPaths)
        // console.warn('App update state: allPaths', allPaths || 'no allPaths')
    },[countries, gearTypes]);

    // setPostRecsByRegion(props.postRecsByRegion);
    useEffect(() => {
        if (props.postRecsByGearType) {
            setPostRecsByGearType(props.postRecsByGearType);
        }
    }, [props]);


    return (
        <div className={"home-screen-cards"}>
            {deployState === 'signup' ? <WaitlistUserCreateForm onSuccess={() => {
                alert('Added to the Fishingigs wait list successfully!');
            }
            }/> : null}
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes} />
            <Stack spacing={2}>
                <GearTypeCardList
                    gearTypeRecs={props.gearTypeRecs}
                    // postRegionRecs={postRegionRecs}
                    postRecsByGearType={postRecsByGearType}
                />
            </Stack>
        </div>
    );
}