import {DataStore} from "aws-amplify";
import {AmpUser, SubPayment, SubscriptionType, Post } from "./models";
import md5 from "md5";

export const defaultName = 'name'
export const defaultPhone = '+12061235555'

export const devState = {
    dev: {
        stateNow: "dev",
        stripeSuccessURL: 'http://localhost:3000/subscription-success',
        stripeCancelURL: 'http://localhost:3000/subscription-cancel',
        staticSiteUrl: 'http://localhost:3000',
    },
    prod: {
        stateNow: "prod",
        // stripeSuccessURL: 'https://www.fishingigs.com/subscription-success',
        stripeSuccessURL: 'https://www.fishingigs.com/',
        // stripeCancelURL: 'https://www.fishingigs.com/subscription-cancel',
        stripeCancelURL: 'https://www.fishingigs.com/',
        staticSiteUrl: 'https://www.fishngigs.com',
    },
};

export const devStateNow = devState['prod'];
export const deployState = 'signup'; // app || signup

export function toDollarString(amount) {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(amount / 100);
}

function monthsToMilliseconds(months) {
    const averageDaysPerMonth = 31;
    const hoursPerDay = 24;
    const minutesPerHour = 60;
    const secondsPerMinute = 60;
    const millisecondsPerSecond = 1000;

    return months * averageDaysPerMonth * hoursPerDay * minutesPerHour * secondsPerMinute * millisecondsPerSecond;
}

export const userSubDataObj = {
    Month: {
        subText: 'Month',
        subPrice: process.env.REACT_APP_STRIPE_MONTH_PRICE,
        subTypeEnum: SubscriptionType.MONTH,
        periodMs: monthsToMilliseconds(1),
        descriptionText: '1 month',
        subLengthMonths: 1
    },
    Quarter: {
        subText: 'Quarter',
        // subPrice: 33,
        subPrice: process.env.REACT_APP_STRIPE_QUARTER_PRICE,
        subTypeEnum: SubscriptionType.QUARTER,
        periodMs: monthsToMilliseconds(3),
        descriptionText: '3 months',
        subLengthMonths: 3
    },
    Year: {
        subText: 'Year',
        // subPrice: 100,
        subPrice: process.env.REACT_APP_STRIPE_YEAR_PRICE,
        userSubEnum: SubscriptionType.YEAR,
        periodMs: monthsToMilliseconds(12),
        descriptionText: '1 year',
        subLengthMonths: 12
    }
}

// export function generateUniquePropKey(baseString) {
//     // Generate a random number or string
//     const randomValue = Math.random().toString(36).substring(2, 15);
//
//     // Concatenate baseString with the random value
//     const uniqueKey = `${baseString}-${randomValue}`;
//
//     return uniqueKey;
// }

export function generateUniqueKey(baseString) {
    // Generate a random number or string
    const randomValue = Math.random().toString(36).substring(2, 15);

    // Concatenate baseString with the random value
    const uniqueKey = `${baseString}-${randomValue}`;

    return uniqueKey;
}

export async function generateHash(inputString) {
    const encoder = new TextEncoder();
    const randomValue = Math.random().toString(36).substring(2, 15);
    const data = encoder.encode(`${inputString}-${randomValue}`);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // Convert buffer to byte array
    // const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    // return hashHex.slice(0, 32); // Truncate to 32 characters
}

export const ROUTER_PATHS = [
    "regions",
    // "countries",
    "ports",
    "fisheries",
    "gear-types",
    "/"
]
export const BASE_ROUTER_PATHS = [
    "region",
    "country",
    "/"
]

export const POST_TYPES_SET = new Set([
    'Vessel',
    'Crew',
    'Unload',
    'Gear Work',
    'Vendor'
]);

export function fetchAllRouterPaths(recsObj) {
    const recsRouterPaths = recsObj['routerPaths']
    const nestedRouterPathsArr = []
    const nestedRouterPathsObj = {}
    // const parsedRecsObj = JSON.parse(JSON.stringify(recsObj))
    BASE_ROUTER_PATHS.forEach((eachPath) => {
        const routeNestedPaths = []
        if (recsRouterPaths[eachPath]) {
            const nestedPaths = recsRouterPaths[eachPath].map((modelRec) => {
                switch (eachPath) {
                    case 'region':
                        return modelRec.name
                    case 'country':
                        return modelRec.intlCode
                    default:
                        return '/'
                }
            })
            nestedPaths.forEach((eachNestedPath) => {
                nestedRouterPathsArr.push(`${eachPath}/${eachNestedPath}`)
                routeNestedPaths.push(eachNestedPath)
            })
            nestedRouterPathsObj[eachPath] = routeNestedPaths
        } else {
            nestedRouterPathsArr.push(`${eachPath}`)
        }
    })
    return nestedRouterPathsArr
}

export function routerPathsToNames(recsObj) {
    const retObj = {}
    BASE_ROUTER_PATHS.forEach((baseRouterPath) => {
        if (recsObj[baseRouterPath]) {
            const nestedPaths = recsObj[baseRouterPath].map((modelRec) => {
                switch (baseRouterPath) {
                    case 'region':
                        return {[modelRec.routerPath]: modelRec.name}
                    case 'gear-type':
                        return {[modelRec.routerPath]: modelRec.name}
                    case 'country':
                        return {[modelRec.routerPath]: modelRec.intlCode}
                    default:
                        return {'/': '/'}
                }
            })
            const pathNamesFactoryRes = pathNamesFactory(nestedPaths)
            retObj[baseRouterPath] = pathNamesFactoryRes
        }
    })
    // console.error('retObj', retObj)
    return retObj;
}

function pathNamesFactory(nestedPaths) {
    const retObj = {}
    nestedPaths.forEach((eachNestedPath) => {
        const eachNestedPathEntries = Object.entries(eachNestedPath)
        const entryParsed = JSON.parse(JSON.stringify(eachNestedPathEntries[0]))
        const parsedEntries = Object.entries(entryParsed)
        const parsedEntryKey = parsedEntries[0][1]
        const parsedEntryVal = parsedEntries[1][1]
        retObj[parsedEntryKey] = parsedEntryVal
    })
    return retObj
}

export function breadcrumbRoutesBase() {
    return [
        {
            name: 'home',
            breadcrumbRoute: '/'
        }
    ]
}

const routesBaseObj = {
    regions: {
        name: 'regions',
        breadcrumbRoute: '/regions'
    },
    ports: {
        name: 'ports',
        breadcrumbRoute: '/ports'
    },
    userPosts: {
        name: 'your-posts',
        breadcrumbRoute: '/user-posts'
    },
    pricing: {
        name: 'pricing',
        breadcrumbRoute: '/pricing'
    },
    termsOfUse: {
        name: 'terms of use',
        breadcrumbRoute: '/terms-of-use'
    },
    contactUs: {
        name: 'contact us',
        breadcrumbRoute: '/contact-us'
    },
    user: {
        name: 'user',
        breadcrumbRoute: '/user'
    },
    howItWorks: {
        name: 'how it works',
        breadcrumbRoute: '/how-it-works'
    },
    aboutUs: {
        name: 'about us',
        breadcrumbRoute: '/about-us'
    },
    fisheries: {
        name: 'fisheries',
        breadcrumbRoute: '/fisheries'
    },
    gearTypes: {
        name: 'gear types',
        breadcrumbRoute: '/gear-types'
    }
}

function breadcrumbRoutesBaseFactory(routeParam) {
    const routeObj = routesBaseObj[routeParam];
    const base = breadcrumbRoutesBase()
    return base.concat(routeObj);
}

export function breadcrumbRoutesRegionsScreen() {
    return breadcrumbRoutesBaseFactory('regions')
}

export function breadcrumbRoutesPortsScreen() {
    return breadcrumbRoutesBaseFactory('ports')
}

export function breadcrumbRoutesUserPostsScreen() {
    return breadcrumbRoutesBaseFactory('userPosts')
}

export function breadcrumbRoutesUserAttributesScreen() {
    return breadcrumbRoutesBaseFactory('user');
}

export function breadcrumbRoutesFisheriesScreen() {
    return breadcrumbRoutesBaseFactory('fisheries');
}

export function breadcrumbRoutesGearTypesScreen() {
    return breadcrumbRoutesBaseFactory('gearTypes');
}

export function breadcrumbRoutesSingleRegion(region) {
    const regions = breadcrumbRoutesRegionsScreen()
    const singleRegionRoutes = [{
        name: region,
        breadcrumbRoute: `/region/${region}`
    }]
    return regions.concat(singleRegionRoutes);
}

export function breadcrumbRoutesSinglePort(port) {
    const ports = breadcrumbRoutesPortsScreen()
    const singlePortRoutes = [{
        name: port,
        breadcrumbRoute: `/port/${port}`
    }]
    return ports.concat(singlePortRoutes);
}

export function breadcrumbRoutesSingleFishery(fishery) {
    const fisheries = breadcrumbRoutesFisheriesScreen()
    const singleFisheryRoutes = [{
        name: fishery,
        breadcrumbRoute: `/fishery/${fishery}`
    }]
    return fisheries.concat(singleFisheryRoutes);
}

export function breadcrumbRoutesSingleGearType(gearType) {
    const gearTypes = breadcrumbRoutesGearTypesScreen()
    const singleGearTypeRoutes = [{
        name: gearType,
        breadcrumbRoute: `/gear-type/${gearType}`
    }]
    return gearTypes.concat(singleGearTypeRoutes);
}

export function filterModelByChecked(elem) {
    if (elem.checked) {
        return true;
    }
}

// TODO - MAKE SURE THIS IS BEING USED CORRECTLY WHEN CREATING A NEW POST,
//  ALL OTHER PLACES
export const msSubMap = {
    // 2000: 2.6784e+6, // month, 31 days
    // 2000: 2678400, // month, 31 days
    2000: 2.6298e+9, // month, 31 days
    // 3300: 8.0352e+6, // quarter, 93 days
    // 3300: 8035200, // quarter, 93 days
    3300: 7.889399e+9, // quarter, 93 days
    // 10000: 3.1536e+7, // year, 365 days
    // 10000: 31536000, // year, 365 days
    10000: 3.15576e+10, // year, 365 days
    // week: 604800000 // week
    month: 2.6298e+9, // month, 31 days
    week: 6.048e+8, // week, 7 days
}

// export const subTypeEnum = {
//     3000: SubscriptionType.QUARTER,
//     10000: SubscriptionType.YEAR
// }

export function subPaymentModelIsExpired(model) {
    const nowTs = Date.now();
    const modelCreatedAtDate = model.processorEventCreated;
    return modelCreatedAtDate > nowTs;
}

export function modelIdNameFactory(models) {
    const idNamesObj = {}
    models.forEach((eachRec) => {
        idNamesObj[eachRec.id] = eachRec.name
    });
    return idNamesObj
}

export async function updateAmpUserUserAttrs(ampUser, userAttrs) {
    console.warn('updateAmpUserUserAttrs(ampUser, userAttrs)', ampUser, userAttrs)
    await DataStore.save(
        AmpUser.copyOf(ampUser, updated => {
            updated.name = userAttrs.name;
            updated.phone = userAttrs.phone;
            updated.share_attrs = userAttrs.share_attrs;
        })
    );
    // window.location.reload();
}

export async function updateAmpUserUserStatus(ampUser, userStatus) {
    console.warn('updateAmpUserUserStatus(ampUser, userStatus)', ampUser, userStatus)
    await DataStore.save(
        AmpUser.copyOf(ampUser, updated => {
            updated.userStatus = userStatus;
        })
    );
    // window.location.reload();
}

export async function updateAmpUserLastPaymentHash(ampUser, lastPaymentHash) {
    console.warn('updateAmpUserLastPaymentHash(ampUser, userStatus)', ampUser, lastPaymentHash)
    await DataStore.save(
        AmpUser.copyOf(ampUser, updated => {
            updated.lastPaymentHash = lastPaymentHash;
        })
    );
    // window.location.reload();
}

export async function fetchAmpUser(ampUserId) {
    console.warn('fetchAmpUser(ampUserId)', ampUserId);
    const updatedAmpUser = await DataStore.query(AmpUser, ampUserId);
    return updatedAmpUser
}

export async function updateSubPaymentStatus(subPayment, status) {
    console.log('updateSubPaymentStatus(subPayment, status)', subPayment, status)
    await DataStore.save(
        SubPayment.copyOf(subPayment, updated => {
            updated.status = status
        })
    );
}

/**
 *
 * @param {{}} obj1
 * @param {{}} obj2
 */
export function objectDeepEquals(obj1, obj2) {
    if (JSON.stringify(obj1) !== JSON.stringify(obj2)) {
        return false;
    }
    if (JSON.stringify(obj1.keys()) !== JSON.stringify(obj2.keys())) {
        return false;
    }
    if (JSON.stringify(obj1.values()) !== JSON.stringify(obj2.values())) {
        return false;
    }
    Object.entries(obj1).forEach((eachEntryArr) => {
        const eachEntryKey = eachEntryArr[0];
        const eachEntryVal = eachEntryArr[1];
        const obj2Val = obj2[eachEntryKey];
        if (JSON.stringify(eachEntryVal) !== JSON.stringify(obj2Val)) {
            return false;
        }
    });
    return true;
}

export function convertTimestampToDate(timestamp) {
    const date = new Date(timestamp);
    return date.toLocaleDateString();
}

export function convertTimestampToDateWithOffset(timestamp, offset) {
    const offsetMs = msSubMap[offset];
    // console.log('offsetMs', offsetMs)
    const date = new Date(timestamp + offsetMs);
    return date.toLocaleDateString();
}

export function lookingDoneNamesFactory(lookingDoneIds, modelIdNames) {
    if (lookingDoneIds && modelIdNames) {
        const lookingDoneIdNames = lookingDoneIds.map((eachId) => {
            // console.log('eachId', eachId);
            // console.log('modelIdNames', modelIdNames);
            // console.log('modelIdNames[eachId]', modelIdNames[eachId]);
            return modelIdNames[eachId];
        });
        return lookingDoneIdNames;
    } else {
        return null;
    }
}

export function disableSelectIfItemChecked(selectName, objsArray) {
    let disableSelect = false;
    objsArray.forEach((eachObj) => {
        if (eachObj.name === selectName && eachObj.checked) {
            disableSelect = true;
        }
    });
    return disableSelect;
}

export function deleteUserPost(postId) {
    console.log('deleteUserPost(postId)', postId)
    DataStore.delete(Post, postId);
}

export function formatRegionIdSet(regionIdSet) {
    const returnSet = new Set();
    regionIdSet.forEach((eachRegionId) => {
        const splitArr = eachRegionId.split('-');
        const modelIdElements = splitArr.slice(0, -1);
        const modelId = modelIdElements.join('-');
        returnSet.add(modelId);
    });
    return Array.from(returnSet);
}

/**
 *
 * @param {Array} doneRegions
 * @param {Array} portRecs
 * @returns {*[]}
 */
export function doneRegionsToDonePortIds(doneRegions, portRecs) {
    // console.log('doneRegions', doneRegions);
    const selectedDoneRegionIds = doneRegions.filter((eachRegion) => {
        if (eachRegion.checked) {
            return eachRegion;
        }
    }).map((eachRegion) => {
        return eachRegion.id;
    })
    // console.log('selectedDoneRegionIds', selectedDoneRegionIds);

    const formattedDoneRegionIds = formatRegionIdSet(selectedDoneRegionIds);
    // console.log('formattedDoneRegionIds', formattedDoneRegionIds);

    const portIdSet = new Set();
    if (formattedDoneRegionIds.length > 0) {
        portRecs.forEach((eachPort) => {
            if (formattedDoneRegionIds.indexOf(eachPort.regionID) !== -1) {
                portIdSet.add(eachPort.id);
            }
        })
    }
    const portIds = Array.from(portIdSet)
    // console.log('doneRegionsToDonePortIds portIds', portIds);
    return portIds;
}

/**
 *
 * @param {Array} lookingRegions
 * @param {Array} portRecs
 * @returns {*[]}
 */
export function lookingRegionsToLookingPortIds(lookingRegions, portRecs) {
    // console.log('lookingRegions', lookingRegions);
    const selectedLookingRegionIds = lookingRegions.filter((eachRegion) => {
        if (eachRegion.checked) {
            return eachRegion;
        }
    }).map((eachRegion) => {
        return eachRegion.id;
    })
    // console.log('selectedLookingRegionIds', selectedLookingRegionIds);

    const formattedLookingRegionIds = formatRegionIdSet(selectedLookingRegionIds);
    // console.log('formattedLookingRegionIds', formattedLookingRegionIds);

    const portIdSet = new Set();
    if (formattedLookingRegionIds.length > 0) {
        portRecs.forEach((eachPort) => {
            if (formattedLookingRegionIds.indexOf(eachPort.regionID) !== -1) {
                portIdSet.add(eachPort.id);
            }
        })
    }
    const portIds = Array.from(portIdSet)
    // console.log('formattedLookingRegionIds portIds', portIds);
    return portIds;
}

/**
 *
 * @param {Array} doneRegions
 * @param {Array} portRecs
 * @returns {*[]}
 */
export function doneRegionsToDonePortNames(doneRegions, portRecs) {
    // console.log('doneRegions', doneRegions);
    const selectedDoneRegionIds = doneRegions.filter((eachRegion) => {
        if (eachRegion.checked) {
            return eachRegion;
        }
    }).map((eachRegion) => {
        return eachRegion.id;
    })
    // console.log('selectedDoneRegionIds', selectedDoneRegionIds);

    const formattedDoneRegionIds = formatRegionIdSet(selectedDoneRegionIds);
    // console.log('formattedDoneRegionIds', formattedDoneRegionIds);

    const portNameSet = new Set();
    if (formattedDoneRegionIds.length > 0) {
        portRecs.forEach((eachPort) => {
            if (formattedDoneRegionIds.indexOf(eachPort.regionID) !== -1) {
                portNameSet.add(eachPort.name);
            }
        })
    }
    const portNames = Array.from(portNameSet)
    // console.log('doneRegionsToDonePortNames portNames', portNames);
    return portNames;
}

/**
 *
 * @param {Array} lookingRegions
 * @param {Array} portRecs
 * @returns {*[]}
 */
export function lookingRegionsToLookingPortNames(lookingRegions, portRecs) {
    // console.log('lookingRegions', lookingRegions);
    const selectedLookingRegionIds = lookingRegions.filter((eachRegion) => {
        if (eachRegion.checked) {
            return eachRegion;
        }
    }).map((eachRegion) => {
        return eachRegion.id;
    })
    // console.log('selectedLookingRegionIds', selectedLookingRegionIds);

    const formattedLookingRegionIds = formatRegionIdSet(selectedLookingRegionIds);
    // console.log('formattedLookingRegionIds', formattedLookingRegionIds);

    const portNameSet = new Set();
    if (formattedLookingRegionIds.length > 0) {
        portRecs.forEach((eachPort) => {
            if (formattedLookingRegionIds.indexOf(eachPort.regionID) !== -1) {
                portNameSet.add(eachPort.name);
            }
        })
    }
    const portNames = Array.from(portNameSet)
    // console.log('lookingRegionsToLookingPortNames portNames', portNames);
    return portNames;
}

export function userShareAttrsFactory(ampUserRec) {
    if (!ampUserRec || !ampUserRec.share_attrs) {
        return {};
    }

    let result = {};
    ampUserRec.share_attrs.forEach(attr => {
        result[attr] = ampUserRec[attr];
    });

    return result;
}