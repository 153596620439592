import React from "react";
import Typography from "@mui/material/Typography";
import UserProfileLink from "../links/UserProfileLink";
import StyledPostSectionContentTypography from "../text/StyledPostSectionContentTypography";

export default function PostUserContactInfoList(props) {

    return (
        <div>
            {/*<Typography>Post Contact Info</Typography>*/}
            {Object.entries(props.userShareAttrs).map(([key, value], index) => {
                // If the key is "phone", format the value.
                if (key === "phone") {
                    value = `(${value.substring(2, 5)}) ${value.substring(5, 8)}-${value.substring(8, 12)}`;
                }
                return (
                    <StyledPostSectionContentTypography
                        key={index}
                        text={`${key}: ${value}`}
                    />
                );
            })}
        </div>
    );
}