import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Fishery } from '../../models';
import FisheryContext from "../contexts/FisheryContext";

const FisheryDataProvider = ({ children }) => {
    const [fisheryRecs, setFisheryRecs] = useState([]);

    useEffect(() => {
        const subscription = DataStore.observeQuery(Fishery)
            .subscribe(msg => {
                const { items, isSynced } = msg;
                // console.log(`Fishery [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                // console.log('Fishery [Snapshot] items', items);
                if (isSynced) {
                    // Update the posts data
                    setFisheryRecs(items);
                }
                // setFisheryRecs(msg.items);
            });

        // Remember to unsubscribe when the component unmounts
        return () => subscription.unsubscribe();
    }, []);


    return (
        <FisheryContext.Provider value={{ fisheryRecs: fisheryRecs }}>
            {children}
        </FisheryContext.Provider>
    );
};

export default FisheryDataProvider;
