import React, { useContext } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import FisheryContext from "../../data/contexts/FisheryContext";
import FisheryCard from "../cards/FisheryCard";
import StyledListBox from "../boxes/StyledListBox";
import {useResponsiveViewport} from "../../data/providers/ResponsiveViewportProvider";

function fisheryCards(fisheryRecsArr) {
    return(
        fisheryRecsArr.map((fisheryRec) => {
                return(
                    <ListItem
                        key={fisheryRec.id}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <FisheryCard
                            fishery={fisheryRec}
                        />
                    </ListItem>
                )
            }
        )
    )
}

export default function FisheryCardList(props) {
    const { fisheryRecs } = useContext(FisheryContext);
    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    // useEffect( console.log("fisheryRecs", fisheryRecs);
    // useEffect(() => {
    //     console.log("fisheryRecs", fisheryRecs);
    // }, []);

    return (
        <div>
            <StyledListBox
                width={isLargeScreen ? '90%' : '100%'}
                style={{
                    color: 'white',
                }}
            >
                <h2>Fisheries</h2>
                <nav aria-label="fishery cards list">
                    <List>
                        {fisheryCards(fisheryRecs)}
                    </List>
                </nav>
            </StyledListBox>
        </div>
    );
}
