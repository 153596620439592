// WrappedComponents.js
import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
// import PostRegionDataProvider from '../../data/providers/PostRegionDataProvider';
import {RequireAuth} from '../auth/RequireAuth';
// import RegionPostsScreen from '../../screens/RegionPostsScreen';
import { DataStore } from 'aws-amplify';
// import { Region } from '../../models';
import RegionDataProvider from "../../data/providers/RegionDataProvider";
import FisheryDataProvider from "../../data/providers/FisheryDataProvider";
import GearTypeDataProvider from "../../data/providers/GearTypeDataProvider";
import PortDataProvider from "../../data/providers/PortDataProvider";
import SinglePostScreen from "../../screens/SinglePostScreen";
import {lookingDoneNamesFactory, userShareAttrsFactory} from "../../consts";
import PostCard from "../cards/PostCard";
import DevStateProvider from "../../data/providers/DevStateProvider";


function WrappedSinglePost(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [postRec, setPostRec] = useState(null);  // Add postRec to state
    const [userShareAttrs, setUserShareAttrs] = useState(null);
    const [ampUserId, setAmpUserId] = useState(null);

    const { postRouterPath } = useParams();
    const location = useLocation();

    const statePostRec = location.state?.postRec;  // Extract postRec from the location state

    useEffect(() => {
        console.log('location', location);
    }, [location]);

    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
            setAmpUserId(props.ampUser.id);
        }
    }, [props]);

    // useEffect( setUserShareAttrs(userShareAttrs);
    useEffect(() => {
        if (ampUser) {
            const userShareAttrs = userShareAttrsFactory(ampUser);
            setUserShareAttrs(userShareAttrs);
        }
    }, [ampUser]);

    useEffect(() => {
        if (statePostRec) {
            setPostRec(statePostRec);
        }
    }, [location]);

    useEffect(() => {
        console.log('props', props);
        console.log('postRouterPath', postRouterPath);
        console.log('ampUser', ampUser);
        console.log('postRec', postRec);  // Log the postRec object
    }, [postRouterPath, ampUser, postRec, props]);

    // TODO - START HERE, FIND OUT WHY POSTREC NOT MAKING IT TO THE SCREEN
    return (
        <DevStateProvider>
        <RegionDataProvider>
            <PortDataProvider>
                <FisheryDataProvider>
                    <GearTypeDataProvider>
                        <RequireAuth>
                            <SinglePostScreen routerPaths={props.routerPaths} ampUser={ampUser} postRec={postRec} />
                            {/*<PostCard*/}
                            {/*    showLogs={props?.showLogs || false}*/}
                            {/*    ampUserId={ampUserId}*/}
                            {/*    userShareAttrs={userShareAttrs}*/}
                            {/*    post={postRec}*/}
                            {/*    doneFisheryNames={lookingDoneNamesFactory(postRec.doneFisheryIDs, props.fisheryIdNames)}*/}
                            {/*    lookingFisheryNames={lookingDoneNamesFactory(postRec.lookingFisheryIDs, props.fisheryIdNames)}*/}
                            {/*    doneRegionNames={lookingDoneNamesFactory(postRec.doneRegionIDs, props.regionIdNames)}*/}
                            {/*    lookingRegionNames={lookingDoneNamesFactory(postRec.lookingRegionIDs, props.regionIdNames)}*/}
                            {/*    doneGearTypeNames={lookingDoneNamesFactory(postRec.doneGearTypeIDs, props.gearTypeIdNames)}*/}
                            {/*    lookingGearTypeNames={lookingDoneNamesFactory(postRec.lookingGearTypeIDs, props.gearTypeIdNames)}*/}
                            {/*    donePortNames={lookingDoneNamesFactory(postRec.donePortIDs, props.portIdNames)}*/}
                            {/*    lookingPortNames={lookingDoneNamesFactory(postRec.lookingPortIDs, props.portIdNames)}*/}
                            {/*/>*/}
                        </RequireAuth>
                    </GearTypeDataProvider>
                </FisheryDataProvider>
            </PortDataProvider>
        </RegionDataProvider>
        </DevStateProvider>
    );
}

export default WrappedSinglePost;

