import React, { useEffect, useState, useContext } from "react";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import RouterBreadcrumbs from "../components/router/RouterBreadcrumbs";
import {breadcrumbRoutesUserAttributesScreen} from "../consts"
import '../styles/UserDetails.css'
import UserAttributesList from "../components/lists/UserAttributesList";
import {useLocation} from "react-router-dom";
import AmpUserContext from "../data/contexts/AmpUserContext";
import {UserStatus} from "../models";

export default function UserDetailsScreen(props) {
    // const [regions, setRegions] = useState([])
    // const [countries, setCountries] = useState([])
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([])
    // const [routerPaths, setRouterPaths] = useState({})
    // const [postRecsByRegion, setPostRecsByRegion] = useState({})
    const [ampUser, setAmpUser] = useState(null);
    const [userSub, setUserSub] = useState(null);
    const [subPayment, setSubPayment] = useState(null);
    const [showLogs, setShowLogs] = useState(false);
    const [lastSyncTimestamp, setLastSyncTimestamp] = useState(Date.now());

    const { ampUserRec } = useContext(AmpUserContext);

    let location = useLocation();

    useEffect(() => {
        if (props?.triggerAmpUserUpdate && props?.ampUser) {
            if (props.ampUser.userStatus === UserStatus.SUBSCRIBER) {
                if (!userSub || !subPayment) {
                    props.triggerAmpUserUpdate(true);
                }
            }
        }
    }, [props.triggerAmpUserUpdate, userSub, subPayment, props.ampUser, lastSyncTimestamp]);

    useEffect(() => {
        // Only start the interval if currentUserSub is null
        let interval;
        if (userSub === null || subPayment === null) {
            interval = setInterval(() => {
                setLastSyncTimestamp((prevTimestamp) => {
                    // If prevTimestamp is null, initialize it with the current time
                    const baseTimestamp = prevTimestamp ?? Date.now();
                    return baseTimestamp + 10000; // Adds 7 seconds
                });
            }, 10000); // Update every 10 seconds
        }

        return () => {
            // Clear the interval when the component unmounts or when currentUserSub changes
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [userSub, subPayment]); // Dependency array includes currentUserSub

    useEffect(() => {
        if (showLogs) {
            console.log('UserDetailsScreen lastSyncTimestamp', lastSyncTimestamp);
        }
    }, [lastSyncTimestamp, showLogs]);

    useEffect(() => {
        if (showLogs) {
        console.log('>>> UserDetailsScreen ampUserRec', ampUserRec);
        }
    }, [ampUserRec]);

    useEffect(() => {
        if (props?.showLogs) {
            setShowLogs(props.showLogs);
        }
    }, [props]);

    // setBreadcrumbRoutes
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesUserAttributesScreen() || [])
        // console.error('breadcrumbRoutes', breadcrumbRoutes)
    }, [props]);

    useEffect(() => {
        // console.log('OOO location', location);
        if (location?.state?.ampUser) {
            if (showLogs) {
                console.log('>>> UserDetailsScreen location.state.ampUser', location.state.ampUser);
            }
            // console.log('>>> UserDetailsScreen location.state.ampUser', location.state.ampUser);
            setAmpUser(location.state.ampUser);
        }
        else if (ampUserRec) {
            if (showLogs) {
                console.log('>>> UserDetailsScreen ampUserRec', ampUserRec);
            }
            // console.log('>>> UserDetailsScreen ampUserRec', ampUserRec);
            setAmpUser(ampUserRec);
        }
        else if (props?.ampUser) {
            if (showLogs) {
                console.log('>>> UserDetailsScreen props.ampUser', props.ampUser);
            }
            // console.log('>>> UserDetailsScreen props.ampUser', props.ampUser);
            setAmpUser(props.ampUser);
        }
    }, [props.ampUser, location, ampUserRec, showLogs]);

    // useEffect(() => {
    //     console.log('ampUser', ampUser);
    // }, [ampUser]);

    useEffect(() => {
        if (props?.userSub) {
            setUserSub(props.userSub);
        }
        // else if (location.state.userSub) {
        //     setAmpUser(location.state.userSub);
        // }
    // }, [props, location]);
    }, [props]);

    // TODO - GO THROUGH AND CHECK ALL SCREENS NOW, THEN COMMIT AND PUSH
    useEffect(() => {
        if (showLogs) {
            console.log('>>> UserDetailsScreen userSub', userSub);
        }
        // console.log('>>> UserDetailsScreen userSub', userSub);
    }, [userSub, showLogs]);

    useEffect(() => {
        if (showLogs) {
            console.log('>>> UserDetailsScreen subPayment', subPayment);
        }
        // console.log('>>> UserDetailsScreen userSub', userSub);
    }, [subPayment, showLogs]);

    useEffect(() => {
        if (props?.subPayment) {
            setSubPayment(props.subPayment);
        }
        // else if (location.state.subPayment) {
        //     setAmpUser(location.state.subPayment);
        // }
    // }, [props, location]);
    }, [props]);

    useEffect(() => {
        if (showLogs) {
            console.log('>>> UserDetailsScreen subPayment', subPayment);
        }
        // console.log('>>> UserDetailsScreen subPayment', subPayment);
    }, [subPayment, showLogs]);

    return (
        <div className={"userDetailsBody"}>
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes} />
            <div>
                <h3 className={"userDetailsHeader"}>
                    User Data
                </h3>
            </div>
            <div className={"userDetailsList"}>
                <Stack spacing={2}>
                    {ampUser ? (
                        <UserAttributesList
                            showLogs={showLogs}
                            ampUser={ampUser}
                            userSub={userSub}
                            subPayment={subPayment}
                        />
                    ) : (
                        <div>
                            <Typography
                                sx={{
                                    fontSize: ['3rem', '3rem', '2rem', '1.5rem'],
                                    color: 'black',
                            }}
                            >
                                Loading user data...
                            </Typography>
                        </div>
                    )}
                </Stack>
            </div>
        </div>
    );
}