import React, {useState, useEffect} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions, Box } from '@mui/material';
import defaultPic from "../../static/images/cards/halibut-circle-hook-outline-1.svg";
import { useNavigate } from "react-router-dom";
import {deployState} from "../../consts";
import BaseLinkButton from "../links/BaseLinkButton";
import StyledListCard from "./StyledListCard";
import StyledCardContent from "./StyledCardContent";
import StyledCardContentTypography from "../text/StyledCardContentTypography";
import {useResponsiveViewport} from "../../data/providers/ResponsiveViewportProvider";

export default function RegionCard(props) {
    const [regionRec, setRegionRec] = useState({});
    const [cardImage, setCardImage] = useState(defaultPic);
    const [regionRoute, setRegionRoute] = useState('');

    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    // useEffect setRegionRoute(`/region/${regionRec.routerPath}`);
    // useEffect setRegionRec(regionRec);
    // useEffect setCardImage(imagePathDefaultAttr);
    useEffect(() => {
        const regionRec = props?.region;
        if (regionRec) {
            setRegionRec(regionRec);
            setRegionRoute(`/region/${regionRec.routerPath}`);
        }
        import(`../../static/images/cards/${props.region?.cardImageFileName || "halibut-circle-hook-outline-1.svg"}`).then(image => {
            const imagePathDefaultAttr = image.default;
            setCardImage(imagePathDefaultAttr);
        })
    },[props]);

    let navigate = useNavigate();

    // useEffect( console.log("regionRoute", regionRoute);
    // useEffect(() => {
    //     console.log("regionRoute", regionRoute);
    // }, [regionRoute]);

    return (
        <StyledListCard
            width={isLargeScreen ? '90%' : '100%'}
        >
            <CardActionArea
                onClick={() => navigate(regionRoute === '' ? '/regions' : regionRoute, {state:{regionRec}})}
            >
                <CardMedia
                    component="img"
                    height="100%"
                    image={cardImage}
                    alt={defaultPic}
                />
                <StyledCardContent>
                    <StyledCardContentTypography
                        text={regionRec?.name || "name"}
                    />
                </StyledCardContent>
            </CardActionArea>
            <CardActions style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                alignItems: 'flex-start'
            }}
            >
                {deployState === 'signup' ? null : <BaseLinkButton
                    linkTarget="/create"
                    buttonText="Create Post"
                    style={{ width: '100%'}}
                    fontSize={['6vw', '3.5vw', '3vw', '2.5vw']}
                />}
                <Box style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: isLargeScreen ? 12 : 4
                }}> {/* New wrapper Box */}
                <BaseLinkButton
                    linkTarget="/fisheries"
                    buttonText="Fisheries"
                    fontSize={['5vw', '3vw', '2.5vw', '2vw']}
                />
                <BaseLinkButton
                    linkTarget="/ports"
                    buttonText="Ports"
                    fontSize={['5vw', '3vw', '2.5vw', '2vw']}
                />
                <BaseLinkButton
                    linkTarget="/gear-types"
                    buttonText={isSmallScreen ? "Gear" : "Gear Types"}
                    fontSize={['5vw', '3vw', '2.5vw', '2vw']}
                />
                </Box>
            </CardActions>
        </StyledListCard>
    );
}
