import React, { useContext } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import GearTypeContext from "../../data/contexts/GearTypeContext";
import GearTypeCard from "../cards/GearTypeCard";
import StyledListBox from "../boxes/StyledListBox";
import {useResponsiveViewport} from "../../data/providers/ResponsiveViewportProvider";

function gearTypeCards(gearTypeRecsArr) {
    return(
        gearTypeRecsArr.map((gearTypeRec) => {
                return(
                    <ListItem
                        key={gearTypeRec.id}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <GearTypeCard
                            gearType={gearTypeRec}
                        />
                    </ListItem>
                )
            }
        )
    )
}

export default function GearTypeCardList(props) {
    const { gearTypeRecs } = useContext(GearTypeContext);
    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    // useEffect( console.log("gearTypeRecs", gearTypeRecs);
    // useEffect(() => {
    //     console.log("gearTypeRecs", gearTypeRecs);
    // }, []);

    return (
        <div>
            <StyledListBox
                width={isLargeScreen ? '90%' : '100%'}
                style={{
                    color: 'white',
                }}
            >
                <h2>Gear Types</h2>
                <nav aria-label="gear type cards list">
                    <List>
                        {gearTypeCards(gearTypeRecs)}
                    </List>
                </nav>
            </StyledListBox>
        </div>
    );
}
