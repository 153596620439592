import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import HomeScreenRegionsCard from "../cards/HomeScreenRegionsCard";
// import HomeScreenCountriesCard from "../cards/HomeScreenCountriesCard";
import HomeScreenFisheriesCard from "../cards/HomeScreenFisheriesCard";
import HomeScreenGearTypesCard from "../cards/HomeScreenGearTypesCard";
import HomeScreenPortsCard from "../cards/HomeScreenPortsCard";
import StyledListBox from "../boxes/StyledListBox";
import {useResponsiveViewport} from "../../data/providers/ResponsiveViewportProvider";


export default function HomeCardsList() {
    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    return (
        <StyledListBox
            width={isLargeScreen ? '90%' : '100%'}
        >
            <nav aria-label="home cards list">
                <List sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {/*<ListItem>*/}
                    {/*    <HomeScreenCountriesCard />*/}
                    {/*</ListItem>*/}
                    <ListItem sx={{ justifyContent: 'center', display: 'flex' }}>
                        <HomeScreenRegionsCard />
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'center', display: 'flex' }}>
                        <HomeScreenPortsCard />
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'center', display: 'flex' }}>
                        <HomeScreenFisheriesCard />
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'center', display: 'flex' }}>
                        <HomeScreenGearTypesCard />
                    </ListItem>
                </List>
            </nav>
        </StyledListBox>
    );
}
