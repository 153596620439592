import React from 'react';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, CardActions } from '@mui/material';
import defaultPic from "../../static/images/cards/halibut-circle-hook-outline-1.svg";
import RegionsCardCardImage from '../../static/images/cards/west-coast-states-red-2.svg'
import {useNavigate} from "react-router-dom";
import {deployState} from "../../consts";
import BaseLinkButton from "../links/BaseLinkButton";
import StyledCardContentTypography from "../text/StyledCardContentTypography";
import StyledCardContent from "./StyledCardContent";
import StyledListCard from "./StyledListCard";
import {useResponsiveViewport} from "../../data/providers/ResponsiveViewportProvider";

export default function HomeScreenRegionsCard() {
    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    let navigate = useNavigate();

    return (
        <StyledListCard
              width={isLargeScreen ? '90%' : '100%'}
        >
            <CardActionArea
                onClick={() => navigate('/regions')}
            >
                <CardMedia
                    component="img"
                    height="100%"
                    image={RegionsCardCardImage}
                    alt={defaultPic}
                />
                <StyledCardContent>
                    <StyledCardContentTypography
                        margin="1rem 0 0 0"
                        text="Regions"
                    />
                </StyledCardContent>
            </CardActionArea>
            <CardActions
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 8,
                    alignItems: 'flex-start'
                }}
            >
                {deployState === 'signup' ? null : <BaseLinkButton
                    linkTarget="/create"
                    buttonText="Create New Post"
                    style={{ width: '100%'}}
                    fontSize={['6vw', '3.5vw', '3vw', '2.5vw']}
                />}
            </CardActions>
        </StyledListCard>
    );
}
