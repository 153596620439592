// WrappedUserPosts.js
import React from 'react';
import {RequireAuth} from '../auth/RequireAuth';
import UserPostsScreen from '../../screens/UserPostsScreen';
import FisheryDataProvider from '../../data/providers/FisheryDataProvider';
import CountryDataProvider from '../../data/providers/CountryDataProvider';
import RegionDataProvider from '../../data/providers/RegionDataProvider';
import PortDataProvider from '../../data/providers/PortDataProvider';
import GearTypeDataProvider from '../../data/providers/GearTypeDataProvider';
import UserPostsProvider from '../../data/providers/UserPostsProvider';
import DevStateProvider from "../../data/providers/DevStateProvider";

function WrappedUserPosts({ ampUser, ...props }) {
    return (
        <DevStateProvider>
        <FisheryDataProvider>
            <CountryDataProvider>
                <RegionDataProvider>
                    <PortDataProvider>
                        <GearTypeDataProvider>
                            <UserPostsProvider ampUserId={ampUser.id}>
                                <RequireAuth>
                                    <UserPostsScreen
                                        {...props}
                                        ampUser={ampUser}
                                    />
                                </RequireAuth>
                            </UserPostsProvider>
                        </GearTypeDataProvider>
                    </PortDataProvider>
                </RegionDataProvider>
            </CountryDataProvider>
        </FisheryDataProvider>
        </DevStateProvider>
    );
}

export default WrappedUserPosts;
