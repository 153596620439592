import {Button} from "@mui/material";
import {Link} from "react-router-dom";

export default function BaseStyledLinkButton(props) {
    return (
        <Link to={props.linkTarget}>
            <Button
                size="small"
                // color="primary"
                sx={{
                    textTransform: 'none',
                    // fontSize: ['5vw', '2.5vw', '1.5vw', '1vw'],
                    fontSize: props.fontSize || ['6vw', '3.5vw', '2.5vw', '2vw'],
                    backgroundColor: '#5F9EA0',
                    color: 'white',
                    padding: props.padding || '0 1.5rem',
                    margin: props.margin || '0 1rem 0 1rem',
                    ...props.style
                }}
            >
                {props.buttonText}
            </Button>
        </Link>
    )
}