import React, {useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions, Box } from '@mui/material';
import defaultPic from "../../static/images/cards/halibut-circle-hook-outline-1.svg";
import { useNavigate } from "react-router-dom";
import {deployState} from "../../consts";
import BaseLinkButton from "../links/BaseLinkButton";
import useMediaQuery from '@mui/material/useMediaQuery';
import StyledListCard from "./StyledListCard";
import {useResponsiveViewport} from "../../data/providers/ResponsiveViewportProvider";
import StyledCardContent from "./StyledCardContent";
import StyledCardContentTypography from "../text/StyledCardContentTypography";

export default function PortCard(props) {
    const [portRec, setPortRec] = useState({});
    const [cardImage, setCardImage] = useState(defaultPic);
    const [portRoute, setPortRoute] = useState('');

    // const isSmallScreen = useMediaQuery('(max-width:600px)');
    // const isLargeScreen = useMediaQuery('(min-width:1200px)');
    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    let navigate = useNavigate();
    // const isInRouterContext = useInRouterContext();

    // useEffect(() => {
    //     console.log('isSmallScreen', isSmallScreen);
    //     console.log('isLargeScreen', isLargeScreen);
    // }, [isSmallScreen, isLargeScreen]);

    // useEffect(() => {
    //     console.log('PortCard props', props);
    // }, [props]);

    // console.log(isInRouterContext);
    // useEffect setPortRoute(`/port/${portRec.routerPath}`);
    // useEffect setPortRec(portRec);
    // useEffect setCardImage(imagePathDefaultAttr);
    useEffect(() => {
        const portRec = props?.port;
        if (portRec) {
            setPortRec(portRec);
            setPortRoute(`/ports/${portRec.routerPath}`);
        }
        import(`../../static/images/cards/${props.port?.cardImageFileName || "halibut-circle-hook-outline-1.svg"}`).then(image => {
            const imagePathDefaultAttr = image.default;
            setCardImage(imagePathDefaultAttr);
        })
    },[props]);


    return (
        <StyledListCard
            width={isLargeScreen ? '90%' : '100%'}
        >
            <CardActionArea
                onClick={() => navigate(portRoute === '' ? '/ports' : portRoute, {state:{portRec}})}
            >
                <CardMedia
                    component="img"
                    height="100%"
                    image={defaultPic}
                    alt={defaultPic}
                />
                <StyledCardContent>
                    <StyledCardContentTypography
                        text={portRec?.name || "name"}
                    />
                </StyledCardContent>
            </CardActionArea>
            <CardActions style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                alignItems: 'flex-start'
            }}
            >
                {deployState === 'signup' ? null : <BaseLinkButton
                    linkTarget="/create"
                    buttonText="Create Post"
                    style={{ width: '100%'}}
                    fontSize={['6vw', '3.5vw', '3vw', '2.5vw']}
                    />
                }
                <Box style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: isLargeScreen ? 12 : 4
                }}> {/* New wrapper Box */}
                <BaseLinkButton
                    linkTarget="/fisheries"
                    buttonText="Fisheries"
                    fontSize={['5vw', '3vw', '2.5vw', '2vw']}
                    />
                <BaseLinkButton
                    linkTarget="/regions"
                    buttonText="Regions"
                    fontSize={['5vw', '3vw', '2.5vw', '2vw']}
                    />
                <BaseLinkButton
                    linkTarget="/gear-types"
                    buttonText={isSmallScreen ? "Gear" : "Gear Types"}
                    fontSize={['5vw', '3vw', '2.5vw', '2vw']}
                />
                </Box>
            </CardActions>
        </StyledListCard>
    );
}
