import { Amplify, DataStore } from "aws-amplify";
import { AmpUser, UserStatus } from "../../models";
import aws_exports from '../../aws-exports';
import {defaultName, defaultPhone} from "../../consts";
import { v4 as uuidv4 } from 'uuid';

Amplify.configure(aws_exports);

export default async function onAmpUserCreate(user) {
    console.log('!!! onAmpUserCreate(user)', user);
    if (user.attributes?.email) {
        return await DataStore.save(
            new AmpUser({
                name: user.attributes?.name || defaultName,
                email: user.attributes?.email,
                username: user.username,
                phone: defaultPhone,
                userStatus: UserStatus.FREE,
                routerPath: uuidv4(),
                share_attrs: ['email']
            })
        );
    } else {
        console.log('!!! no email user', user);
    }
}