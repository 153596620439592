import React, { useEffect, useState, useContext } from "react";
import RouterBreadcrumbs from "../components/router/RouterBreadcrumbs";
import { useParams } from "react-router-dom";
import {breadcrumbRoutesPortsScreen, routerPathsToNames} from "../consts";
import BasicRouterMenu from "../components/router/BasicRouterMenu";
import {breadcrumbRoutesSinglePort} from "../consts";
import { useLocation } from "react-router-dom";
import PortPostsCardList from "../components/lists/PortPostsCardList";
import {modelIdNameFactory} from "../consts";
import PortContext from "../data/contexts/PortContext";
import FisheryContext from "../data/contexts/FisheryContext";
import GearTypeContext from "../data/contexts/GearTypeContext";
import DevStateContext from "../data/contexts/DevStateContext";
import {groupPostPortRecsByPort} from "../data/group/postPorts";
import PostPortContext from "../data/contexts/PostPortContext";

export default function PortPostsScreen(props) {
    const [portRouterPaths, setPortRouterPaths] = useState({})
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([])
    const [portName, setPortName] = useState('')
    const [thisPortPosts, setThisPortPosts] = useState([])
    const [fisheryIdNames, setFisheryIdNames] = useState({});
    const [gearTypeIdNames, setGearTypeIdNames] = useState({});
    const [portIdNames, setPortIdNames] = useState([]);
    const [postRecsByPort, setPostRecsByPort] = useState({});
    const [showLogs, setShowLogs] = useState(false);

    const location = useLocation();

    const { portRecs } = useContext(PortContext);
    const { fisheryRecs } = useContext(FisheryContext);
    const { gearTypeRecs } = useContext(GearTypeContext);
    const { postPortRecs } = useContext(PostPortContext);
    const { devStateObj } = useContext(DevStateContext);

    // useEffect(() => { setShowLogs(devStateObj.showLogs);
    useEffect(() => {
        if (devStateObj) {
            setShowLogs(devStateObj.showLogs);
        }
    }, [devStateObj]);

    // useEffect( console.log('PortPostsCardList devStateObj', devStateObj);
    useEffect(() => {
        if (showLogs) {
            console.log('PortPostsCardList devStateObj', devStateObj);
        }
    }, [devStateObj, showLogs]);

    // setPortName(location.state.portRec.name) setPostPortRecs(location.state.postPortRecs);
    useEffect(() => {
        if (location.state) {
            setPortName(location.state.portRec.name);
        }
    }, [props, location]);

    // setPortRouterPaths(routerPathsToNamesRes['port']);
    useEffect(() => {
        // console.warn('PortPosts(props)', props)
        const routerPathsToNamesRes = routerPathsToNames(props.routerPaths.routerPaths)
        setPortRouterPaths(routerPathsToNamesRes['port']);
        if (showLogs) {
            console.log('routerPathsToNamesRes', routerPathsToNamesRes)
            console.log('portRouterPaths', portRouterPaths)
        }
    }, [props, showLogs]);

    const params = useParams();
    const portPath = params['portName'];

    // setThisPortPosts(thisPortPostsVal);
    useEffect(() => {
        if (params) {
            const thisPortPostsVal = postRecsByPort[portPath];
            setThisPortPosts(thisPortPostsVal);
        }
    }, [postRecsByPort, location]);

    // setBreadcrumbRoutes(breadcrumbRoutesSinglePort(portPath)
    useEffect(() => {
        if (portPath) {
            setBreadcrumbRoutes(breadcrumbRoutesSinglePort(portPath) || [])
        } else {
            setBreadcrumbRoutes(breadcrumbRoutesPortsScreen() || [])
        }
    }, [props]);

    // useEffect( setFisheries(props.fisheries); setFisheryIdNames(modelIdNameFactory(props.fisheries));
    useEffect(() => {
        if (fisheryRecs) {
            setFisheryIdNames(modelIdNameFactory(fisheryRecs));
        }
    }, [fisheryRecs]);

    // useEffect( setGearTypes(props.gearTypes); setGearTypeIdNames(modelIdNameFactory(props.gearTypes));
    useEffect(() => {
        if (gearTypeRecs) {
            setGearTypeIdNames(modelIdNameFactory(gearTypeRecs));
        }
    }, [gearTypeRecs]);

    // useEffect( setPorts(props.ports); setPortIdNames(modelIdNameFactory(props.ports));
    useEffect(() => {
        if (portRecs) {
            setPortIdNames(modelIdNameFactory(portRecs));
        }
    }, [portRecs]);

    // useEffect( setPostRecsByPort(recsByPort);
    useEffect(() => {
        if (postPortRecs) {
            (async () => {
                const recsByPort = await groupPostPortRecsByPort(postPortRecs);
                setPostRecsByPort(recsByPort);
            })();
        }
    }, [postPortRecs]);

    // useEffect( console.log('postRecsByPort', postRecsByPort);
    useEffect(() => {
        if (showLogs) {
            console.log('postRecsByPort', postRecsByPort);
        }
    }, [postRecsByPort, showLogs]);

    // <PortPostsCardList
    function portPostsList() {
        if (postPortRecs) {
            return (
                <PortPostsCardList
                    showLogs={showLogs}
                    ampUser={props.ampUser}
                    portName={portName}
                    portPosts={postRecsByPort}
                    thisPortPosts={thisPortPosts}
                    fisheryIdNames={fisheryIdNames}
                    gearTypeIdNames={gearTypeIdNames}
                    portIdNames={portIdNames} // TODO - TEST PORTS COME THROUGH FROM ALL FORM TYPES
                />
            )
        } else {
            // console.error('render default transition element, e.g. a spinner')
            return (
                <div>
                    <p>
                        default transition element
                    </p>
                </div>
            )
        }
    }

    return (
        // <div className={"Posts-body"}>
        <div style={{
            backgroundColor: 'lightgray',
            minHeight: '15vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 'calc(10px + 2vmin)',
            color: 'white',
            marginBottom: '5vh'
        }}>
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes}/>
            <div>
            </div>
            <BasicRouterMenu menuRouterPaths={portRouterPaths}/>
            <div>
                {portPostsList()}
            </div>

        </div>
    )
}