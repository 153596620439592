import React from 'react';
import {Button} from "@mui/material";
import { useNavigate } from 'react-router-dom';

const PostPreviewButton = ({ postType, formValues, ampUser, checkedFormValues }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/post-preview', {
            state: {
                postType,
                formValues,
                ampUser,
                checkedFormValues
            }
        });
    };

    return (
        <Button
            variant="contained"
            onClick={handleClick}
            style={{
                marginTop: '6px',
                marginBottom: '16px',
                backgroundColor: '#5F9EA0'
        }}
        >
            Preview Post
        </Button>
    );
};

export default PostPreviewButton;
