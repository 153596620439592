import React from 'react';
import ListItem from '@mui/material/ListItem';

const StyledUserAttrListSection = ({ children, sx }) => {
    return (
        <ListItem
            sx={{
                // margin: '4px',
                marginLeft: {
                    xs: '0.25rem',
                    sm: '0.5rem',
                    md: '1rem',
                    lg: '1.5rem',
                    xl: '2rem'
                },
                // padding: '4px',
                // paddingLeft: {
                //     xs: '0.25rem',
                //     sm: '0.5rem',
                //     md: '1rem',
                //     lg: '1.5rem',
                //     xl: '2rem'
                // },
                // borderRadius: '4px',
                // boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                // Additional styles from sx prop
                ...sx,
            }}
        >
            {children}
        </ListItem>
    );
};

export default StyledUserAttrListSection;
