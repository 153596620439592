// WrappedComponents.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PostFisheryDataProvider from '../../data/providers/PostFisheryDataProvider';
import {RequireAuth} from '../auth/RequireAuth';
import FisheryPostsScreen from '../../screens/FisheryPostsScreen';
import { DataStore } from 'aws-amplify';
import { Fishery } from '../../models';
import FisheryDataProvider from "../../data/providers/FisheryDataProvider";
import PortDataProvider from "../../data/providers/PortDataProvider";
import GearTypeDataProvider from "../../data/providers/GearTypeDataProvider";
import DevStateProvider from "../../data/providers/DevStateProvider";

function WrappedFisheryPosts(props) {
    const { fisheryName } = useParams();
    const [fisheryId, setFisheryId] = useState(null);

    // useEffect(() => {
    //     console.log('fisheryName', fisheryName);
    //     console.log('props.ampUser', props.ampUser);
    // }, [fisheryName, props.ampUser]);

    useEffect(() => {
        async function fetchFisheryId() {
            // const regions = await DataStore.query(Region, r => r.name('eq', regionName));
            const fisheries = await DataStore.query(Fishery, r => r.routerPath.eq(fisheryName));
            if (fisheries.length > 0) {
                setFisheryId(fisheries[0].id);
            }
        }

        fetchFisheryId();
    }, [fisheryName]);

    return (
        <DevStateProvider>
        <FisheryDataProvider>
            <PortDataProvider>
                <GearTypeDataProvider>
                    <PostFisheryDataProvider fisheryId={fisheryId}>
                        <RequireAuth>
                            <FisheryPostsScreen routerPaths={props.routerPaths} ampUser={props.ampUser} />
                        </RequireAuth>
                    </PostFisheryDataProvider>
                </GearTypeDataProvider>
            </PortDataProvider>
        </FisheryDataProvider>
        </DevStateProvider>
    );
}

export default WrappedFisheryPosts;
