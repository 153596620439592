import React, {useState, useEffect} from 'react';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, CardActions, Box } from '@mui/material';
import defaultPic from "../../static/images/cards/halibut-circle-hook-outline-1.svg";
import { useNavigate } from "react-router-dom";
import { deployState } from "../../consts";
import BaseLinkButton from "../links/BaseLinkButton";
import StyledListCard from "./StyledListCard";
import StyledCardContent from "./StyledCardContent";
import StyledCardContentTypography from "../text/StyledCardContentTypography";
import {useResponsiveViewport} from "../../data/providers/ResponsiveViewportProvider";


export default function FisheryCard(props) {
    const [fisheryRec, setFisheryRec] = useState({});
    const [cardImage, setCardImage] = useState(defaultPic);
    const [fisheryRoute, setFisheryRoute] = useState('');
    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    useEffect(() => {
        const fisheryRec = props?.fishery;
        if (fisheryRec) {
            setFisheryRec(fisheryRec);
            setFisheryRoute(`/fishery/${fisheryRec.routerPath}`);
        }
        import(`../../static/images/cards/${props.fishery?.cardImageFileName || "halibut-circle-hook-outline-1.svg"}`).then(image => {
            const imagePathDefaultAttr = image.default;
            setCardImage(imagePathDefaultAttr);
        })
    },[props]);

    let navigate = useNavigate();

    // useEffect(() => {
    //     console.log('FisheryCard fisheryRec', fisheryRec);
    // }, [fisheryRec]);

    // useEffect(() => {
    //     console.log('FisheryCard cardImage', cardImage);
    // }, [cardImage]);

    return (
        <StyledListCard
            width={isLargeScreen ? '90%' : '100%'}
        >
            <CardActionArea
                onClick={() => navigate(fisheryRoute === '' ? '/fisheries' : fisheryRoute, {state:{fisheryRec}})}
            >
                <CardMedia
                    component="img"
                    height="100%"
                    image={cardImage}
                    alt={defaultPic}
                />
                <StyledCardContent>
                    <StyledCardContentTypography
                        text={fisheryRec?.name || "name"}
                    />
                </StyledCardContent>
            </CardActionArea>
            <CardActions style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                alignItems: 'flex-start'
            }}
            >
                {deployState === 'signup' ? null : <BaseLinkButton
                    linkTarget="/create"
                    buttonText="Create Post"
                    style={{ width: '100%'}}
                    fontSize={['6vw', '3.5vw', '3vw', '2.5vw']}
                />}
                <Box style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: isLargeScreen ? 12 : 4
                }}
                > {/* New wrapper Box */}
                <BaseLinkButton
                    linkTarget="/regions"
                    buttonText="Regions"
                    fontSize={['5vw', '3vw', '2.5vw', '2vw']}
                />
                <BaseLinkButton
                    linkTarget="/ports"
                    buttonText="Ports"
                    fontSize={['5vw', '3vw', '2.5vw', '2vw']}
                />
                <BaseLinkButton
                    linkTarget="/gear-types"
                    buttonText={isSmallScreen ? "Gear" : "Gear Types"}
                    fontSize={['5vw', '3vw', '2.5vw', '2vw']}
                />
                </Box>
            </CardActions>
        </StyledListCard>
    );
}
