import React, {useState, useEffect, useContext} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { CardActionArea, CardActions } from '@mui/material';
import BaseNavigateButton from "../components/links/BaseNavigateButton";
import {userSubDataObj} from "../consts";
import AmpUserContext from "../data/contexts/AmpUserContext";

export default function ApplyForUserSubScreen(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [userSubData, setUserSubData] = useState(userSubDataObj);
    const [showLogs, setShowLogs] = useState(false);

    const {ampUserRec} = useContext(AmpUserContext);

    // useEffect( setShowLogs(
    useEffect(() => {
        if (props?.showLogs){
            setShowLogs(props.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [props]);

    // useEffect( setAmpUser(props.ampUser);
    useEffect(() => {
        if (ampUserRec) {
            setAmpUser(ampUserRec);
        }
    }, []);

    // useEffect(() => {
    //     if (userSubDataObj) {
    //         setUserSubData(userSubDataObj);
    //     }
    // }, [userSubDataObj]);

    useEffect(() => {
        if (showLogs) {
            console.log('ApplyForUserSubScreen userSubData', userSubData);
        }
    }, [userSubData]);

    // useEffect( console.log('SubscribeCancelScreen(props) ampUser', ampUser);
    // useEffect(() => {
    //     console.log('SubscribeCancelScreen(props) ampUser', ampUser);
    // }, [ampUser]);

    return (
        <div>
        <h2>
            Subscribe to create a new post
        </h2>
        <div className={'Post-success-body'}>
            <Card
                sx={{
                    maxWidth: 460
            }}
            >
                <CardContent>
                    <Typography
                        sx={{
                            padding: '0 0.5vw',
                            margin: '1vh 0.5vw',
                            fontSize: ['5vw', '3vw', '2vw', '1.5vw'],
                        }}
                    >
                        {userSubData ? `$${userSubData.Month.subPrice} for ${userSubData.Month.descriptionText}` : '1 Month subscription'}
                    </Typography>
                    <Typography
                        sx={{
                            padding: '0 0.5vw',
                            margin: '1vh 0.5vw',
                            fontSize: ['5vw', '3vw', '2vw', '1.5vw'],
                        }}
                    >
                        Create unlimited posts
                    </Typography>
                </CardContent>
                <CardActionArea>
                    <CardActions>
                        <BaseNavigateButton
                            label="Subscribe"
                            navTarget="/subscribe"
                            navState={{subLength: userSubData.Month.subText, subPrice: userSubData.Month.subPrice}}
                        />
                    </CardActions>
                </CardActionArea>
                <Divider />
            </Card>
            <Card
                sx={{
                    maxWidth: 460,
                    marginTop: '1.5rem',
            }}
            >
                <CardContent>
                    <Typography
                        sx={{
                            padding: '0 0.5vw',
                            margin: '1vh 0.5vw',
                            fontSize: ['5vw', '3vw', '2vw', '1.5vw'],
                        }}
                    >
                        {userSubData ? `$${userSubData.Quarter.subPrice} for ${userSubData.Quarter.descriptionText}` : 'Quarter year subscription'}
                    </Typography>
                    <Typography
                        sx={{
                            padding: '0 0.5vw',
                            margin: '1vh 0.5vw',
                            fontSize: ['5vw', '3vw', '2vw', '1.5vw'],
                        }}
                    >
                        Create unlimited posts
                    </Typography>
                </CardContent>
                <CardActionArea>
                    <CardActions>
                        <BaseNavigateButton
                            label="Subscribe"
                            navTarget="/subscribe"
                            navState={{subLength: userSubData.Quarter.subText, subPrice: userSubData.Quarter.subPrice}}
                        />
                    </CardActions>
                </CardActionArea>
                <Divider />
            </Card>
            <Card
                sx={{
                    maxWidth: 460,
                    marginTop: '1.5rem',
            }}
            >
                <CardContent>
                    <Typography
                        sx={{
                            padding: '0 0.5vw',
                            margin: '1vh 0.5vw',
                            fontSize: ['5vw', '3vw', '2vw', '1.5vw'],
                        }}
                    >
                        {userSubData ? `$${userSubData.Year.subPrice} for ${userSubData.Year.descriptionText}` : '1 Year subscription'}
                    </Typography>
                    <Typography
                        sx={{
                            padding: '0 0.5vw',
                            margin: '1vh 0.5vw',
                            fontSize: ['5vw', '3vw', '2vw', '1.5vw'],
                        }}
                    >
                        Create unlimited posts
                    </Typography>
                </CardContent>
                <CardActionArea>
                    <CardActions>
                        <BaseNavigateButton
                            label="Subscribe"
                            navTarget="/subscribe"
                            navState={{subLength: userSubData.Year.subText, subPrice: userSubData.Year.subPrice}}
                        />
                    </CardActions>
                </CardActionArea>
                <Divider />
            </Card>
            </div>
        </div>
    )
}