import React, { useContext } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import PortCard from "../cards/PortCard";
import PortContext from "../../data/contexts/PortContext";
import StyledListBox from "../boxes/StyledListBox";
import {useResponsiveViewport} from "../../data/providers/ResponsiveViewportProvider";

function portCards(portRecsArr) {
    return(
        portRecsArr.map((portRec) => {
            // console.log('portRec', portRec);
                const portRegionRec = portRec.Region;
                return(
                    <ListItem
                        key={portRec.id}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <PortCard
                            port={portRec}
                            region={portRegionRec}
                        />
                    </ListItem>
                )
            }
        )
    )
}

export default function PortCardList(props) {

    const { portRecs } = useContext(PortContext);
    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    // useEffect( console.log("portRecs", portRecs);
    // useEffect(() => {
    //     console.log("portRecs", portRecs);
    // }, []);

    return (
        <div>
            <StyledListBox
                width={isLargeScreen ? '90%' : '100%'}
                style={{
                    color: 'white',
                }}
            >
                <h2>Ports</h2>
                <nav aria-label="port cards list">
                    <List>
                        {portCards(portRecs)}
                    </List>
                </nav>
            </StyledListBox>
        </div>
    );
}
