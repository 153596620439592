// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const WaitlistUserStatus = {
  "SUBSCRIBED": "SUBSCRIBED",
  "UNSUBSCRIBED": "UNSUBSCRIBED"
};

const PostStatus = {
  "LIVE": "LIVE",
  "EXPIRED": "EXPIRED",
  "PENDING": "PENDING",
  "REMOVED": "REMOVED",
  "CANCELLED": "CANCELLED",
  "ARCHIVED": "ARCHIVED"
};

const PostType = {
  "CREW": "CREW",
  "GEAR": "GEAR",
  "UNLOAD": "UNLOAD",
  "VESSEL": "VESSEL",
  "VENDOR": "VENDOR"
};

const SubPaymentStatus = {
  "LIVE": "LIVE",
  "EXPIRED": "EXPIRED",
  "PENDING": "PENDING",
  "CANCELLED": "CANCELLED",
  "DELETED": "DELETED"
};

const UserSubStatus = {
  "LIVE": "LIVE",
  "EXPIRED": "EXPIRED",
  "PENDING": "PENDING",
  "CANCELLED": "CANCELLED",
  "DELETED": "DELETED",
  "ARCHIVED": "ARCHIVED"
};

const SubscriptionType = {
  "YEAR": "YEAR",
  "QUARTER": "QUARTER",
  "MONTH": "MONTH"
};

const UserStatus = {
  "FREE": "FREE",
  "SUBSCRIBER": "SUBSCRIBER",
  "REMOVED": "REMOVED",
  "DELETED": "DELETED",
  "PENDING": "PENDING",
  "EXPIRED": "EXPIRED"
};

const { WaitlistUser, Post, SubPayment, UserSub, Country, Port, GearType, Fishery, Region, AmpUser, PostCountry, PostRegion, PostPort, PostFishery, PostGearType, UserSubSubPayment } = initSchema(schema);

export {
  WaitlistUser,
  Post,
  SubPayment,
  UserSub,
  Country,
  Port,
  GearType,
  Fishery,
  Region,
  AmpUser,
  PostCountry,
  PostRegion,
  PostPort,
  PostFishery,
  PostGearType,
  UserSubSubPayment,
  WaitlistUserStatus,
  PostStatus,
  PostType,
  SubPaymentStatus,
  UserSubStatus,
  SubscriptionType,
  UserStatus
};