
/**
 * create obj of region ids by port names
 * @param {Array} regionRecs
 * @param {Array} portRecs
 * @returns {{}}
 */
export function groupRegionsByPortNames(regionRecs, portRecs) {
    // console.log('portRecs', portRecs);
    const regionsByPortNames = {};
    portRecs.forEach((eachPort) => {
        const portRegionId = eachPort.regionID;
        const portName = eachPort.name;
        regionsByPortNames[portName] = portRegionId;
    });
    // console.log('regionsByPortNames', regionsByPortNames);
    return regionsByPortNames;
}