import React, {useState, useEffect} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import { Button, CardActionArea, CardActions } from '@mui/material';
import defaultPic from "../../static/images/cards/halibut-circle-hook-outline-1.svg";
import PostUserContactInfoList from "../lists/PostUserContactInfoList";
import BaseStyledLinkButton from "../links/BaseStyledLinkButton";
import BaseStyledButton from "../buttons/BaseStyledButton";
// import {useResponsiveViewport} from "../../data/providers/ResponsiveViewportProvider";
// import Stack from "@mui/material/Stack";


export default function PostPreviewCard(props) {
    const [cardImage, setCardImage] = useState(defaultPic)
    const [formValues, setFormValues] = useState({});
    const [checkedFormValues, setCheckedFormValues] = useState({});
    const [ampUser, setAmpUser] = useState({});
    const [lookingPortNames, setLookingPortNames] = useState('');
    const [donePortNames, setDonePortNames] = useState('');
    const [userShareAttrs, setUserShareAttrs] = useState({});

    // const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    const postAttrsFontSizes = ['5vw', '3vw', '2vw', '1.5vw']
    // const listItemTextSX = {
    //     color: 'black',
    //     fontSize: ['5rem', '4rem', '3rem', '2rem'],
    // }
    const typographySX = {
        color: 'black',
        fontSize: ['1.5rem', '1.75rem', '1.5rem', '1rem']
    }
    const listSubheaderSX = {
        padding: '0 0.5vw',
        margin: '1vh 0.5vw',
        color: 'black',
        fontSize: ['1.75rem', '2rem', '1.5rem', '1rem']
    }

    // setFormValues(props.formValues);
    useEffect(() => {
        if (props?.formValues) {
            setFormValues(props.formValues);
        }
    },[props]);

    // setCheckedFormValues(props.checkedFormValues);
    useEffect(() => {
        if (props.checkedFormValues) {
            setCheckedFormValues(props.checkedFormValues);
        }
    },[props]);

    // setFormValues(props.ampUser);
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
        }
    },[props]);

    useEffect(() => {
        if (props?.userShareAttrs) {
            setUserShareAttrs(props.userShareAttrs)
        }
    }, [props]);

    // useEffect console.log('userShareAttrs', userShareAttrs);
    // useEffect(() => {
    //     console.log('userShareAttrs', userShareAttrs);
    // }, [userShareAttrs]);

    // useEffect(() => {
    //     console.log('PostPreviewCard ampUser', ampUser);
    // }, [ampUser]);

    useEffect(() => {
        let allDonePortNames = [];
        if (checkedFormValues?.donePortNames) {
            allDonePortNames = allDonePortNames.concat(checkedFormValues.donePortNames);
        }
        if (formValues?.doneRegionPortNames) {
            allDonePortNames = allDonePortNames.concat(formValues.doneRegionPortNames);
        }
        if (allDonePortNames.length > 0) {
            setDonePortNames(allDonePortNames.join(', '));
        }
    }, [checkedFormValues, formValues]);

    useEffect(() => {
        let allLookingPortNames = [];
        if (checkedFormValues?.lookingPortNames) {
            allLookingPortNames = allLookingPortNames.concat(checkedFormValues.lookingPortNames);
        }
        if (formValues?.lookingRegionPortNames) {
            allLookingPortNames = allLookingPortNames.concat(formValues.lookingRegionPortNames);
        }
        if (allLookingPortNames.length > 0) {
            setLookingPortNames(allLookingPortNames.join(', '));
        }
    }, [checkedFormValues, formValues]);

    return (
        <Box sx={{
            width: {
                xs: '90%',    // 0px Full width on extra-small and small devices
                sm: '90%',     // 600px Full width on extra-small and small devices
                md: 420,       // 900px Fixed width on medium devices and up
                // md: '100%',       // Fixed width on medium devices and up
                lg: 600,        // 1200px Fixed width on large devices and up
            },
            maxWidth: 600,
            minWidth: 300,
            bgcolor: 'background.paper',
            color: 'black',
            position: 'relative',
            overflowX: 'hidden',
            overflowY: 'auto',
            '& ul': { padding: 0 },
            '& > :not(style)': { m: 1 }
        }}
        >
        <Card sx={{ maxWidth: 420 }}>
            <CardActionArea>
                <CardContent>
                    <Typography
                        gutterBottom
                        // component="div"
                        sx={{
                            fontSize: postAttrsFontSizes,
                            padding: '0 0.5vw',
                            margin: '1vh 0.5vw',
                        }}
                    >
                        {ampUser?.name || 'missing name'}
                    </Typography>
                    <PostUserContactInfoList
                        userShareAttrs={userShareAttrs}
                        fieldsStyle={{
                            fontSize: postAttrsFontSizes,
                            padding: '0 0.5vw',
                            margin: '1vh 0.5vw',
                        }}
                    />
                    <Divider />
                    <List>
                        {checkedFormValues.lookingRegionNames ? <ListSubheader
                            inset={false}
                            sx={listSubheaderSX}
                        >
                            Looking Regions:
                        </ListSubheader> : null}
                        {checkedFormValues.lookingRegionNames ? <div><Typography
                            sx={typographySX}
                        >
                            {checkedFormValues.lookingRegionNames.join(', ')}
                        </Typography><Divider /></div> : null}
                        {lookingPortNames ? <ListSubheader
                            inset={false}
                            sx={listSubheaderSX}
                        >
                            Looking Ports:
                        </ListSubheader> : null}
                        {lookingPortNames ? <div><Typography
                            sx={typographySX}
                        >
                            {lookingPortNames}
                        </Typography><Divider /></div> : null}
                        {checkedFormValues?.lookingFisheryNames && checkedFormValues?.lookingFisheryNames?.length > 0 ? <ListSubheader
                            sx={listSubheaderSX}
                        >
                            Looking Fisheries:
                        </ListSubheader> : null}
                        {checkedFormValues.lookingFisheryNames ? <div><Typography
                            sx={typographySX}
                        >
                            {checkedFormValues.lookingFisheryNames.join(', ')}
                        </Typography><Divider /></div> : null}
                        {checkedFormValues.lookingGearTypeNames ? <ListSubheader
                            sx={listSubheaderSX}
                        >
                            Looking Gear Types:
                        </ListSubheader> : null}
                        {checkedFormValues.lookingGearTypeNames ? <div><Typography
                            sx={typographySX}
                        >
                            {checkedFormValues.lookingGearTypeNames.join(', ')}
                        </Typography><Divider /></div> : null}
                        {formValues.lookingTextFieldValue ? <ListSubheader
                            sx={listSubheaderSX}
                        >
                            Looking For:
                        </ListSubheader> : null}
                        {formValues.lookingTextFieldValue ? <div><Typography
                            sx={typographySX}
                        >
                            {formValues.lookingTextFieldValue}
                        </Typography><Divider /></div> : null}
                        <Divider />
                        <Divider />
                        {checkedFormValues.doneRegionNames ? <ListSubheader
                            sx={listSubheaderSX}
                        >
                            Done Regions:
                        </ListSubheader> : null}
                        {checkedFormValues.doneRegionNames ? <div><Typography
                            sx={typographySX}
                        >
                            {checkedFormValues.doneRegionNames.join(', ')}
                        </Typography><Divider /></div> : null}
                        {donePortNames ? <ListSubheader
                            inset={false}
                            sx={listSubheaderSX}
                        >
                            Done Ports:
                        </ListSubheader> : null}
                        {donePortNames ? <div><Typography
                            sx={typographySX}
                        >
                            {donePortNames}
                        </Typography><Divider /></div> : null}
                        {checkedFormValues.doneFisheryNames ? <ListSubheader
                            sx={listSubheaderSX}
                        >
                            Done Fisheries:
                        </ListSubheader> : null}
                        {checkedFormValues.doneFisheryNames ? <div><Typography
                            sx={typographySX}
                        >
                            {checkedFormValues.doneFisheryNames.join(', ')}
                        </Typography><Divider /></div> : null}
                        {checkedFormValues.doneGearTypeNames ? <ListSubheader
                            sx={listSubheaderSX}
                        >
                            Done Gear Types:
                        </ListSubheader> : null}
                        {checkedFormValues.doneGearTypeNames ? <div><Typography
                            sx={typographySX}
                        >
                            {checkedFormValues.doneGearTypeNames.join(', ')}
                        </Typography><Divider /></div>: null}
                        {formValues.doneTextFieldValue ? <ListSubheader
                            sx={listSubheaderSX}
                        >
                            Experience:
                        </ListSubheader> : null}
                        {formValues.doneTextFieldValue ? <div><Typography
                            sx={typographySX}
                        >
                            {formValues.doneTextFieldValue}
                        </Typography><Divider /></div> : null}
                        <Divider />
                    </List>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <BaseStyledButton
                    size={'large'}
                    label={'Create Post'}
                    onClick={() => props.onCreatePost()}
                    fontSize={['6vw', '3.5vw', '2.5vw', '2vw']}
                    style={{
                        margin: '0 1vw 0 1vw',
                    }}
                />
                <BaseStyledLinkButton
                    buttonText={'Post'}
                    linkTarget={'/create'}
                    fontSize={['6vw', '3.5vw', '2.5vw', '2vw']}
                />
                <BaseStyledLinkButton
                    buttonText={'Fisheries'}
                    linkTarget={'/fisheries'}
                    fontSize={['6vw', '3.5vw', '2.5vw', '2vw']}
                />
                <BaseStyledLinkButton
                    buttonText={'Ports'}
                    linkTarget={'/ports'}
                    fontSize={['6vw', '3.5vw', '2.5vw', '2vw']}
                />
            </CardActions>
        </Card>
        </Box>
    );
}
