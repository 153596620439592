import React from 'react';
import TextField from '@mui/material/TextField';

const StyledTextField = ({ id, label, value, onChange, maxRows, sx, ...props }) => {
    return (
        <TextField
            id={id}
            label={label}
            maxRows={maxRows}
            value={value}
            onChange={onChange}
            variant="outlined"
            sx={{
                padding: '0 0.5vw',
                margin: '1vh 0.5vw',
                borderRadius: '4px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                ...sx,
            }}
            {...props}
        />
    );
};

export default StyledTextField;
