import { Link } from 'react-router-dom';
import { Button } from "@mui/material";

function UserProfileLink() {
    return (
        <Link to="/user">
            <Button size="small" color="primary">
                User Profile
            </Button>
        </Link>
    );
}

export default UserProfileLink;
