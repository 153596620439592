import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import {useEffect, useState} from "react";
import {disableSelectIfItemChecked, filterModelByChecked, generateUniqueKey} from "../../consts";
import {PostType} from "../../models";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { groupPortsByRegion, groupPortNamesByRegion } from "../../data/group/ports";
import { groupRegionsByPortNames } from "../../data/group/regions";
import {
    doneRegionsToDonePortIds,
    lookingRegionsToLookingPortIds,
    doneRegionsToDonePortNames,
    lookingRegionsToLookingPortNames
} from "../../consts";
import PostPreviewLink from "../links/PostPreviewLink";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function CreateCrewPostForm(props) {
    const [regionRecs, setRegionRecs] = useState([]);
    const [portRecs, setPortRecs] = useState([]);
    const [fisheryRecs, setFisheryRecs] = useState([]);
    const [gearTypeRecs, setGearTypeRecs] = useState([]);
    const [selectedDoneRegions, setSelectedDoneRegions] = useState([]);
    const [selectedDonePorts, setSelectedDonePorts] = useState([]);
    const [selectedLookingRegions, setSelectedLookingRegions] = useState([]);
    const [selectedLookingPorts, setSelectedLookingPorts] = useState([]);
    const [selectedDoneFisheries, setSelectedDoneFisheries] = useState([]);
    const [selectedLookingFisheries, setSelectedLookingFisheries] = useState([]);
    const [selectedDoneGearTypes, setSelectedDoneGearTypes] = useState([]);
    const [selectedLookingGearTypes, setSelectedLookingGearTypes] = useState([]);
    const [defaultChecked, setDefaultChecked] = useState(false);
    const [lookingTextFieldValue, setLookingTextFieldValue] = useState('');
    const [doneTextFieldValue, setDoneTextFieldValue] = useState('');
    const [ampUser, setAmpUser] = useState({});
    const [formValues, setFormValues] = useState({});
    const [checkedFormValues, setCheckedFormValues] = useState({});
    const [personName, setPersonName] = useState([]);
    const [portsByRegion, setPortsByRegion] = useState({});
    const [portNamesByRegion, setPortNamesByRegion] = useState({});
    const [regionsByPortName, setRegionsByPortName] = useState({});
    const [selectedLookingPortsByRegion, setSelectedLookingPortsByRegion] = useState({});
    const [selectedDonePortsByRegion, setSelectedDonePortsByRegion] = useState({});
    const [selectedLookingFisheryNames, setSelectedLookingFisheryNames] = useState([]);
    const [selectedDoneFisheryNames, setSelectedDoneFisheryNames] = useState([]);
    const [selectedLookingGearTypeNames, setSelectedLookingGearTypeNames] = useState([]);
    const [selectedDoneGearTypeNames, setSelectedDoneGearTypeNames] = useState([]);
    const [showLogs, setShowLogs] = useState(false);

    const theme = useTheme();

    // useEffect( setShowLogs(
    useEffect(() => {
        if (props?.showLogs){
            setShowLogs(props.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [props]);

    // useEffect( console.warn('showLogs', showLogs);
    // useEffect(() => {
    //     console.log('showLogs', showLogs);
    // }, [showLogs]);

    // const handleLookingPortSelectChange = (event, regionId)
    const handleLookingPortSelectChange = (event, regionId) => {
        // console.log('event', event);
        // console.log('!!! handleLookingPortSelectChange regionId', regionId)
        const { target: { value }, } = event;
        // console.log('>>> value', value);
        if (value?.length === 0) {
            const newSelectedRegionPorts = {};
            newSelectedRegionPorts[regionId] = value;
            // console.log('value?.length === 0 newSelectedRegionPorts', newSelectedRegionPorts);
            setSelectedLookingPortsByRegion({...selectedLookingPortsByRegion, ...newSelectedRegionPorts});
        }
        if (selectedLookingPortsByRegion.hasOwnProperty(regionId)) {
            const selectedRegionPorts = {};
            selectedRegionPorts[regionId] = value;
            // console.log('<<<>>>selectedRegionPorts', selectedRegionPorts)
            setSelectedLookingPortsByRegion({...selectedLookingPortsByRegion, ...selectedRegionPorts});
        } else {
            const selectedRegionPorts = {};
            selectedRegionPorts[regionId] = value;
            setSelectedLookingPortsByRegion({...selectedLookingPortsByRegion, ...selectedRegionPorts});
        }
    };

    // const handleDonePortSelectChange = (event, regionId)
    const handleDonePortSelectChange = (event, regionId) => {
        // console.log('event', event);
        const { target: { value }, } = event;
        // console.log('value', value);
        if (value?.length === 0) {
            const newSelectedRegionPorts = {};
            newSelectedRegionPorts[regionId] = value;
            // console.log('value?.length === 0 newSelectedRegionPorts', newSelectedRegionPorts);
            setSelectedDonePortsByRegion({...selectedDonePortsByRegion, ...newSelectedRegionPorts});
        }
        if (selectedDonePortsByRegion.hasOwnProperty(regionId)) {
            const selectedRegionPorts = {};
            selectedRegionPorts[regionId] = value;
            // console.log('<<<>>>selectedRegionPorts', selectedRegionPorts)
            setSelectedDonePortsByRegion({...selectedDonePortsByRegion, ...selectedRegionPorts});
        } else {
            const selectedRegionPorts = {};
            selectedRegionPorts[regionId] = value;
            setSelectedDonePortsByRegion({...selectedDonePortsByRegion, ...selectedRegionPorts});
        }
    };

    // const handleLookingFisherySelectChange = (event)
    const handleLookingFisherySelectChange = (event) => {
        // console.log('event', event);
        const { target: { value }, } = event;
        // console.log('!!! handleLookingFisherySelectChange value', value);
        setSelectedLookingFisheryNames(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    // useEffect( console.log('selectedLookingFisheryNames', selectedLookingFisheryNames);
    // useEffect(() => {
    //     console.log('selectedLookingFisheryNames', selectedLookingFisheryNames);
    // }, [selectedLookingFisheryNames]);

    // const handleDoneFisherySelectChange = (event)
    const handleDoneFisherySelectChange = (event) => {
        // console.log('event', event);
        const { target: { value }, } = event;
        // console.log('>>> value', value);
        setSelectedDoneFisheryNames(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    // const handleLookingGearTypeSelectChange = (event)
    const handleLookingGearTypeSelectChange = (event) => {
        // console.log('event', event);
        const { target: { value }, } = event;
        // console.log('value', value);
        setSelectedLookingGearTypeNames(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    // const handleDoneGearTypeSelectChange = (event)
    const handleDoneGearTypeSelectChange = (event) => {
        // console.log('event', event);
        const { target: { value }, } = event;
        // console.log('value', value);
        setSelectedDoneGearTypeNames(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    // useEffect( setFormValues({...formValues, selectedLookingPortNames: selectedPortsByRegion});
    useEffect(() => {
        const selectedLookingPortsObj = {};
        const selectedLookingPortNames = [];
        for (const [key, value] of Object.entries(selectedLookingPortsByRegion)) {
            // console.log(`${key}: ${value}`);
            const regionPorts = portsByRegion[key];
            // console.log('>>> regionPorts', regionPorts);
            const lookingPorts = [];
            regionPorts.forEach((eachRegionPort) => {
                if (value.indexOf(eachRegionPort.name) !== -1) {
                    lookingPorts.push({
                        id: eachRegionPort.id,
                        name: eachRegionPort.name,
                        model: eachRegionPort,
                    });
                    selectedLookingPortNames.push(eachRegionPort.name);
                }
            })
            selectedLookingPortsObj[key] = lookingPorts;
        }
        setCheckedFormValues({...checkedFormValues, lookingPortNames: selectedLookingPortNames});
        setFormValues({...formValues, selectedLookingPorts: selectedLookingPortsObj});
    }, [selectedLookingPortsByRegion]);

    // useEffect( selectedDonePortsObj lookingFisheries setCheckedFormValues( setFormValues(
    useEffect(() => {
        const selectedDonePortsObj = {};
        const selectedDonePortNames = [];
        for (const [key, value] of Object.entries(selectedDonePortsByRegion)) {
            // console.log(`${key}: ${value}`);
            const regionPorts = portsByRegion[key];
            // console.log('>>> regionPorts', regionPorts);
            const donePorts = [];
            regionPorts.forEach((eachRegionPort) => {
                if (value.indexOf(eachRegionPort.name) !== -1) {
                    donePorts.push({
                        id: eachRegionPort.id,
                        name: eachRegionPort.name,
                        model: eachRegionPort,
                    });
                    selectedDonePortNames.push(eachRegionPort.name);
                }
            })
            selectedDonePortsObj[key] = donePorts;
        }
        setCheckedFormValues({...checkedFormValues, donePortNames: selectedDonePortNames});
        setFormValues({...formValues, selectedDonePorts: selectedDonePortsObj});
    }, [selectedDonePortsByRegion]);

    // useEffect( lookingFisheries setCheckedFormValues( setFormValues(
    useEffect(() => {
        // console.log('!!! useEffect(')
        const allLookingFisheryNames = [];
        const lookingFisheries = [];
        fisheryRecs.forEach((eachFishery) => {
            // console.log('fisheryRecs.forEach((eachFishery)', eachFishery);
            // console.log('selectedLookingFisheryNames', allLookingFisheryNames);
            if (selectedLookingFisheryNames.indexOf(eachFishery.name) !== -1) {
                // console.log('!!! FISHERY FOUND')
                lookingFisheries.push({
                    id: eachFishery.id,
                    name: eachFishery.name,
                    model: eachFishery,
                });
                allLookingFisheryNames.push(eachFishery.name);
            }
        })
        setCheckedFormValues({...checkedFormValues, lookingFisheryNames: allLookingFisheryNames});
        setFormValues({...formValues, selectedLookingFisheries: lookingFisheries});
    }, [selectedLookingFisheryNames]);

    // useEffect( doneFisheries setCheckedFormValues( setFormValues(
    useEffect(() => {
        const doneFisheryNames = [];
        const doneFisheries = [];
        fisheryRecs.forEach((eachFishery) => {
            if (selectedDoneFisheryNames.indexOf(eachFishery.name) !== -1) {
                doneFisheries.push({
                    id: eachFishery.id,
                    name: eachFishery.name,
                    model: eachFishery,
                });
                doneFisheryNames.push(eachFishery.name);
            }
        })
        setCheckedFormValues({...checkedFormValues, doneFisheryNames: doneFisheryNames});
        setFormValues({...formValues, selectedDoneFisheries: doneFisheries});
    }, [selectedDoneFisheryNames]);

    // useEffect( doneGearTypes setCheckedFormValues( setFormValues(
    useEffect(() => {
        const doneGearTypeNames = [];
        const doneGearTypes = [];
        gearTypeRecs.forEach((eachGearType) => {
            if (selectedDoneGearTypeNames.indexOf(eachGearType.name) !== -1) {
                doneGearTypes.push({
                    id: eachGearType.id,
                    name: eachGearType.name,
                    model: eachGearType,
                });
                doneGearTypeNames.push(eachGearType.name);
            }
        })
        setCheckedFormValues({...checkedFormValues, doneGearTypeNames: doneGearTypeNames});
        setFormValues({...formValues, selectedDoneGearTypes: doneGearTypes});
    }, [selectedDoneGearTypeNames]);

    // useEffect( lookingGearTypes setCheckedFormValues( setFormValues(
    useEffect(() => {
        const lookingGearTypeNames = [];
        const lookingGearTypes = [];
        gearTypeRecs.forEach((eachGearType) => {
            if (selectedLookingGearTypeNames.indexOf(eachGearType.name) !== -1) {
                lookingGearTypes.push({
                    id: eachGearType.id,
                    name: eachGearType.name,
                    model: eachGearType,
                });
                lookingGearTypeNames.push(eachGearType.name);
            }
        })
        setCheckedFormValues({...checkedFormValues, lookingGearTypeNames: lookingGearTypeNames});
        setFormValues({...formValues, selectedLookingGearTypes: lookingGearTypes});
    }, [selectedLookingGearTypeNames]);

    // useEffect( console.log('portsByRegion', portsByRegion);
    // useEffect(() => {
    //     console.log('portsByRegion', portsByRegion);
    // }, [portsByRegion]);

    // useEffect( console.log('selectedPortsByRegion', selectedPortsByRegion);
    // useEffect(() => {
    //     console.log('selectedLookingPortsByRegion', selectedLookingPortsByRegion);
    // }, [selectedLookingPortsByRegion]);

    // useEffect( console.log('personName', personName);
    // useEffect(() => {
    //     console.log('personName', personName);
    // }, [personName]);

    // useEffect( setRegionsByPortName(groupRegionsByPortNames(regionRecs, portRecs));
    useEffect(() => {
        if (portRecs.length > 0) {
            setRegionsByPortName(groupRegionsByPortNames(regionRecs, portRecs));
        }
    }, [portRecs]);

    // useEffect( console.log('regionsByPortName', regionsByPortName);
    // useEffect(() => {
    //     console.log('regionsByPortName', regionsByPortName);
    // }, [regionsByPortName]);

    // resetValues() reset all the state things
    function resetValues() {
        setSelectedDoneFisheries([]);
        setSelectedLookingFisheries([]);
        setSelectedLookingRegions([]);
        setSelectedDoneRegions([]);
        // setSelectedDoneGearTypes([]);
        // setSelectedLookingGearTypes([]);
        // resetSelectedGearTypes();
        // resetLookingGearTypeCheckboxes();
        // setDefaultChecked(false);
        // TODO - GET FORM VALUES RESETTING, ADD IN CHECK FOR ACTIVE SUBSCRIPTION USER STATUS
    }

    // useEffect( setPortsByRegion(groupPortsByRegion(portRecs));
    useEffect(() => {
        if (portRecs.length > 0) {
            setPortsByRegion(groupPortsByRegion(portRecs));
        }
    }, [portRecs]);

    // useEffect( console.log('portsByRegion', portsByRegion);
    // useEffect(() => {
    //     console.log('portsByRegion', portsByRegion);
    // });

    // useEffect( setPortNamesByRegion(groupPortNamesByRegion(portRecs));
    useEffect(() => {
        if (portRecs.length > 0) {
            setPortNamesByRegion(groupPortNamesByRegion(portRecs));
        }

    }, [portRecs]);

    // useEffect( console.log('portNamesByRegion', portNamesByRegion);
    // useEffect(() => {
    //     console.log('portNamesByRegion', portNamesByRegion);
    // }, [portRecs]);

    // useEffect setAmpUser(props.ampUser)
    useEffect(() => {
        if (props.ampUser) {
            setAmpUser(props.ampUser);
        }
        // console.log('CreatePostScreen(props) props.ampUser', props.ampUser);
    }, [props]);

    // useEffect setRegionRecs(props.regionRecs)
    useEffect(() => {
        if (props.regionRecs) {
            setRegionRecs(props.regionRecs);
        }
    }, [props, regionRecs]);

    // useEffect setPortRecs(props.portRecs);
    useEffect(() => {
        if (props.portRecs) {
            setPortRecs(props.portRecs);
        }
    }, [props, portRecs]);

    // useEffect console.log('CreateCrewPostForm...
    useEffect(() => {
        if (showLogs) {
            console.log('CreateCrewPostForm regionRecs', regionRecs)
        }
        // console.log('CreateCrewPostForm selectedDoneRegions', selectedDoneRegions)
        // console.log('CreateCrewPostForm selectedLookingRegions', selectedLookingRegions)
    // }, [selectedDoneRegions, selectedLookingRegions])
    }, [regionRecs, showLogs])

    // handleChange setValue(event.target.value)
    // const handleChange = (event) => {
    //     setValue(event.target.value);
    // };

    // handleLookingTextFieldChange setLookingTextFieldValue(event.target.value);
    const handleLookingTextFieldChange = (event) => {
        setLookingTextFieldValue(event.target.value);
        setFormValues({...formValues, lookingTextFieldValue: event.target.value})
    };

    // handleDoneTextFieldChange setLookingTextFieldValue(event.target.value);
    const handleDoneTextFieldChange = (event) => {
        setDoneTextFieldValue(event.target.value);
        setFormValues({...formValues, doneTextFieldValue: event.target.value})
    };

    // useEffect( setFisheryRecs(props.fisheryRecs);
    useEffect(() => {
        if (props.fisheryRecs) {
            setFisheryRecs(props.fisheryRecs);
        }
    }, [props, fisheryRecs]);

    // useEffect( setGearTypeRecs(props.gearTypeRecs);
    useEffect(() => {
        if (props.gearTypeRecs) {
            setGearTypeRecs(props.gearTypeRecs);
        }
    }, [props]);

    // useEffect( default setSelectedRegions(defaultSelectedFisheries);
    useEffect(() => {
        if (regionRecs) {
            const defaultSelectedRegions = regionRecs.map((eachRegion) => {
                return {
                    id: `${eachRegion.id}-${Date.now()}`,
                    model: eachRegion,
                    "checked": false,
                    name: eachRegion.name
                }
            });
            setSelectedDoneRegions(defaultSelectedRegions);
            setSelectedLookingRegions(defaultSelectedRegions);
        }
    }, [regionRecs]);

    // useEffect( console.log('>>> checkedFormValues', checkedFormValues);
    useEffect(() => {
        if (showLogs) {
            console.log('!!! checkedFormValues', checkedFormValues);
        }
    }, [checkedFormValues, showLogs]);

    // setSelectedLookingRegions( setFormValues(
    const handleLookingRegionCheckboxChange = (event) => {
        const newValues = selectedLookingRegions.map(eachBox => {
            if (event.target.name === eachBox.name) {
                return {...eachBox, checked: event.target.checked}
            }
            return eachBox;
        });
        setSelectedLookingRegions(newValues);
        const lookingRegions = newValues.filter(filterModelByChecked);
        const lookingRegionNames = lookingRegions.map((eachRegion) => {
            return eachRegion.name;
        });
        setCheckedFormValues({...checkedFormValues, lookingRegions: lookingRegions});
        setCheckedFormValues({...checkedFormValues, lookingRegionNames: lookingRegionNames});

        const lookingRegionPortNames = lookingRegionsToLookingPortNames(newValues, portRecs);
        const lookingRegionPortIds = lookingRegionsToLookingPortIds(newValues, portRecs);
        setFormValues({...formValues,
            selectedLookingRegions: newValues,
            lookingRegionPortIds: lookingRegionPortIds,
            lookingRegionPortNames: lookingRegionPortNames})
    }

    // setSelectedDoneRegions( setFormValues(
    const handleDoneRegionCheckboxChange = (event) => {
        const newValues = selectedDoneRegions.map(eachBox => {
            if (event.target.name === eachBox.name) {
                return {...eachBox, checked: event.target.checked}
            }
            return eachBox;
        });
        setSelectedDoneRegions(newValues);
        const doneRegions = newValues.filter(filterModelByChecked);
        const doneRegionNames = doneRegions.map((eachRegion) => {
            return eachRegion.name;
        });
        setCheckedFormValues({...checkedFormValues, doneRegions: doneRegions});
        setCheckedFormValues({...checkedFormValues, doneRegionNames: doneRegionNames});

        const doneRegionPortNames = doneRegionsToDonePortNames(newValues, portRecs);
        const doneRegionPortIds = doneRegionsToDonePortIds(newValues, portRecs);
        setFormValues({...formValues,
            selectedDoneRegions: newValues,
            doneRegionPortIds: doneRegionPortIds,
            doneRegionPortNames: doneRegionPortNames})
    }

    // setDefaultChecked(bangChecked);
    const handleDefaultCheckboxChange = () => {
        const bangChecked = !defaultChecked;
        setDefaultChecked(bangChecked);
    };

    // useEffect( console.log('formValues', formValues);
    useEffect(() => {
        if (showLogs) {
            console.log('formValues', formValues);
        }
    }, [formValues, showLogs]);

    return (
        <div>
            <h4>Create New Crew Post</h4>
            <Divider/>
            <h2>
                What are you looking for?
            </h2>
            <Divider>
                <Chip label="Ports by Region"/>
            </Divider>
            <div>
                <FormControl
                    row="true"
                    component="fieldset"
                    variant="standard"
                    sx={{
                        marginLeft: '0.5rem',
                        padding: '0 0.25rem'
                    }}
                >
                    <div>
                        {regionRecs ? regionRecs.map((eachRegion) => {
                            return (
                                <FormControlLabel
                                    row="true"
                                    key={generateUniqueKey(eachRegion.id)}
                                    label={eachRegion.name}
                                    control={
                                        <Checkbox
                                            row="true"
                                            checked={selectedLookingRegions[eachRegion.name.checked]}
                                            onChange={handleLookingRegionCheckboxChange}
                                            name={eachRegion.name}
                                        />
                                    }
                                />
                            )
                        }) : <FormControlLabel
                            key={'eachRegion-123'}
                            label={'Region'}
                            control={
                                <Checkbox
                                    row="true"
                                    checked={false}
                                    onChange={handleDefaultCheckboxChange}
                                    name={'Regions'}
                                />
                            }
                        />
                        }
                    </div>
                </FormControl>
            </div>
            <div>
                {regionRecs ? regionRecs.map((eachRegion) => {
                    const regionPortNames = portNamesByRegion[eachRegion.id] || []
                    const selectedRegionPortNames = selectedLookingPortsByRegion[eachRegion.id] || personName;
                    return (
                        <div key={generateUniqueKey(eachRegion.id)}>
                            <FormControl sx={{ m: 1, width: 300 }}>
                            {/*<InputLabel id="demo-multiple-chip-label">{eachRegion.name}</InputLabel>*/}
                                <InputLabel id="demo-multiple-chip-label">{disableSelectIfItemChecked(eachRegion.name, selectedLookingRegions)
                                    ? `all ${eachRegion.name} ports`
                                    : `${eachRegion.name}`}
                                </InputLabel>
                                <Select
                                labelId={`demo-multiple-chip-label-${eachRegion.name}`}
                                id={`demo-multiple-chip-${eachRegion.name}`}
                                multiple
                                disabled={disableSelectIfItemChecked(eachRegion.name, selectedLookingRegions)}
                                value={selectedRegionPortNames}
                                onChange={(e) => handleLookingPortSelectChange(e, eachRegion.id)}
                                input={<OutlinedInput id={`select-multiple-chip-${eachRegion.name}`} label={`Chip-${eachRegion.name}`} />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={generateUniqueKey(value)} label={value} />
                                            // <Chip key={`${value}-${eachRegion.name}`} label={value} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {regionPortNames.map((name) => (
                                    <MenuItem
                                        key={generateUniqueKey(name)}
                                        value={name}
                                        style={getStyles(name, personName, theme)}
                                    >
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                        </div>
                    )
                }
                ) : null }
            </div>
            <Divider>
                <Chip label="Fisheries"/>
            </Divider>
            <div>
                <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id={`demo-multiple-chip-label-Fisheries`}>Fisheries</InputLabel>
                    <Select
                        labelId={`demo-multiple-chip-label-Fisheries`}
                        id={`demo-multiple-chip-Fisheries`}
                        multiple
                        value={selectedLookingFisheryNames}
                        onChange={handleLookingFisherySelectChange}
                        input={<OutlinedInput id={`select-multiple-chip-fisheries`} label={`Chip-fisheries`} />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={generateUniqueKey(value)} label={value} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {fisheryRecs.map((eachFishery) => (
                            <MenuItem
                                key={generateUniqueKey(eachFishery.name)}
                                value={eachFishery.name}
                                style={getStyles(eachFishery.name, personName, theme)}
                            >
                                {eachFishery.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <Divider>
                <Chip label="Gear Types"/>
            </Divider>
            <div>
                <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id={`demo-multiple-chip-label-gear-types`}>Gear Types</InputLabel>
                    <Select
                        labelId={`demo-multiple-chip-label-gear-types`}
                        id={`demo-multiple-chip-gear-types`}
                        multiple
                        value={selectedLookingGearTypeNames}
                        onChange={handleLookingGearTypeSelectChange}
                        input={<OutlinedInput id={`select-multiple-chip-gear-types`} label={`Chip-gear-types`} />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={generateUniqueKey(value)} label={value} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {gearTypeRecs.map((eachRec) => (
                            <MenuItem
                                key={generateUniqueKey(eachRec.name)}
                                value={eachRec.name}
                                style={getStyles(eachRec.name, personName, theme)}
                            >
                                {eachRec.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div>
                <FormControl
                    row="true"
                    component="fieldset"
                    variant="standard"
                    sx={{
                        minWidth: 300
                    }}
                >
                    <TextField
                        id="filled-multiline-flexible"
                        label="Describe the work you are looking for"
                        multiline
                        maxRows={4}
                        value={lookingTextFieldValue}
                        onChange={handleLookingTextFieldChange}
                        variant="filled"
                    />
                </FormControl>
            </div>
            <Divider/>
            <h2>
                What have you done?
            </h2>
            <Divider>
                <Chip label="Regions"/>
            </Divider>
            <div>
                <FormControl
                    row="true"
                    component="fieldset"
                    variant="standard"
                    sx={{
                        marginLeft: '0.5rem',
                        padding: '0 0.25rem'
                    }}
                >
                    <div>
                        {regionRecs ? regionRecs.map((eachRegion) => {
                            return (
                                <FormControlLabel
                                    row="true"
                                    key={generateUniqueKey(eachRegion.id)}
                                    label={eachRegion.name}
                                    control={
                                        <Checkbox
                                            row="true"
                                            checked={selectedDoneRegions[eachRegion.name.checked]}
                                            onChange={handleDoneRegionCheckboxChange}
                                            name={eachRegion.name}
                                        />
                                    }
                                />
                            )
                        }) : <FormControlLabel
                            key={'eachRegion-456'}
                            label={'Region'}
                            control={
                                <Checkbox
                                    row="true"
                                    checked={false}
                                    onChange={handleDefaultCheckboxChange}
                                    name={'Regions'}
                                />
                            }
                        />
                        }
                    </div>
                </FormControl>
            </div>
            <div>
                {regionRecs ? regionRecs.map((eachRegion) => {
                        const regionPortNames = portNamesByRegion[eachRegion.id] || []
                        const selectedRegionPortNames = selectedDonePortsByRegion[eachRegion.id] || personName;
                        return (
                            <div key={generateUniqueKey(eachRegion.id)}>
                                <FormControl sx={{ m: 1, width: 300 }}>
                                    {/*<InputLabel id="demo-multiple-chip-label">{eachRegion.name}</InputLabel>*/}
                                    <InputLabel id="demo-multiple-chip-label">{disableSelectIfItemChecked(eachRegion.name, selectedDoneRegions)
                                        ? `all ${eachRegion.name} ports`
                                        : `${eachRegion.name}`}
                                    </InputLabel>
                                    <Select
                                        labelId={`demo-multiple-chip-label-${eachRegion.name}-done`}
                                        id={`demo-multiple-chip-${eachRegion.name}-done`}
                                        multiple
                                        disabled={disableSelectIfItemChecked(eachRegion.name, selectedDoneRegions)}
                                        value={selectedRegionPortNames}
                                        onChange={(e) => handleDonePortSelectChange(e, eachRegion.id)}
                                        input={<OutlinedInput id={`select-multiple-chip-${eachRegion.name}-done`} label={`Chip-${eachRegion.name}`} />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={generateUniqueKey(value)} label={value} />
                                                    // <Chip key={`${value}-${eachRegion.name}`} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {regionPortNames.map((name) => (
                                            <MenuItem
                                                key={generateUniqueKey(name)}
                                                value={name}
                                                style={getStyles(name, personName, theme)}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        )
                    }
                ) : null }
            </div>
            <Divider>
                <Chip label="Fisheries"/>
            </Divider>
            <div>
                <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id={`demo-multiple-chip-label-fisheries-done`}>Fisheries</InputLabel>
                    <Select
                        labelId={`demo-multiple-chip-label-fisheries-done`}
                        id={`demo-multiple-chip-fisheries-done`}
                        multiple
                        value={selectedDoneFisheryNames}
                        onChange={handleDoneFisherySelectChange}
                        input={<OutlinedInput id={`select-multiple-chip-fisheries-done`} label={`Chip-fisheries`} />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={generateUniqueKey(value)} label={value} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {fisheryRecs.map((eachFishery) => (
                            <MenuItem
                                key={generateUniqueKey(eachFishery.name)}
                                value={eachFishery.name}
                                style={getStyles(eachFishery.name, personName, theme)}
                            >
                                {eachFishery.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <Divider>
                <Chip label="Gear Types"/>
            </Divider>
            <div>
                <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id={`demo-multiple-chip-label-gear-types-done`}>Gear Types</InputLabel>
                    <Select
                        labelId={`demo-multiple-chip-label-gear-types-done`}
                        id={`demo-multiple-chip-gear-types-done`}
                        multiple
                        value={selectedDoneGearTypeNames}
                        onChange={handleDoneGearTypeSelectChange}
                        input={<OutlinedInput id={`select-multiple-chip-gear-types-done`} label={`Chip-gear-types`} />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={generateUniqueKey(value)} label={value} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {gearTypeRecs.map((eachRec) => (
                            <MenuItem
                                key={generateUniqueKey(eachRec.name)}
                                value={eachRec.name}
                                style={getStyles(eachRec.name, personName, theme)}
                            >
                                {eachRec.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div>
                <FormControl
                    row="true"
                    component="fieldset"
                    variant="standard"
                    sx={{
                        minWidth: 300
                    }}
                >
                    <TextField
                        id="filled-multiline-flexible"
                        label="Describe your fishing experience"
                        multiline
                        maxRows={4}
                        // value={value}
                        value={doneTextFieldValue}
                        onChange={handleDoneTextFieldChange}
                        variant="filled"
                    />
                </FormControl>
            </div>
            <Divider/>
            <PostPreviewLink
                postType={PostType.CREW}
                formValues={formValues}
                ampUser={ampUser}
                checkedFormValues={checkedFormValues}
            />
            <Divider/>
        </div>
    )
}