import React, { useState, useEffect, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import Modal from '@mui/material/Modal';
import { AppBarRouterMenu } from "./AppBarRouterMenu";
import {defaultName, deployState, devStateNow} from "../../consts";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { useLocation, useNavigate, } from 'react-router-dom';
import { Amplify } from "aws-amplify";
import aws_exports from '../../aws-exports';
import {Button} from "@mui/material";
import DevStateContext from "../../data/contexts/DevStateContext";
import AmpUserContext from "../../data/contexts/AmpUserContext";
import StyledHeaderButton from "../buttons/StyledHeaderButton";
Amplify.configure(aws_exports);

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const appRoutes = [
    'regions',
    'countries',
    'fisheries',
    'ports',
    'gear-types',
    // 'create',
    '/'
]

function ScrollTop(props) {
    const {children, window} = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = ((event.target).querySelector('#back-to-top-anchor'));
        if (anchor) {
            anchor.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };
}

export default function PrimarySearchAppBar(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [menuItems, setMenuItems] = useState([])
    const [anchorMenuEl, setAnchorMenuEl] = React.useState(null);
    // const [allRouterPaths, setAllRouterPaths] = useState([])
    // const [allRouterPathNames, setAllRouterPathNames] = useState({})
    // const [cognitoUser, setCognitoUser] = useState(null);
    const [userDisplayName, setUserDisplayName] = useState('');
    const [ampUser, setAmpUser] = useState(null);
    const [userAuthenticated, setUserAuthenticated] = useState(false);
    const [showLogs, setShowLogs] = useState(false);
    const [blogUrl, setBlogUrl] = useState('');

    // const [displayNoti, setDisplayNoti] = useState(false);
    // const [networkConnected, setNetworkConnected] = useState( true);
    // const [networkModalText, setNetworkModalText] = useState('');

    const { ampUserRec, setAmpUserRec: setAmpUserRecContext } = useContext(AmpUserContext);
    const { devStateObj } = useContext(DevStateContext);

    const location = useLocation();
    let navigate = useNavigate();
    // useEffect(() => {
    //     if (props.cognitoUser) {
    //         setCognitoUser(props.cognitoUser);
    //     }
    // }, [props]);

    useEffect(() => {
        if (devStateObj) {
            setBlogUrl(devStateObj.blogUrl);
            setShowLogs(devStateObj.showLogs);
        }
    }, [devStateObj]);

    useEffect(() => {
        if (ampUser && setAmpUserRecContext) {
            setAmpUserRecContext(ampUser);
        }
    }, [ampUser, setAmpUserRecContext]);

    // useEffect( console.log('>>> PrimarySearchAppBar ampUserRec', ampUserRec);
    // useEffect(() => {
    //     if (showLogs) {
    //     console.log('>>> PrimarySearchAppBar ampUserRec', ampUserRec);
    //     }
    // }, [ampUserRec, showLogs]);

    useEffect(() => {
        if (showLogs) {
        console.log('blogUrl', blogUrl);
        }
    }, [blogUrl, showLogs]);

    useEffect(() => {
        if (props?.authStatus) {
            if (props.authStatus === 'authenticated') {
                setUserAuthenticated(true);
            } else {
                setUserAuthenticated(false);
            }
        }
    }, [props]);


    // window.addEventListener("offline", (event) => {
    //     setNetworkConnected(false);
    //     setNetworkModalText('network connection is offline');
    //     setDisplayNoti(true);
    //     // displayErrorNoti('lost network connection');
    //     // window.location.reload();
    // });
    //
    // window.addEventListener("online", (event) => {
    //     setNetworkConnected(true);
    //     setNetworkModalText('network connection is back online');
    //     setDisplayNoti(true);
    //     // displayNetworkReconnectedNoti();
    //     // window.location.reload();
    // });

    // todo - should probably group these into a separate component

    // const displayNetworkReconnectedNoti = () => {
    //     setNotiMessage('Connected to network')
    //     setNotiSeverity('info')
    //     setDisplayNoti(true);
    // }

    // const displayRecordAddedNoti = () => {
    //     setNotiMessage('Record Added!')
    //     setNotiSeverity('success')
    //     setDisplayNoti(true);
    // }

    // const displayUploadSuccessNoti = () => {
    //     setNotiMessage('Uploaded File Successfully!')
    //     setNotiSeverity('success')
    //     setDisplayNoti(true);
    // }

    // const displayErrorNoti = (errMsg) => {
    //     setNotiMessage(errMsg)
    //     setNotiSeverity('warning')
    //     setDisplayNoti(true);
    // }

    // const handleNotiClose = (event, reason) => {
    //     if (reason === 'clickaway') {
    //         return;
    //     }
    //     setDisplayNoti(false);
    // }


    // useEffect( setUserDisplayName(
    // useEffect(() => {
    //     if (ampUserRec) {
    //         if (ampUserRec?.name !== defaultName) {
    //             setUserDisplayName(ampUserRec.name)
    //         } else if (ampUserRec?.email) {
    //             setUserDisplayName(ampUserRec.email.split('@')[0])
    //         }
    //     } else {
    //         setUserDisplayName('');
    //     }
    // }, [ampUserRec]);

    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
            if (props.ampUser?.name !== defaultName) {
                setUserDisplayName(props.ampUser.name)
            } else if (props.ampUser?.email) {
                setUserDisplayName(props.ampUser.email.split('@')[0])
            }
        } else {
            setUserDisplayName('');
        }
    }, [props.ampUser]);

    useEffect(() => {
        if (showLogs) {
        console.log('userDisplayName', userDisplayName);
        }
    }, [userDisplayName, showLogs]);

    // useEffect( setMenuItems(appRoutes)
    useEffect(() => {
        setMenuItems(appRoutes);
    }, []);

    // useEffect(() => {
    //     // const routerPathsToNamesRes = routerPathsToNames(props.routerPaths.routerPathNames)
    //     const routerPathsToNamesRes = routerPathsToNames(props.routerPathNames.routerPaths)
    //     // setRouterPathNames(routerPathsToNames)
    //     // console.error('routerPathsToNamesRes', routerPathsToNamesRes)
    //     setAllRouterPathNames(routerPathsToNamesRes)
    //     // console.error('allRouterPathMOFONames', allRouterPathNames)
    // }, [props])

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const open = Boolean(anchorMenuEl);

    const handleRouterMenuClick = (event) => {
        setAnchorMenuEl(event.currentTarget);
    };

    const handleRouterMenuClose = () => {
        setAnchorMenuEl(null);
    };

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    function returnSignOut() {
        // DataStore.clear();
        return (
            props.signOut
        )
    }

    function SignOutModal() {
        const [open, setOpen] = React.useState(false);
        const handleModalOpen = () => {
            setOpen(true);
        };
        const handleModalClose = () => {
            setOpen(false);
        };

        return (
            <React.Fragment>
                {/*<Button*/}
                {/*    variant="contained"*/}
                {/*    // color="secondary"*/}
                {/*    sx={{*/}
                {/*        backgroundColor: '#5F9EA0',*/}
                {/*        color: 'white',*/}
                {/*        // marginLeft: '8px',  // Slight left margin*/}
                {/*        marginLeft: {*/}
                {/*            xs: '0.25rem',  // Assuming base font-size is 16px, this is 4px*/}
                {/*            sm: '0.5rem',   // 8px*/}
                {/*            md: '1rem',     // 16px*/}
                {/*            lg: '1.5rem',   // 24px*/}
                {/*            xl: '2rem'      // 32px*/}
                {/*        },*/}
                {/*        textTransform: 'none',  // Reset the default text transformation*/}
                {/*        '&:hover': {*/}
                {/*            backgroundColor: '#507D7E',  // A darker shade for hover*/}
                {/*        }*/}
                {/*    }}*/}
                {/*    onClick={handleModalOpen}>Sign Out*/}
                {/*</Button>*/}
                <StyledHeaderButton
                    onClick={handleModalOpen}
                >
                    Sign Out
                </StyledHeaderButton>
                <Modal
                    hideBackdrop
                    open={open}
                    onClose={handleModalClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Box sx={{ ...modalStyle, width: 300 }}>
                        <h2 id="child-modal-title">Sign Out</h2>
                        <p id="child-modal-description">
                            Click below to sign out, see you soon!
                        </p>
                        <Button
                            onClick={props.signOut}
                            // variant="contained"
                            // color="secondary"
                        >Sign Out</Button>
                        <Button onClick={handleModalClose}>Cancel</Button>
                    </Box>
                </Modal>
            </React.Fragment>
        );
    }

    // function NetworkConnModal() {
    //     const [open, setOpen] = useState(false);
    //     // const handleOpen = () => setOpen(displayNoti);
    //
    //     useEffect(() => {
    //         setOpen(displayNoti);
    //     }, [displayNoti]);
    //
    //     const handleClose = () => {
    //         setOpen(false);
    //         setDisplayNoti(false);
    //     }
    //
    //     return (
    //         <div>
    //             {/*<Button onClick={handleOpen}>Open modal</Button>*/}
    //             <Modal
    //                 open={open}
    //                 onClose={handleClose}
    //                 aria-labelledby="modal-modal-title"
    //                 aria-describedby="modal-modal-description"
    //             >
    //                 <Box sx={modalStyle}>
    //                     <Typography id="modal-modal-title" variant="h6" component="h2">
    //                         Network Connection Changed
    //                     </Typography>
    //                     <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    //                         {/*{`network is connected: ${networkConnected}`}*/}
    //                         {networkModalText}
    //                     </Typography>
    //                 </Box>
    //             </Modal>
    //         </div>
    //     );
    // }

    // THESE 2 MENUS ARE JUST USED IN THE RETURN FUNCTION
    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            // FULL SCREEN MENU ON THE RIGHT
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem
                onClick={() => navigate('user', {
                    state: {
                        userSub: props.userSub,
                        ampUser: ampUser,
                        subPayment: props.subPayment
                    }
                })}
            >
                My account
            </MenuItem>
            {userAuthenticated ? <MenuItem onClick={returnSignOut()}>Sign Out</MenuItem> : null}
        </Menu>
    );

    // TODO - START HERE, GET ENTIRE USER PROFILE MENU TO DISPLAY, NOT JUST THE SIGN OUT BUTTON
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            // SMALL SCREEN MENU ON THE RIGHT
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {/*<MenuItem>*/}
            {/*    <IconButton*/}
            {/*        size="large"*/}
            {/*        aria-label="show 17 new notifications"*/}
            {/*        color="inherit"*/}
            {/*    >*/}
            {/*        /!*<Badge badgeContent={17} color="error">*!/*/}
            {/*        /!*    <NotificationsIcon />*!/*/}
            {/*        /!*</Badge>*!/*/}
            {/*    </IconButton>*/}
            {/*    <p>Notifications</p>*/}
            {/*</MenuItem>*/}
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
            {/*OTHER OPTION WAS NOT ADDED, RENDERS AS EXPECTED*/}
        </Menu>
    );

    return (
        <Box sx={{
            flexGrow: 1
        }}>
            {/*<AppBar position="fixed">*/}
            <AppBar
                position="sticky"
                sx={{
                backgroundColor: '#486B6C',
                color: 'white',
            }}>
                <Toolbar>
                    {/*BASE APP BAR, TOGGLES ON RIGHT HAND MENU DOTS*/}
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2 }}
                        id="router-menu-button"
                        aria-controls={open ? 'router-menu-button' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleRouterMenuClick}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="router-menu"
                        anchorEl={anchorMenuEl}
                        open={open}
                        onClose={handleRouterMenuClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {anchorMenuEl ? <AppBarRouterMenu /> : null}
                    </Menu>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            fontSize: ['5vw', '4vw', '2vw', '1.5vw'],
                    }}
                    >
                        {userDisplayName ? `Hello ${userDisplayName}` : "FNG"}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} >
                        <StyledHeaderButton
                            onClick={() => navigate('/')}
                        >Fishingigs
                        </StyledHeaderButton>
                    </Box>
                    {/*PRETTY SURE BELOW BOX IS ONE OF THE BADGED ICONS*/}
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {blogUrl ? <StyledHeaderButton
                            onClick={() => window.open(blogUrl, '_blank')}
                        >Blog
                        </StyledHeaderButton> : null}
                        {deployState !== 'signup' ? userAuthenticated ? <SignOutModal /> : <StyledHeaderButton
                            onClick={() => navigate('/login')}>Sign In
                        </StyledHeaderButton> : null}
                        {ampUser ? <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                            fontSize="inherit"
                        >
                            <AccountCircle />
                        </IconButton> : null}
                    </Box>
                    {/*PRETTY SURE BELOW BOX IS ONE OF THE BADGED ICONS*/}
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        {blogUrl ? <StyledHeaderButton
                            onClick={() => window.open(blogUrl, '_blank')}
                        >Blog
                        </StyledHeaderButton> : null}
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {/*TODO - FIGURE OUT HOW THIS IS TOGGLING THE RIGHT HAND MENU*/}
            {renderMobileMenu}
            {renderMenu}
        </Box>
    );
}
