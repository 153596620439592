import React, {useEffect, useState, useContext} from "react";
import CreatePostFormMUI from "../components/forms/CreatePostFormMUI";
import {PostType, PostStatus, UserStatus} from "../models";
import RegionContext from "../data/contexts/RegionContext";
import PortContext from "../data/contexts/PortContext";
import FisheryContext from "../data/contexts/FisheryContext";
import GearTypeContext from "../data/contexts/GearTypeContext";
import DevStateContext from "../data/contexts/DevStateContext";

export default function CreatePostScreen(props) {
    const [postTypeEnum, setPostTypeEnum] = useState({});
    const [postStatusEnum, setPostStatusEnum] = useState({});
    const [userStatusEnum, setUserStatusEnum] = useState({});
    const [regionDataRecs, setRegionDataRecs] = useState([]);
    const [fisheryDataRecs, setFisheryDataRecs] = useState([]);
    const [gearTypeDataRecs, setGearTypeDataRecs] = useState([]);
    const [portDataRecs, setPortDataRecs] = useState([]);
    const [ampUser, setAmpUser] = useState(null);
    // const [portsByRegion, setPortsByRegion] = useState({});
    // const [portNamesByRegion, setPortNamesByRegion] = useState({});
    // const [regionsByPortName, setRegionsByPortName] = useState({});
    const [showLogs, setShowLogs] = useState(false);

    // let navigate = useNavigate();
    // let location = useLocation();

    const { regionRecs } = useContext(RegionContext);
    const { portRecs } = useContext(PortContext);
    const { fisheryRecs } = useContext(FisheryContext);
    const { gearTypeRecs } = useContext(GearTypeContext);
    // const { ampUserRec } = useContext(AmpUserContext);
    const { devStateObj } = useContext(DevStateContext);

    // useEffect( setShowLogs(
    useEffect(() => {
        if (devStateObj?.showLogs){
            setShowLogs(devStateObj.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [devStateObj]);

    // useEffect( console.log('CreatePostScreen ampUser', ampUser);
    useEffect(() => {
        if (showLogs) {
            console.log('CreatePostScreen ampUser', ampUser);
        }
    }, [ampUser, showLogs]);

    // useEffect( console.log('showLogs', showLogs);
    // useEffect(() => {
    //     console.log('showLogs', showLogs);
    // }, [showLogs]);

    // useEffect( setRegionsByPortName(groupRegionsByPortNames(regionRecs, portRecs));
    // useEffect(() => {
    //     if (portDataRecs.length > 0) {
    //         setRegionsByPortName(groupRegionsByPortNames(regionDataRecs, portDataRecs));
    //     }
    // }, [regionDataRecs, portDataRecs]);

    // useEffect( setPortNamesByRegion(groupPortNamesByRegion(portRecs));
    // useEffect(() => {
    //     if (portDataRecs.length > 0) {
    //         setPortNamesByRegion(groupPortNamesByRegion(portDataRecs));
    //     }
    //
    // }, [portDataRecs]);

    // useEffect( setPortsByRegion(groupPortsByRegion(portRecs));
    // useEffect(() => {
    //     if (portDataRecs.length > 0) {
    //         setPortsByRegion(groupPortsByRegion(portDataRecs));
    //     }
    // }, [portDataRecs]);

    // useEffect setAmpUser(props.ampUser)
    useEffect(() => {
        if (props.ampUser) {
            setAmpUser(props.ampUser);
        }
        if (showLogs) {
            console.log('CreatePostScreen(props) props.ampUser', props.ampUser);
            // console.log('CreatePostScreen AmpUserContext ampUserRec', ampUserRec);
            // console.log('CreatePostScreen(props) ampUser', ampUser);
            // console.log('CreatePostScreen ampUser', ampUser);
        }
    }, [props, showLogs]);

    // useEffect setPostTypeEnum(PostType);
    useEffect(() => {
        if (gearTypeRecs) {
            setGearTypeDataRecs(gearTypeRecs);
            // console.warn('PostType', PostType);
        }
    }, [gearTypeRecs]);

    // useEffect setPostTypeEnum(PostType);
    useEffect(() => {
        if (PostType) {
            setPostTypeEnum(PostType);
            // console.warn('PostType', PostType);
        }
    }, []);

    // useEffect setPostStatusEnum(PostStatus);
    useEffect(() => {
        if (PostStatus) {
            setPostStatusEnum(PostStatus);
            // console.warn('PostStatus', PostStatus);
        }
    }, []);

    // useEffect setUserStatusEnum(UserStatus);
    useEffect(() => {
        if (UserStatus) {
            setUserStatusEnum(UserStatus);
            // console.warn('UserStatus', UserStatus);
        }
    }, []);

    // useEffect setRegionRecs(props.regions);
    useEffect(() => {
        if (regionRecs) {
            setRegionDataRecs(regionRecs);
            // console.warn('regionRecs', regionRecs);
        }
    }, [regionRecs]);

    // useEffect setPortRecs(props.ports);
    useEffect(() => {
        if (portRecs) {
            setPortDataRecs(portRecs);
        }
    }, [portRecs]);

    // useEffect console.warn('regionDataRecs', regionDataRecs);
    // useEffect(() => {
    //     // if (props.regions) {
    //         // setRegionRecs(props.regions);
    //         // TODO - START HERE, WHY NO REGIONS TO HERE?
    //         if (showLogs) {
    //             // console.warn('props.regions', props.regions);
    //             console.warn('regionDataRecs', regionDataRecs);
    //         }
    //     // }
    // }, [showLogs]);

    // useEffect setFisheryRecs(props.fisheries)
    useEffect(() => {
        if (fisheryRecs) {
            setFisheryDataRecs(fisheryRecs);
            // console.warn('CreatePostScreen(props) fisheryRecs', fisheryRecs);
        }
    }, [fisheryRecs]);

    return (
        <div>
            <h2 style={{color: "#000"}} >Create Your New Post</h2>
            <CreatePostFormMUI
                showLogs={showLogs}
                gearTypeRecs={gearTypeDataRecs}
                ampUser={ampUser}
                postTypes={postTypeEnum}
                postStatus={postStatusEnum}
                userStatus={userStatusEnum}
                regionRecs={regionRecs}
                fisheryRecs={fisheryDataRecs}
                portRecs={portDataRecs}
            />
        </div>
    )
}