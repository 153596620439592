import React from 'react';
import ListItem from '@mui/material/ListItem';

const StyledUserAttrListSection = ({ children, sx, style }) => {
    return (
        <ListItem
            sx={{
                margin: style?.margin || '0.75vh 0.75vw',
                padding: style?.padding || '0.75vh 0.75vw',
                // borderRadius: '4px',
                // boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                // Additional styles from sx prop
                ...sx,
            }}
        >
            {children}
        </ListItem>
    );
};

export default StyledUserAttrListSection;
