import React, {useContext, useEffect, useState} from "react";
import RouterBreadcrumbs from "../components/router/RouterBreadcrumbs";
import { useParams } from "react-router-dom";
import {breadcrumbRoutesRegionsScreen, routerPathsToNames} from "../consts";
import BasicRouterMenu from "../components/router/BasicRouterMenu";
import {breadcrumbRoutesSingleRegion} from "../consts";
import { useLocation } from "react-router-dom";
import RegionPostsCardList from "../components/lists/RegionPostsCardList";
import {modelIdNameFactory} from "../consts";
import PortContext from "../data/contexts/PortContext";
import FisheryContext from "../data/contexts/FisheryContext";
import GearTypeContext from "../data/contexts/GearTypeContext";
import PostRegionContext from "../data/contexts/PostRegionContext";
import {groupPostRegionRecsByRegion} from "../data/group/postRegions";
import DevStateContext from "../data/contexts/DevStateContext";

export default function RegionPostsScreen(props) {
    // const [regionUserPosts, setRegionPosts] = useState([])
    const [regionRouterPaths, setRegionRouterPaths] = useState({})
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([])
    const [regionName, setRegionName] = useState('');
    const [thisRegionId, setThisRegionId] = useState('');
    const [thisRegionPosts, setThisRegionPosts] = useState([])
    const [fisheryIdNames, setFisheryIdNames] = useState({});
    const [gearTypeIdNames, setGearTypeIdNames] = useState({});
    const [portIdNames, setPortIdNames] = useState([]);
    // const [networkConnected, setNetworkConnected] = useState( true)
    const [postRecsByRegion, setPostRecsByRegion] = useState({});
    const [showLogs, setShowLogs] = useState(false);

    const { portRecs } = useContext(PortContext);
    const { fisheryRecs } = useContext(FisheryContext);
    const { gearTypeRecs } = useContext(GearTypeContext);
    const { postRegionRecs } = useContext(PostRegionContext);
    const { devStateObj } = useContext(DevStateContext);

    const location = useLocation();

    // useEffect(() => { setShowLogs(devStateObj.showLogs);
    useEffect(() => {
        if (devStateObj) {
            setShowLogs(devStateObj.showLogs);
        }
    }, [devStateObj]);

    // useEffect( console.log('RegionPostsCardList devStateObj', devStateObj);
    useEffect(() => {
        if (showLogs) {
            console.log('RegionPostsCardList devStateObj', devStateObj);
        }
    }, [devStateObj, showLogs]);

    // useEffect setRegionName(location.state.regionRec.name) setPostRegionRecs(location.state.postRegionRecs);
    useEffect(() => {
        if (location.state) {
            setRegionName(location.state.regionRec.name);
            setThisRegionId(location.state.regionRec.id);
        }
    }, [props, location]);

    // setRegionRouterPaths(routerPathsToNamesRes['region']);
    useEffect(() => {
        const routerPathsToNamesRes = routerPathsToNames(props.routerPaths.routerPaths)
        setRegionRouterPaths(routerPathsToNamesRes['region']);
        if (showLogs) {
            console.log('routerPathsToNamesRes', routerPathsToNamesRes)
            console.log('regionRouterPaths', regionRouterPaths)
        }
    }, [props, showLogs]);

    const params = useParams();
    const regionPath = params['regionName'];

    // setThisRegionPosts(thisRegionPostsVal);
    useEffect(() => {
        if (params) {
            const thisRegionPostsVal = postRecsByRegion[regionPath];
            setThisRegionPosts(thisRegionPostsVal);
        }
    }, [postRecsByRegion, location]);

    // setBreadcrumbRoutes(breadcrumbRoutesSingleRegion(regionPath)
    useEffect(() => {
        if (regionPath) {
            setBreadcrumbRoutes(breadcrumbRoutesSingleRegion(regionPath) || [])
        } else {
            setBreadcrumbRoutes(breadcrumbRoutesRegionsScreen() || [])
        }
    }, [props]);

    // useEffect( setFisheries(props.fisheries); setFisheryIdNames(modelIdNameFactory(props.fisheries));
    useEffect(() => {
        if (fisheryRecs) {
            setFisheryIdNames(modelIdNameFactory(fisheryRecs));
        }
    }, [fisheryRecs]);

    // useEffect( setGearTypes(props.gearTypes); setGearTypeIdNames(modelIdNameFactory(props.gearTypes));
    useEffect(() => {
        if (gearTypeRecs) {
            setGearTypeIdNames(modelIdNameFactory(gearTypeRecs));
        }
    }, [gearTypeRecs]);

    // useEffect( setPorts(props.ports); setPortIdNames(modelIdNameFactory(props.ports));
    useEffect(() => {
        if (portRecs) {
            setPortIdNames(modelIdNameFactory(portRecs));
        }
    }, [portRecs]);

    // useEffect( console.log('RegionPostsScreen(props) props', props);
    useEffect(() => {
        if (showLogs) {
            console.log('RegionPostsScreen(props) props', props);
        }
    }, [props, showLogs]);

    // useEffect( setPostRecsByRegion(recsByRegion);
    useEffect(() => {
        if (postRegionRecs) {
            (async () => {
                const recsByRegion = await groupPostRegionRecsByRegion(postRegionRecs);
                setPostRecsByRegion(recsByRegion);
            })();
        }
    }, [postRegionRecs]);

    // useEffect( console.log('postRecsByRegion', postRecsByRegion);
    useEffect(() => {
        if (showLogs) {
            console.log('RegionPostsScreen(postRecsByRegion) postRecsByRegion', postRecsByRegion);
        }
    }, [postRecsByRegion, showLogs]);

    // <RegionPostsCardList
    function regionUserPostsList() {
        if (postRecsByRegion) {
            return (
                <RegionPostsCardList
                    showLogs={showLogs}
                    ampUser={props.ampUser}
                    regionName={regionName}
                    regionPosts={postRecsByRegion}
                    thisRegionPosts={thisRegionPosts}
                    fisheryIdNames={fisheryIdNames}
                    gearTypeIdNames={gearTypeIdNames}
                    portIdNames={portIdNames}
                />
            )
        } else {
            // console.error('render default transition element, e.g. a spinner')
            return (
                <div>
                    <p>
                        default transition element
                    </p>
                </div>
            )
        }
    }

    return (
        // <div className={"Posts-body"}>
        <div style={{
            backgroundColor: 'lightgray',
            minHeight: '15vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 'calc(10px + 2vmin)',
            color: 'white',
            marginBottom: '5vh'
        }}>
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes}/>
            <div>
            </div>
            <BasicRouterMenu menuRouterPaths={regionRouterPaths}/>
            <div>
                {regionUserPostsList()}
            </div>
        </div>
    )
}