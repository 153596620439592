import React from "react";
import {
    Link
} from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import {ROUTER_PATHS} from "../../consts";

/*
* USE THIS ONE IN THE APP BAR
* * */
export function AppBarRouterMenu() {

    return (
        <div>
            <nav>
                <ul>
                    {ROUTER_PATHS.map((option) => (
                        <MenuItem key={`router-menu-path-${option}`} selected={option === 'regions'} >
                            {option === '/' ? <Link to={option}>home</Link> : <Link to={option}>{option}</Link>}
                        </MenuItem>
                    ))}
                </ul>
            </nav>
        </div>
    );
}

// export function RouterMenuRouterPathToNames(pathsToNamesObj) {
//     // console.warn('routerMenuRouterPathToNames(pathsToNamesObj)', pathsToNamesObj)
//     const pathsToNamesEntries = Object.entries(pathsToNamesObj);
//     // console.warn('pathsToNamesEntries', pathsToNamesEntries)
//     return (
//         <div>
//             <nav>
//                 <ul>
//                     <li><p>
//                         list item
//                     </p></li>
//                     {}
//                     {/*{pathsToNamesObj.keys().map((pathToNameObj) => {*/}
//                     {/*    // <MenuItem key={`router-menu-path-${option}`} selected={option === 'regions'} onClick={handleClose}>*/}
//                     {/*    return <MenuItem key={`router-menu-path-${pathToNameObj}`} selected={option === 'regions'} >*/}
//                     {/*        <Link to={option}>{option}</Link>*/}
//                     {/*    </MenuItem>*/}
//                     {/*})}*/}
//                 </ul>
//             </nav>
//         </div>
//     );
// }

// function returnAllPaths(pathsObj) {
//     const allPathsArr = [];
//     ROUTER_PATHS.forEach((eachPath) => {
//         if (pathsObj.hasOwnProperty(eachPath)) {
//             pathsObj.forEach((eachNestedPath) => {
//                 allPathsArr.push(`${eachPath}/${eachNestedPath}`)
//             })
//         } else {
//             allPathsArr.push(`${eachPath}`)
//         }
//     })
//
//     return allPathsArr
// }

// export function AppBarAllRouterPathsMenu(recsObj) {
//
//     return (
//         <div>
//             <nav>
//                 <ul>
//                     {returnAllPaths(recsObj).map((eachPath) => (
//                         // <MenuItem key={`router-menu-path-${option}`} selected={option === 'regions'} onClick={handleClose}>
//
//                         <MenuItem key={`router-menu-path-${eachPath}`} selected={eachPath === 'home'} >
//                             <Link to={eachPath}>{eachPath}</Link>
//                         </MenuItem>
//                     ))}
//                 </ul>
//             </nav>
//         </div>
//     );
// }
