import React, {useState, useEffect} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {Box, Button, CardActionArea, CardActions, Divider} from '@mui/material';
import {deleteUserPost} from "../../consts";
import Modal from "@mui/material/Modal";
import PostUserContactInfoList from "../lists/PostUserContactInfoList";
import SinglePostLink from "../links/SinglePostLink";
import BaseLinkButton from "../links/BaseLinkButton";
import BaseStyledLinkButton from "../links/BaseStyledLinkButton";
// import BaseStyledDeleteButton from "../buttons/BaseStyledButton";
import BaseStyledDeleteButton from "../buttons/BaseStyledDeleteButton";
import StyledListCard from "./StyledListCard";
import {useResponsiveViewport} from "../../data/providers/ResponsiveViewportProvider";
import StyledPostSectionHeaderTypography from "../text/StyledPostSectionHeaderTypography";
import StyledPostSectionContentTypography from "../text/StyledPostSectionContentTypography";

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

function fisheriesList(fisheryNames) {
    // console.log('fisheryNames', fisheryNames);
    return (
        <StyledPostSectionContentTypography
            text={fisheryNames.join(', ')}
        />

    )
}

function regionsList(regionNames) {
    // console.log('regionNames', regionNames);
    return (
        <StyledPostSectionContentTypography
            text={regionNames.join(', ')}
        />
    )
}

function portsList(portNames) {
    // console.log('portNames', portNames);
    return (
        <StyledPostSectionContentTypography
            text={portNames.join(', ')}
        />
    )
}

function gearTypesList(gearTypeNames) {
    // console.log('gearTypeNames', gearTypeNames);
    return (
        <StyledPostSectionContentTypography
            text={gearTypeNames.join(', ')}
        />
    )
}

export default function PostCard(props) {
    const [postRec, setPostRec] = useState({});
    // const [fisheryNames, setFisheryNames] = useState(null);
    const [doneFisheryNames, setDoneFisheryNames] = useState(null);
    const [lookingFisheryNames, setLookingFisheryNames] = useState(null);
    // const [regionNames, setRegionNames] = useState(null);
    const [doneRegionNames, setDoneRegionNames] = useState(null);
    const [lookingRegionNames, setLookingRegionNames] = useState(null);
    // const [portNames, setPortNames] = useState(null);
    const [donePortNames, setDonePortNames] = useState(null);
    const [lookingPortNames, setLookingPortNames] = useState(null);
    // const [gearTypeNames, setGearTypeNames] = useState(null);
    const [doneGearTypeNames, setDoneGearTypeNames] = useState(null);
    const [lookingGearTypeNames, setLookingGearTypeNames] = useState(null);

    const [showLogs, setShowLogs] = useState(false);

    const [showDeleteButton, setShowDeleteButton] = useState(false);
    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
    const [lookingUserText, setLookingUserText] = useState('');
    const [doneUserText, setDoneUserText] = useState('');
    const [userShareAttrs, setUserShareAttrs] = useState({});
    const [isContactInfoObscured, setIsContactInfoObscured] = useState(true);
    const [userOwnsPost, setUserOwnsPost] = useState(false);

    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    // useEffect( setUserOwnsPost(true);
    useEffect(() => {
        if (props?.post && props?.ampUserId) {
            const postOwnerId = props.post.ampuserID;
            const ampUserId = props.ampUserId;
            if (postOwnerId === ampUserId) {
                setUserOwnsPost(true);
            }
        }
    }, [props]);

    // ueEffect( console.log('userOwnsPost', userOwnsPost);
    // useEffect(() => {
    //     console.log('userOwnsPost', userOwnsPost);
    // }, [userOwnsPost]);

    // useEffect( console.log('showDeleteButton', showDeleteButton);
    // useEffect(() => {
    //     console.log('showDeleteButton', showDeleteButton);
    // }, [showDeleteButton]);

    // useEffect( setUserShareAttrs(props.userShareAttrs);
    useEffect(() => {
        if (props?.userShareAttrs) {
            setUserShareAttrs(props.userShareAttrs);
        }
    }, [props.userShareAttrs]);

    // useEffect( console.log('userShareAttrs', userShareAttrs);
    useEffect(() => {
        if (showLogs) {
            console.log('userShareAttrs', userShareAttrs);
        }
    }, [userShareAttrs, showLogs]);

    const handleOpenModal = () => {
        setDisplayDeleteModal(true);
    };

    const handleCloseModal = () => {
        setDisplayDeleteModal(false);
    };

    const DeleteModal = ({ open, onClose, postId }) => {
        return (
            <Modal open={open} onClose={onClose}>
                <div style={{ backgroundColor: '#fff', padding: '1rem' }}>
                    <h2>Confirm Deleting the Post</h2>
                    {/*<p>Here is some content for the modal.</p>*/}
                    <Button
                        variant="contained"
                        color="warning"
                        onClick={() => {
                            deleteUserPost(postId);
                            onClose();
                        }}
                    >
                        Delete Post
                    </Button>
                    <Button onClick={() => setDisplayDeleteModal(false)}>Cancel</Button>
                </div>
            </Modal>
        );
    };

    // useEffect( setShowLogs(props.showLogs);
    useEffect(() => {
        if (props?.showLogs) {
            setShowLogs(props.showLogs);
        }
    }, [props]);

    // useEffect( console.log('showLogs', showLogs);
    useEffect(() => {
        if (showLogs) {
            console.log('showLogs', showLogs);
        }
    }, [showLogs]);

    // useEffect( setPostRec(postRec); setDoneUserText(postRec.postBody.doneTextFieldValue); setLookingUserText(postRec.postBody.lookingTextFieldValue);
    useEffect(() => {
        const postRec = props?.post;
        if (postRec) {
            setPostRec(postRec);
            setDoneUserText(postRec.postBody.doneTextFieldValue);
            setLookingUserText(postRec.postBody.lookingTextFieldValue);
        }
    },[props]);

    // useEffect( setShowDeleteButton(props.showDelete);
    useEffect(() => {
        if (userOwnsPost) {
            setShowDeleteButton(true);
        }
    }, [userOwnsPost]);

    // useEffect( setDoneFisheryNames( setLookingFisheryNames(
    useEffect(() => {
        if (props?.doneFisheryNames) {
            if (props.doneFisheryNames.length > 0) {
                setDoneFisheryNames(props.doneFisheryNames);
            }
        }
        if (props?.lookingFisheryNames) {
            if (props.lookingFisheryNames.length > 0) {
                setLookingFisheryNames(props.lookingFisheryNames);
            }
        }
    },  [props]);

    // useEffect( show lots of names logs
    useEffect(() => {
        if (showLogs) {
            console.log('doneFisheryNames', doneFisheryNames);
            console.log('lookingFisheryNames', props.lookingFisheryNames);
            console.log('doneGearTypeNames', props.doneGearTypeNames);
            console.log('lookingGearTypeNames', props.lookingGearTypeNames);
            console.log('doneRegionNames', props.doneRegionNames);
            console.log('lookingRegionNames', props.lookingRegionNames);
        }
    }, [showLogs, doneGearTypeNames, doneRegionNames, donePortNames, doneFisheryNames, lookingFisheryNames, lookingGearTypeNames, lookingRegionNames, lookingPortNames]);

    // useEffect( setDoneGearTypeNames(
    useEffect(() => {
        if (props?.doneGearTypeNames) {
            if (props.doneGearTypeNames.length > 0) {
                setDoneGearTypeNames(props.doneGearTypeNames);
            }
        }
        if (props?.lookingGearTypeNames) {
            if (props.lookingGearTypeNames.length > 0) {
                setLookingGearTypeNames(props.lookingGearTypeNames);
            }
        }
    }, [props]);

    // useEffect( setDoneRegionNames( setLookingRegionNames(
    useEffect(() => {
        if (props?.doneRegionNames) {
            if (props.doneRegionNames.length > 0) {
                setDoneRegionNames(props.doneRegionNames);
            }
            // setDoneRegionNames(props.doneRegionNames);
        }
        if (props?.lookingRegionNames) {
            if (props.lookingRegionNames.length > 0) {
                setLookingRegionNames(props.lookingRegionNames);
            }
            // setLookingRegionNames(props.lookingRegionNames);
        }
    }, [props]);

    // useEffect( setDonePortNames( setLookingPortNames(
    useEffect(() => {
        if (props?.donePortNames) {
            if (props.donePortNames.length > 0) {
                setDonePortNames(props.donePortNames);
            }
            // setDonePortNames(props.donePortNames);
        }
        if (props?.lookingPortNames) {
            if (props.lookingPortNames.length > 0) {
                setLookingPortNames(props.lookingPortNames);
            }
            // setLookingPortNames(props.lookingPortNames);
        }
    }, [props]);

    // useEffect( console.log('PostCard props', props);
    useEffect(() => {
        if (showLogs) {
            console.log('PostCard props', props);
        }
        // console.log('PostCard props', props);
    }, [props, showLogs]);

    const handleDisplayContactInfoToggle = () => {
        setIsContactInfoObscured(!isContactInfoObscured);
    };

    return (
        <StyledListCard
            width={isLargeScreen ? '90%' : '100%'}
        >
            <CardActionArea>
                <CardContent>
                    <Typography gutterBottom align={'center'} variant="h4" component="div">
                        {postRec?.postType || 'missing postType'}
                    </Typography>
                    <Divider />
                        <div onClick={handleDisplayContactInfoToggle}>
                            <StyledPostSectionHeaderTypography
                                text={'Post Contact Info:'}
                            />
                            {isContactInfoObscured ? <StyledPostSectionContentTypography
                                text={"Click to reveal"}
                                fontSize={'1.75rem'}
                            /> : <PostUserContactInfoList userShareAttrs={userShareAttrs} userOwnsPost={userOwnsPost} />}
                            <Divider />
                        </div>
                        <div>
                            {!isContactInfoObscured && userOwnsPost ? <div>
                                <StyledPostSectionContentTypography
                                    text={'Want to update your shared contact info?'}
                                />
                                <BaseLinkButton
                                    linkTarget="/profile"
                                    buttonText="Update Profile"
                                    fontSize={['5vw', '3vw', '1.5vw', '1.5vw']}
                                />
                        </div> : null}
                    </div>
                    <Divider />
                    {/* TODO - ADJUST FONT SIZES BASED ON VIEWPORT */}
                    {/* TODO - MAKE SURE THIS ONLY DISPLAYS IF VALUES ARE PRESENT */}
                    {doneFisheryNames ? <div>
                            <StyledPostSectionHeaderTypography
                                text={'Done Fisheries:'}
                            />
                                {fisheriesList(doneFisheryNames)}
                        </div>
                        : null}
                    {lookingFisheryNames ? <div>
                            <StyledPostSectionHeaderTypography
                                text={'Looking Fisheries:'}
                            />
                                {fisheriesList(lookingFisheryNames)}
                        </div>
                        : null}
                    <Divider />
                    {doneGearTypeNames ? <div>
                        <StyledPostSectionHeaderTypography
                            text={'Done Gear Types:'}
                        />
                                {gearTypesList(doneGearTypeNames)}
                        </div>
                        : null}
                    {lookingGearTypeNames ? <div>
                            <StyledPostSectionHeaderTypography
                                text={'Looking Gear Types:'}
                            />
                            {gearTypesList(lookingGearTypeNames)}
                        </div>
                        : null}
                    <Divider />
                    {doneRegionNames ? <div>
                        <StyledPostSectionHeaderTypography
                            text={'Done Regions:'}
                        />
                                {regionsList(doneRegionNames)}
                        </div>
                        : null}
                    {lookingRegionNames ? <div>
                        <StyledPostSectionHeaderTypography
                            text={'Looking Regions:'}
                        />
                                {regionsList(lookingRegionNames)}
                        </div>
                        : null}
                    <Divider />
                    {donePortNames ? <div>
                            <StyledPostSectionHeaderTypography
                                text={'Done Ports:'}
                            />
                            {portsList(donePortNames)}
                        </div>
                        : null}
                    {lookingPortNames ? <div>
                            <StyledPostSectionHeaderTypography
                                text={'Looking Ports:'}
                            />
                            {portsList(lookingPortNames)}
                        </div>
                        : null}
                    <Divider />
                    <Divider />
                    {lookingUserText ? <div>
                            <StyledPostSectionHeaderTypography
                                text={'Looking User Text:'}
                            />
                        <StyledPostSectionContentTypography
                            text={lookingUserText}
                        />
                        </div>
                        : null}
                    {doneUserText ? <div>
                            <StyledPostSectionHeaderTypography
                                text={'Done User Text:'}
                            />
                        <StyledPostSectionContentTypography
                            text={doneUserText}
                        />
                        </div>
                        : null}
                    <Divider />
                    <Divider />
                </CardContent>
            </CardActionArea>
            <Divider />
            <Divider />
            <div
                style={{
                    margin: '0.25rem 0 0.25rem 0',
                }}
            >
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    {/* TODO - TOGGLE THIS BACK IN WHEN EDITING POSTS IS ENABLED */}
                    {/*<SinglePostLink postRouterPath={postRec.routerPath} postRec={postRec} />*/}
                    <BaseStyledLinkButton
                        linkTarget="/regions"
                        buttonText="Regions"
                        fontSize={['5vw', '3vw', '1.5vw', '1.5vw']}
                    />
                    <BaseStyledLinkButton
                        linkTarget="/fisheries"
                        buttonText="Fisheries"
                        fontSize={['5vw', '3vw', '1.5vw', '1.5vw']}
                    />
                </Box>
            </div>
            <div
                style={{
                    margin: '0 0 0.75rem 0',
                }}
            >
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <BaseStyledLinkButton
                        linkTarget="/ports"
                        buttonText="Ports"
                        fontSize={['5vw', '3vw', '1.5vw', '1.5vw']}
                    />
                    <BaseStyledLinkButton
                        linkTarget="/gear-types"
                        buttonText="Gear Types"
                        fontSize={['5vw', '3vw', '1.5vw', '1.5vw']}
                    />
                </Box>
            </div>
            {showDeleteButton ? <div>
                <Divider />
                <Box style={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
                }}>
                    <BaseStyledDeleteButton
                        size="small"
                        onClick={() => {
                            if (showLogs) {
                                console.log('delete post');
                            }
                            setDisplayDeleteModal(true);
                        }}
                        label={'Delete Your Post'}
                    />
                </Box>
            </div> : null}
            {displayDeleteModal ? <DeleteModal open={handleOpenModal} close={handleCloseModal} postId={postRec.id} /> : null}
        </StyledListCard>
    );
}
