import React, { useEffect, useState, useContext } from "react";
import Stack from '@mui/material/Stack';
import RouterBreadcrumbs from "../components/router/RouterBreadcrumbs";
import {breadcrumbRoutesRegionsScreen, deployState} from "../consts"
import RegionCardList from "../components/lists/RegionCardList";
import RegionContext from "../data/contexts/RegionContext";
import WaitlistUserCreateForm from "../ui-components/WaitlistUserCreateForm";

export default function RegionsScreen(props) {
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([])

    const { regionRecs } = useContext(RegionContext);

    // useEffect( setBreadcrumbRoutes
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesRegionsScreen() || [])
    }, [props]);

    return (
        <div className={"home-screen-cards"}>
            {deployState === 'signup' ? <WaitlistUserCreateForm onSuccess={() => {
                alert('Added to the Fishingigs wait list successfully!');
            }
            }/> : null}
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes} />
            <Stack spacing={2}>
                <RegionCardList
                    regionRecs={regionRecs}
                />
            </Stack>
        </div>
    );
}