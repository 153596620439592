import React from 'react';
import {WaitlistUserStatus} from "../../models";
// import { AmplifyTheme } from 'aws-amplify-react';
import WaitlistUserCreateForm from '../../ui-components/WaitlistUserCreateForm'; // Adjust import path if needed
import CryptoJS from 'crypto-js';

function createUniqueToken(email) {
    const timestamp = Date.now().toString();
    const randomNumber = Math.floor(Math.random() * (999999 - 100000) + 100000).toString(); // Generating a random 6-digit number
    const dataToHash = email + timestamp + randomNumber;

    const hash = CryptoJS.SHA256(dataToHash).toString(CryptoJS.enc.Hex);
    return hash;
}

const customSubmitStyle = {
    backgroundColor: '#5F9EA0', // Replace with your desired color.
    // transition: 'background-color 0.3s ease', // Optional: Smooth transition effect for the color change.
    '&:hover': {
        backgroundColor: '#507D7E' // Replace with your desired hover color.
    }
};

const customClearStyle = {
    // backgroundColor: '#5F9EA0', // Replace with your desired color.
    // transition: 'background-color 0.3s ease', // Optional: Smooth transition effect for the color change.
    '&:hover': {
        backgroundColor: '#507D7E' // Replace with your desired hover color.
    }
};

const customTextFieldStyle = {
    '&:focusWithin': {
        borderColor: '#5F9EA0', // Make the border color the same as the button's background color when the input is focused.
        boxShadow: '0 0 0 1px #5F9EA0' // This is for additional focus styling that some browsers like Chrome use.
    }
};

const WrappedWaitlistUserCreateForm = (props) => {

    const customOverrides = {
        SubmitButton: {
            style: customSubmitStyle
        },
        ClearButton: {
            style: customClearStyle
        },
        email: { // This is assuming the "key" for the email TextField override is "email" as seen in the original component.
            style: customTextFieldStyle
        }
    };

    const combinedOverrides = {
        ...props.overrides, // In case there are other overrides passed in from the parent
        ...customOverrides
    };

    const handleSubmit = (fields) => {
        // Obtain email from fields
        const email = fields.email;

        // Generate the unique token using the email
        const uniqueToken = createUniqueToken(email);

        // Modify the fields object as needed
        const updatedFields = {
            ...fields,
            waitlistUserStatus: WaitlistUserStatus.SUBSCRIBED,
            uniqueToken: uniqueToken,
        };

        // If the parent component passed an onSubmit prop, call it with the modified fields
        if (props.onSubmit) {
            return props.onSubmit(updatedFields);
        }

        // Otherwise, return the modified fields directly
        return updatedFields;
    };

    return <WaitlistUserCreateForm {...props} onSubmit={handleSubmit} overrides={combinedOverrides}/>;
};

export default WrappedWaitlistUserCreateForm;
