import React, { useEffect, useState, useContext } from "react";
import Stack from '@mui/material/Stack';
import RouterBreadcrumbs from "../components/router/RouterBreadcrumbs";
import {breadcrumbRoutesFisheriesScreen, deployState} from "../consts"
import FisheryCardList from "../components/lists/FisheryCardList";
import FisheryContext from "../data/contexts/FisheryContext";
import WaitlistUserCreateForm from "../ui-components/WaitlistUserCreateForm";

export default function FisheriesScreen(props) {
    // const [fisheries, setFisheries] = useState([])
    // const [countries, setCountries] = useState([])
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([])
    const [routerPaths, setRouterPaths] = useState({})
    const [postRecsByFishery, setPostRecsByFishery] = useState({})

    const { fisheryRecs } = useContext(FisheryContext);

    // setBreadcrumbRoutes
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesFisheriesScreen() || [])
        // console.error('breadcrumbRoutes', breadcrumbRoutes)
    }, [props]);

    // setRouterPaths(allPaths)
    // useEffect(() => {
    //     const allPaths = {
    //         // 'country': countries,
    //         // 'region': regions,
    //         'fishery': fisheries
    //     }
    //     setRouterPaths(allPaths)
    // },[countries, fisheries]);

    // setPostRecsByRegion(props.postRecsByRegion);
    useEffect(() => {
        if (props.postRecsByFishery) {
            setPostRecsByFishery(props.postRecsByFishery);
        }
    }, [props]);


    return (
        <div className={"home-screen-cards"}>
            {deployState === 'signup' ? <WaitlistUserCreateForm onSuccess={() => {
                alert('Added to the Fishingigs wait list successfully!');
            }
            }/> : null}
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes} />
            <Stack spacing={2}>
                <FisheryCardList
                    fisheryRecs={props.fisheryRecs}
                    postRecsByFishery={postRecsByFishery}
                />
            </Stack>
        </div>
    );
}