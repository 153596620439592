import {useContext, useEffect, useState} from "react";
import { Post, PostStatus, PostType } from "../models";
import PostPreviewCard from "../components/cards/PostPreviewCard";
import {
    createPostFisheryModelsByIds,
    createPostRegionsByIds,
    createPostGearTypeModelsByIds,
    createPostPortByIds
} from "../data/create/crewPost";
import {
    useNavigate,
    useLocation,
} from "react-router-dom";
import {DataStore} from "aws-amplify";
import md5 from "md5";
import {Box, Button} from "@mui/material";
import {devStateNow, filterModelByChecked, formatRegionIdSet, msSubMap, userShareAttrsFactory} from "../consts";
// import {doneRegionsToDonePortIds, lookingRegionsToLookingPortIds } from "../consts"
import PortContext from "../data/contexts/PortContext";
import RegionContext from "../data/contexts/RegionContext";
import BaseStyledButton from "../components/buttons/BaseStyledButton";

// TOOD - BIG TODO - GET AMPUSER HERE VIA CONTEXT SO REFRESHES DON'T LOSE USER AND THROW
export default function PostPreviewScreen(props) {
    const [ampUser, setAmpUser] = useState({});
    const [formValues, setFormValues] = useState({});
    const [checkedFormValues, setCheckedFormValues] = useState({});
    const [postType, setPostType] = useState(null)
    const [ports, setPorts] = useState([])
    const [showLogs, setShowLogs] = useState(false);
    // const [setSelectedLookingRegions, setSetSelectedLookingRegions] = useState(true);
    // const [setSelectedDoneRegions, setSetSelectedDoneRegions] = useState(true);
    const [userShareAttrs, setUserShareAttrs] = useState(null);
    const [regions, setRegions] = useState([]);

    const { portRecs } = useContext(PortContext);
    const { regionRecs } = useContext(RegionContext);

    let navigate = useNavigate();
    let location = useLocation();

    // TODO - ADD REGION PORTS TO PREVIEW CARD,
    //  ADD REGION PORTS TO NEW REC STILL NOT WORKING

    // useEffect( console.log('PostPreviewScreen(props)', props);
    useEffect(() => {
        if (showLogs) {
            console.log('PostPreviewScreen(props)', props);
        }
    }, [props, showLogs]);

    // useEffect( console.log('formValues', formValues);
    useEffect(() => {
        if (showLogs) {
            console.log('PostPreviewScreen(props) formValues', formValues);
        }
    }, [formValues, showLogs]);

    // useEffect( console.log('location', location);
    useEffect(() => {
        if (showLogs) {
            console.log('location', location);
        }
    }, [location, showLogs]);

    // useEffect( setRegions(regionRecs);
    useEffect(() => {
        if (regionRecs) {
            setRegions(regionRecs);
        }
    }, [regionRecs]);

    // useEffect( setPorts(portRecs);
    useEffect(() => {
        if (portRecs) {
            setPorts(portRecs);
        }
    }, [portRecs]);

    // useEffect( setShowLogs(
    useEffect(() => {
        if (props?.showLogs){
            setShowLogs(props.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [props]);

    // useEffect( setFormValues(location.state.formValues);
    useEffect(() => {
        if (location?.state?.formValues) {
            setFormValues(location.state.formValues);
        }
    }, [location]);

    // function setLookingPortsBasedOnLookingRegions(lookingRegions) {
    //     const lookingRegionPortIds = lookingRegionsToLookingPortIds(lookingRegions, ports);
    // }

    // useEffect( setAmpUser(props.ampUser || location.state.ampUser);
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
        }
        else if (location.state.ampUser) {
            setAmpUser(location.state.ampUser);
        }
    }, [props, location]);

    // useEffect( setUserShareAttrs(userShareAttrs);
    useEffect(() => {
        if (ampUser) {
            // setAmpUser(props.ampUser);
            const userShareAttrs = userShareAttrsFactory(ampUser);
            setUserShareAttrs(userShareAttrs);
        }
    }, [ampUser]);

    // useEffect( setPostType(location.state.postType);
    useEffect(() => {
        if (location?.state?.postType) {
            setPostType(location.state.postType);
        }
    }, [location]);

    // useEffect( console.log('PostPreviewScreen(props) postType', postType);
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('PostPreviewScreen(props) postType', postType);
    //     }
    // }, [postType, showLogs]);

    // useEffect( setCheckedFormValues(location.state.checkedFormValues);
    useEffect(() => {
        if (location?.state?.checkedFormValues) {
            setCheckedFormValues(location.state.checkedFormValues);
        }
    }, [location]);

    // useEffect( console.log('PostPreviewScreen(props) checkedFormValues', checkedFormValues);
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('PostPreviewScreen(props) checkedFormValues', checkedFormValues);
    //     }
    // }, [checkedFormValues, showLogs]);

    // onCreatePost new Post(
    // async function onCreatePost(postType, formValues) {
    async function onCreatePost() {
        const bodyData = {};
        const idsObj = {};
        if (formValues.lookingTextFieldValue) {
            // bodyData.lookingTextFieldValue = lookingTextFieldValue;
            bodyData.lookingTextFieldValue = formValues.lookingTextFieldValue;
        }
        if (formValues.doneTextFieldValue) {
            // bodyData.doneTextFieldValue = doneTextFieldValue;
            bodyData.doneTextFieldValue = formValues.doneTextFieldValue;
        }

        // TODO - START HERE, ADD PORTS BY REGION TO ALL PORTS,
        //  ADD RELATED MODELS
        let allRegionIds = [];
        if (formValues.selectedDoneRegions) {
            const unformattedDoneRegionIds = [];
            for (const eachModel of formValues.selectedDoneRegions) {
                if (eachModel.checked) {
                    if (showLogs) {
                        console.log('formValues.selectedDoneRegions eachModel', eachModel);
                    }
                    unformattedDoneRegionIds.push(eachModel.id);
                }
            }
            const formattedDoneRegionIds = formatRegionIdSet(unformattedDoneRegionIds);
            allRegionIds = formattedDoneRegionIds;
            if (showLogs) {
                console.log('allRegionIds', allRegionIds);
            }
            idsObj.doneRegionIDs = allRegionIds;
        }

        const allRegionIdSet = new Set(allRegionIds);
        const lookingRegionIds = [];
        if (formValues.selectedLookingRegions) {
            const unformattedLookingRegionIds = [];
            for (const eachModel of formValues.selectedLookingRegions) {
                if (eachModel.checked) {
                    if (showLogs) {
                        console.log('formValues.selectedLookingRegions eachModel', eachModel);
                    }
                    unformattedLookingRegionIds.push(eachModel.id);
                }
            }
            const formattedLookingRegionIds = formatRegionIdSet(unformattedLookingRegionIds);
            lookingRegionIds.push(...formattedLookingRegionIds);
            if (showLogs) {
                console.log('lookingRegionIds', lookingRegionIds);
            }
            idsObj.lookingRegionIDs = lookingRegionIds;
            for (const eachRegionId of lookingRegionIds) {
                allRegionIdSet.add(eachRegionId);
            }
        }
        idsObj.regionIDs = Array.from(allRegionIdSet);
        if (showLogs) {
            console.log('idsObj.regionIDs', idsObj.regionIDs);
        }

        // TODO - START HERE - DECIDE ON PORTS HERE BASED ON SELECTED REGIONS, ADD MENU + SUB MENU FOR PORTS
        let allPortIds = [];
        if (formValues.selectedLookingPorts) {
            for (const [key, value] of Object.entries(formValues.selectedLookingPorts)) {
                // console.log(`${key}: ${value}`);
                const lookingPortIds = value.map((v) => v.id);
                allPortIds = allPortIds.concat(lookingPortIds);
                idsObj.lookingPortIDs = allPortIds;
            }
        }
        if (formValues.selectedDonePorts) {
            for (const [key, value] of Object.entries(formValues.selectedDonePorts)) {
                // console.log(`${key}: ${value}`);
                const donePortIds = value.map((v) => v.id);
                let portIdSet = new Set(allPortIds);
                for (const portId of donePortIds) {
                    portIdSet = portIdSet.add(portId);
                }
                allPortIds = allPortIds.concat(Array.from(portIdSet));
                idsObj.donePortIDs = donePortIds;
            }
        }
        if (formValues.lookingRegionPortIds) {
            let portIdSet = new Set(allPortIds);
            for (const portId of formValues.lookingRegionPortIds) {
                portIdSet = portIdSet.add(portId);
            }
            allPortIds = Array.from(portIdSet);
            if (!idsObj.lookingPortIDs) {
                idsObj.lookingPortIDs = [];
            }
            idsObj.lookingPortIDs = idsObj.lookingPortIDs.concat(formValues.lookingRegionPortIds);
        }
        if (formValues.doneRegionPortIds) {
            let portIdSet = new Set(allPortIds);
            for (const portId of formValues.doneRegionPortIds) {
                portIdSet = portIdSet.add(portId);
            }
            allPortIds = Array.from(portIdSet);
            if (!idsObj.donePortIDs) {
                idsObj.donePortIDs = [];
            }
            idsObj.donePortIDs = idsObj.donePortIDs.concat(formValues.doneRegionPortIds);
        }
        idsObj.portIDs = allPortIds;
        // console.log('!!! >>> idsObj.portIDs', idsObj.portIDs);
        // console.log('!!! >>> idsObj.donePortIDs', idsObj.donePortIDs);
        // console.log('!!! >>> idsObj.lookingPortIDs', idsObj.lookingPortIDs);

        let allFisheryIds = [];
        if (formValues.selectedLookingFisheries) {
            const lookingFisheryIds = []
            for (const [key, value] of Object.entries(formValues.selectedLookingFisheries)) {
                lookingFisheryIds.push(value.id);
            }
            allFisheryIds = allFisheryIds.concat(lookingFisheryIds);
            idsObj.lookingFisheryIDs = lookingFisheryIds;
        }
        if (formValues.selectedDoneFisheries) {
            const doneFisheryIds = [];
            for (const [key, value] of Object.entries(formValues.selectedDoneFisheries)) {
                doneFisheryIds.push(value.id);
            }
            allFisheryIds = allFisheryIds.concat(doneFisheryIds);
            idsObj.doneFisheryIDs = doneFisheryIds;
        }
        idsObj.fisheryIDs = allFisheryIds;

        let allGearTypeIds = [];
        if (formValues.selectedDoneGearTypes) {
            const doneGearTypeIds = [];
            for (const [key, value] of Object.entries(formValues.selectedDoneGearTypes)) {
                // console.log(`${key}: ${value}`);
                doneGearTypeIds.push(value.id);
            }
            allGearTypeIds = allGearTypeIds.concat(doneGearTypeIds);
            idsObj.doneGearTypeIDs = doneGearTypeIds;
        }
        if (formValues.selectedLookingGearTypes) {
            const lookingGearTypeIds = [];
            for (const [key, value] of Object.entries(formValues.selectedLookingGearTypes)) {
                // console.log(`${key}: ${value}`);
                lookingGearTypeIds.push(value.id);
            }
            allGearTypeIds = allGearTypeIds.concat(lookingGearTypeIds);
            idsObj.lookingGearTypeIDs = lookingGearTypeIds;
        }
        idsObj.gearTypeIDs = allGearTypeIds;

        // TODO - Notify user of length of post being live, right now is one month
        // TODO - ADD REGIONS TO VALIDATE NEW MODELS COMING THROUGH ON POSTS PAGE
        const newRec = await DataStore.save(
            new Post({
                title: `${Date.now()} new title`,
                begin: Date.now(),
                end: Date.now() + msSubMap.month,
                postType: postType,
                postBody: bodyData || {"post": "body"},
                postStatus: PostStatus.LIVE,
                email: ampUser.email,
                phone: ampUser.phone,
                routerPath: md5(`${ampUser.name}-${postType}-${formValues.body}-${Date.now()}`),
                doneRegionIDs: idsObj.doneRegionIDs,
                lookingRegionIDs: idsObj.lookingRegionIDs,
                doneFisheryIDs: idsObj.doneFisheryIDs,
                lookingFisheryIDs: idsObj.lookingFisheryIDs,
                doneGearTypeIDs: idsObj.doneGearTypeIDs,
                lookingGearTypeIDs: idsObj.lookingGearTypeIDs,
                donePortIDs: idsObj.donePortIDs,
                lookingPortIDs: idsObj.lookingPortIDs,
                ampuserID: ampUser.id,
            })
        );
        if (showLogs) {
            console.log('newRec', newRec);
        }
        createPostFisheryModelsByIds(newRec, idsObj.doneFisheryIDs, idsObj.lookingFisheryIDs, showLogs);
        createPostGearTypeModelsByIds(newRec, idsObj.doneGearTypeIDs, idsObj.lookingGearTypeIDs, showLogs);
        createPostPortByIds(newRec, idsObj.portIDs, showLogs);
        createPostRegionsByIds(newRec, idsObj.doneRegionIDs, idsObj.lookingRegionIDs, regions, showLogs);
        navigate('/post-success', { state: {ampUser, newRec} });
    }
    // TODO - ? ADD CHECK FOR ONLY 1 LIVE POST IN A CATEGORY AT A TIME ?
    // TODO - THEN MOVE ON TO VIEWING LISTS OF CARDS OF POSTS BY MODEL RELATION

    // useEffect( console.log('PostPreviewScreen(props) checkedFormValues', checkedFormValues);
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('PostPreviewScreen(props) checkedFormValues', checkedFormValues);
    //     }
    // }, [checkedFormValues, showLogs]);

    // useEffect(() => {
    //     console.log('PostPreviewScreen(props) formValues', formValues);
    // }, [formValues]);

    // TODO - NEXT GET THE CARD CENTERED, THEN FILL THE CARD WITH VALUES
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                }}
        >
            <div>
                <h2>Post Preview Screen</h2>
            </div>
            {/*<Divider />*/}
            <div className={'Post-preview-body'}>
                <PostPreviewCard
                    ampUser={ampUser}
                    formValues={formValues}
                    checkedFormValues={checkedFormValues}
                    userShareAttrs={userShareAttrs}
                    onCreatePost={onCreatePost}
                />
            </div>
            {/*<Divider />*/}
            <div className={"button-bottom-space"}>
                {/*{}*/}
                {/*<Button*/}
                {/*    size={'large'}*/}
                {/*    sx={{*/}
                {/*        textTransform: 'none',*/}
                {/*        backgroundColor: '#5F9EA0',*/}
                {/*    }}*/}
                {/*    variant="contained"*/}
                {/*    onClick={() => onCreatePost()}>*/}
                {/*    Create Post*/}
                {/*</Button>*/}
                <BaseStyledButton
                    size={'large'}
                    label={'Create Post'}
                    onClick={() => onCreatePost()}
                    fontSize={['6vw', '3.5vw', '2.5vw', '2vw']}
                    style={{
                      margin: '0 1vw 0 1vw',
                    }}
                />
            </div>
        </Box>
    )
}