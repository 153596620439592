import {useState, useEffect} from "react";
import { PostType } from "../../models";
import Box from '@mui/material/Box';
import "../../styles/App.css"
import { FormControl, FormLabel, Select, MenuItem } from '@mui/material';
import CreateCrewPostForm from "./CreateCrewPostForm";
import CreateGearPostForm from "./CreateGearPostForm";
import CreateUnloadPostForm from "./CreateUnloadPostForm";
import CreateVesselPostForm from "./CreateVesselPostForm";
import CreateVendorPostForm from "./CreateVendorPostForm";
import {Typography} from "@mui/material";
import {groupRegionsByPortNames} from  '../../data/group/regions';
import {groupPortNamesByRegion, groupPortsByRegion} from "../../data/group/ports";

// export default function FormPropsTextFields() {
export default function CreatePostFormMUI(props) {
    const [regionRecs, setRegionRecs] = useState([]);
    const [portRecs, setPortRecs] =  useState([]);
    const [fisheryRecs, setFisheryRecs] = useState([]);
    const [gearTypeRecs, setGearTypeRecs] = useState([])
    const [selectedPostType, setSelectedPostType] = useState('');
    const [postStatusEnum, setPostStatusEnum] = useState({});
    const [userStatusEnum, setUserStatusEnum] = useState({});
    const [ampUser, setAmpUser] = useState({})
    const [livePostTypes, setLivePostTypes] = useState(new Set());
    const [portsByRegion, setPortsByRegion] = useState({});
    const [portNamesByRegion, setPortNamesByRegion] = useState({});
    const [regionsByPortName, setRegionsByPortName] = useState({});

    // useEffect( setRegionsByPortName(groupRegionsByPortNames(regionRecs, portRecs));
    useEffect(() => {
        if (portRecs.length > 0) {
            setRegionsByPortName(groupRegionsByPortNames(regionRecs, portRecs));
        }
    }, [portRecs, regionRecs]);

    // useEffect( setPortNamesByRegion(groupPortNamesByRegion(portRecs));
    useEffect(() => {
        if (portRecs.length > 0) {
            setPortNamesByRegion(groupPortNamesByRegion(portRecs));
        }

    }, [portRecs]);

    // useEffect( setPortsByRegion(groupPortsByRegion(portRecs));
    useEffect(() => {
        if (portRecs.length > 0) {
            setPortsByRegion(groupPortsByRegion(portRecs));
        }
    }, [portRecs]);

    // useEffect setUserAttrs(props.ampUser)
    useEffect(() => {
        if (props.ampUser) {
            setAmpUser(props.ampUser);
        }
        // console.log('CreatePostScreen(props) props.ampUser', props.ampUser)
    }, [props]);

    // useEffect setRegionRecs(props.regionRecs);
    useEffect(() => {
        if (props.regionRecs) {
            setRegionRecs(props.regionRecs);
            // console.warn('regionRecs', regionRecs);
        }
    }, [props]);

    // useEffect setPortRecs(props.portRecs);
    useEffect(() => {
        if (props.portRecs) {
            setPortRecs(props.portRecs);
        }
    }, [props]);

    // useEffect setFisheryRecs(props.fisheryRecs);
    useEffect(() => {
        if (props.fisheryRecs) {
            setFisheryRecs(props.fisheryRecs);
            // console.warn('CreatePostFormMUI fisheryRecs', fisheryRecs);
        }
    }, [props, fisheryRecs]);

    // useEffect setGearTypeRecs(props.gearTypeRecs);
    useEffect(() => {
        if (props.gearTypeRecs) {
            setGearTypeRecs(props.gearTypeRecs);
            // console.warn('CreatePostFormMUI( gearTypeRecs', gearTypeRecs);
        }
    }, [props.gearTypeRecs]);

    const handlePostTypeOptions = () => {
        if (PostType) {
            return Object.keys(PostType).map((eachPostType) => {
                return (
                    <MenuItem key={eachPostType} value={eachPostType}>
                        {eachPostType}
                    </MenuItem>
                );
            });
        } else {
            return (
                <MenuItem value="post-type-default">
                    PostType
                </MenuItem>
            );
        }
    }

    const handlePostTypeChange = (event) => {
        setSelectedPostType(event.target.value);
    };

    function selectedPostForm(){
        switch (selectedPostType) {
            case 'CREW':
                return (
                    <CreateCrewPostForm
                        showLogs={props.showLogs}
                        postType={selectedPostType}
                        postStatus={postStatusEnum}
                        userStatus={userStatusEnum}
                        regionRecs={regionRecs}
                        portRecs={portRecs}
                        fisheryRecs={fisheryRecs}
                        gearTypeRecs={gearTypeRecs}
                        ampUser={ampUser}
                        portsByRegion={portsByRegion}
                        portNamesByRegion={portNamesByRegion}
                        regionsByPortName={regionsByPortName}
                    />
                )
            case 'VESSEL':
                return (
                    <CreateVesselPostForm
                        showLogs={props.showLogs}
                        postType={selectedPostType}
                        postStatus={postStatusEnum}
                        userStatus={userStatusEnum}
                        regionRecs={regionRecs}
                        portRecs={portRecs}
                        fisheryRecs={fisheryRecs}
                        gearTypeRecs={gearTypeRecs}
                        ampUser={ampUser}
                        portsByRegion={portsByRegion}
                        portNamesByRegion={portNamesByRegion}
                        regionsByPortName={regionsByPortName}

                    />
                )
            case 'GEAR':
                return (
                    <CreateGearPostForm
                        showLogs={props.showLogs}
                        postType={selectedPostType}
                        postStatus={postStatusEnum}
                        userStatus={userStatusEnum}
                        regionRecs={regionRecs}
                        portRecs={portRecs}
                        fisheryRecs={fisheryRecs}
                        gearTypeRecs={gearTypeRecs}
                        ampUser={ampUser}
                        portsByRegion={portsByRegion}
                        portNamesByRegion={portNamesByRegion}
                        regionsByPortName={regionsByPortName}

                    />
                )
            case 'UNLOAD':
                return (
                    <CreateUnloadPostForm
                        showLogs={props.showLogs}
                        postType={selectedPostType}
                        postStatus={postStatusEnum}
                        userStatus={userStatusEnum}
                        regionRecs={regionRecs}
                        portRecs={portRecs}
                        fisheryRecs={fisheryRecs}
                        gearTypeRecs={gearTypeRecs}
                        ampUser={ampUser}
                        portsByRegion={portsByRegion}
                        portNamesByRegion={portNamesByRegion}
                        regionsByPortName={regionsByPortName}

                    />
                )
            case 'VENDOR':
                return (
                    <CreateVendorPostForm
                        showLogs={props.showLogs}
                        postType={selectedPostType}
                        postStatus={postStatusEnum}
                        userStatus={userStatusEnum}
                        regionRecs={regionRecs}
                        portRecs={portRecs}
                        fisheryRecs={fisheryRecs}
                        gearTypeRecs={gearTypeRecs}
                        ampUser={ampUser}
                        portsByRegion={portsByRegion}
                        portNamesByRegion={portNamesByRegion}
                        regionsByPortName={regionsByPortName}
                    />
                )
            default:
                return (
                    <Typography>
                        Select a Post Type
                    </Typography>
                    )
        }
    }

    return (
        <div
            style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflowY: 'auto'
        }}
        >
        <Box
            component="form"
            sx={{
                backgroundColor: 'white',
                '& .MuiTextField-root': { m: 1, width: '90%' }
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <FormControl
                    fullWidth
                    sx={{
                        width: '100%',
                        padding: '12px',
                    }}
                >
                    <Select
                        sx={{
                            background: 'white',
                            marginTop: '16px',
                            minWidth: {
                                xs: '200px',  // 100% width on small viewports
                                sm: '260px',
                                md: '320px'  // 400px width from the md breakpoint and up
                            },
                            mx: 'auto',
                        }}
                        labelId="post-type-select-label"
                        defaultValue=''
                        value={selectedPostType}
                        onChange={handlePostTypeChange}
                        name="post-type-select"
                    >
                        {handlePostTypeOptions()}
                    </Select>
                </FormControl>
            </div>
            {selectedPostForm()}
        </Box>
            </div>
    );
}
