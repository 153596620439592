import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { SortDirection } from "aws-amplify";
import {Post, PostFishery} from '../../models';
import PostFisheryContext from "../contexts/PostFisheryContext";

const PostFisheryDataProvider = ({ children, fisheryId }) => {
    const [postFisheryRecs, setPostFisheryRecs] = useState([]);

    useEffect(() => {
        // console.log('PostFisheryDataProvider fisheryId', fisheryId);
        if (fisheryId) {
            const subscription = DataStore.observeQuery(PostFishery,
                pr => pr.and(pr => [
                    pr.fisheryId.eq(fisheryId)
                ]),
                {
                    sort: s => s.createdAt(SortDirection.ASCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    // console.log(`PostFishery pr [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                    // console.log('PostFishery pr [Snapshot] items', items);
                    if (isSynced) {
                        // Filter the items based on the related model's postStatus
                        const filteredItems = await Promise.all(items.filter(async item => {
                            const post = await DataStore.query(Post, item.postID);
                            return post.postStatus === "LIVE";
                        }));
                        // Update the posts data
                        setPostFisheryRecs(filteredItems);
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
    }, [fisheryId]); // Dependency array now includes fisheryId
    return (
        <PostFisheryContext.Provider value={{ postFisheryRecs: postFisheryRecs }}>
            {children}
        </PostFisheryContext.Provider>
    );
};

export default PostFisheryDataProvider;
