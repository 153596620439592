import React, {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Routes, Route, Link, useParams } from "react-router-dom";


export default function BasicRouterMenu(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [routerMenuItems, setRouterMenuItems] = useState({});
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        // console.log('BasicRouterMenu(props.menuRouterPaths)', props.menuRouterPaths)
        setRouterMenuItems(props.menuRouterPaths || {'message': 'loading...'})
    }, [props])

    return (
        <div>
            {/*<Button*/}
            {/*    id="basic-button"*/}
            {/*    aria-controls={open ? 'basic-menu' : undefined}*/}
            {/*    aria-haspopup="true"*/}
            {/*    aria-expanded={open ? 'true' : undefined}*/}
            {/*    onClick={handleClick}*/}
            {/*>*/}
            {/*    Dashboard*/}
            {/*</Button>*/}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {Object.values(routerMenuItems).map((eachItem) => {
                    return <MenuItem key={eachItem} onClick={handleClose}>{eachItem}</MenuItem>
                })}
            </Menu>
        </div>
    );
}
