// WrappedComponents.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PostPortDataProvider from '../../data/providers/PostPortDataProvider';
import {RequireAuth} from '../auth/RequireAuth';
import PortPostsScreen from '../../screens/PortPostsScreen';
import { DataStore } from 'aws-amplify';
import { Port } from '../../models';
import PortDataProvider from "../../data/providers/PortDataProvider";
import FisheryDataProvider from "../../data/providers/FisheryDataProvider";
import GearTypeDataProvider from "../../data/providers/GearTypeDataProvider";
import DevStateProvider from "../../data/providers/DevStateProvider";

function WrappedPortPosts(props) {
    const { portName } = useParams();
    const [portId, setPortId] = useState(null);

    // useEffect( console.log('portName', portName); console.log('props.ampUser', props.ampUser);
    // useEffect(() => {
    //     console.log('portName', portName);
    //     console.log('props.ampUser', props.ampUser);
    // }, [portName, props.ampUser]);

    useEffect(() => {
        async function fetchPortId() {
            // const regions = await DataStore.query(Region, r => r.name('eq', regionName));
            const ports = await DataStore.query(Port, r => r.routerPath.eq(portName));
            if (ports.length > 0) {
                setPortId(ports[0].id);
            }
        }

        fetchPortId();
    }, [portName]);

    return (
        <DevStateProvider>
        <PortDataProvider>
            <FisheryDataProvider>
                <GearTypeDataProvider>
                    <PostPortDataProvider portId={portId}>
                        <RequireAuth>
                            <PortPostsScreen routerPaths={props.routerPaths} ampUser={props.ampUser} />
                        </RequireAuth>
                    </PostPortDataProvider>
                </GearTypeDataProvider>
            </FisheryDataProvider>
        </PortDataProvider>
        </DevStateProvider>
    );
}

export default WrappedPortPosts;
