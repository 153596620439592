import Typography from '@mui/material/Typography';

const StyledPostSectionContentTypography = ({ text, ...props }) => {
    return (
        <Typography
            gutterBottom
            sx={{
                // Add default styles specific to the Typography component
                // For example, setting font size, weight, color, etc.
                fontSize: props.fontSize || '1.3rem', // Example font size
                // fontSize: ['1rem', '1rem', '1rem', '1rem'],
                fontWeight: 'normal', // Example font weight
                // color: 'text.primary', // Example color
                // marginBottom: 1, // Example margin at the bottom
                // margin: props.margin || 0,
                margin: props.margin || ['0, 0, 0.25rem, 0'],
                // Add more default styles if necessary

                // Overwrite with props.sx to allow for custom styles
                ...props.sx,
            }}
            {...props} // Spread the rest of the props to the Typography component
        >
            {text}
        </Typography>
    );
}

export default StyledPostSectionContentTypography;