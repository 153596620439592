import React, {useContext, useEffect} from 'react';
import styles from '../../styles/MobileFooter.css';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import StyledFooterButton from "../buttons/StyledFooterButton";
import DevStateContext from "../../data/contexts/DevStateContext";

export default function MobileFooter(props) {

    const [termsUrl, setTermsUrl] = React.useState('');
    const [contactUrl, setContactUrl] = React.useState('');
    const [aboutUrl, setAboutUrl] = React.useState('');
    const [worksUrl, setWorksUrl] = React.useState('');
    const { devStateObj } = useContext(DevStateContext);

    useEffect(() => {
        if (devStateObj) {
            setTermsUrl(devStateObj.termsUrl);
            setContactUrl(devStateObj.contactUrl);
            setAboutUrl(devStateObj.aboutUrl);
            setWorksUrl(devStateObj.worksUrl);
        }
    }, [devStateObj]);

    // TODO - start here, make sure the linked pages have correct content, work
    return (
        <AppBar
            position="static"
        >
            <Toolbar sx={{
                backgroundColor: '#486B6C',
                color: 'white',
            }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}
                      direction={{ xs: 'column', sm: 'row' }}>
                    <Grid item xs={12} sm="auto">
                        {termsUrl ? <StyledFooterButton
                            onClick={() => window.open(worksUrl, '_blank')}
                        >How It Works
                        </StyledFooterButton> : null}
                    </Grid>
                    <Grid item xs={12} sm="auto">
                        {aboutUrl ? <StyledFooterButton
                            onClick={() => window.open(aboutUrl, '_blank')}
                        >About Us
                        </StyledFooterButton> : null}
                    </Grid>
                    <Grid item xs={12} sm="auto">
                        {contactUrl ? <StyledFooterButton
                            onClick={() => window.open(contactUrl, '_blank')}
                        >Contact Us
                        </StyledFooterButton> : null}
                    </Grid>
                    <Grid item xs={12} sm="auto">
                        {termsUrl ? <StyledFooterButton
                            onClick={() => window.open(termsUrl, '_blank')}
                        >Terms of Service
                        </StyledFooterButton> : null}
                    </Grid>
                    <Grid item xs={12} sm="auto" className={styles.ngigsLogo}>
                        <StyledFooterButton>
                            By{' '}
                            <img
                                src="/favicon.ico"
                                alt="ngigs logo"
                                width={40}
                                height={40}
                            />
                            ngigs
                        </StyledFooterButton>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm="auto"
                        sx={{
                            marginBottom: '0.5rem',
                            fontSize: ['6vw', '4vw', '2.5vw', '2vw'],
                    }}>
                        <div>
                            &copy; {new Date().getFullYear()} ngigs LLC. All rights reserved.
                        </div>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}
