import React from 'react';
import ListItemText from '@mui/material/ListItemText';

function toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

const StyledListItemText = ({ text, propsFontSize, ...props }) => {
    const titleCasedText = toTitleCase(text);
    // console.log('StyledListItemText text', text);
    // console.log('StyledListItemText props', props);
    // console.log('StyledListItemText propsFontSize', propsFontSize);

    const sxStyles = {
        fontSize: propsFontSize || ['2.5vw', '2vw', '1.5vw', '1vw'],
        padding: props.padding || '1rem',
        color: 'black',
        textTransform: 'none',
        // Add any default styles here
    };

    return (
        <ListItemText
            // sx={{
            //     // fontSize: props.fontSize || ['2.5vw', '2vw', '1.5vw', '1vw'],
            //     fontSize: propsFontSize || ['2.5vw', '2vw', '1.5vw', '1vw'],
            //     padding: props.padding || '1rem',
            //     color: 'black',
            //     textTransform: 'none',
            //     // Add any default styles here
            //     // ...props.style
            // }}
            sx={sxStyles}
            {...props}
        >
            {titleCasedText}
        </ListItemText>
    );
};

export default StyledListItemText;
