import React, { useContext } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import RegionCard from "../cards/RegionCard";
import RegionContext from "../../data/contexts/RegionContext";
import StyledListBox from "../boxes/StyledListBox";
import {useResponsiveViewport} from "../../data/providers/ResponsiveViewportProvider";

function regionCards(regionRecsArr) {
    return(
        regionRecsArr.map((regionRec) => {
                const regionCountryRec = regionRec.Country;
                return(
                    <ListItem
                        key={regionRec.id}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <RegionCard
                            region={regionRec}
                            country={regionCountryRec}
                        />
                    </ListItem>
                )
            }
        )
    )
}

export default function RegionCardList() {
    const { regionRecs } = useContext(RegionContext);
    const { isSmallScreen, isLargeScreen } = useResponsiveViewport();

    return (
        <div>
            <StyledListBox
                width={isLargeScreen ? '90%' : '100%'}
                style={{
                    color: 'white',
                }}
            >
            <h2>Regions</h2>
            <nav aria-label="region cards list">
                <List>
                    {regionCards(regionRecs)}
                </List>
            </nav>
            </StyledListBox>
        </div>
    );
}
