import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { SortDirection } from "aws-amplify";
import { Post } from '../../models';
import UserPostsContext from "../contexts/UserPostsContext";

const UserPostsProvider = ({ children, ampUserId }) => {
    const [userPostsRecs, setUserPostsRecs] = useState([]);
    const [userPostsShowLogs, setUserPostsShowLogs] = useState(false);

    // function setUserPostsShowLogs(showLogs) {
    //     setUserPostsShowLogs(showLogs);
    // }

    // useEffect(() => {
    //     console.log('UserPostsProvider userPostsShowLogs', userPostsShowLogs);
    // }, [userPostsShowLogs]);

    // useEffect(() => {
    //     if (userPostsShowLogs) {
    //         console.log('UserPostsProvider userPostsRecs', userPostsRecs);
    //     }
    // }, [userPostsRecs, userPostsShowLogs]);

    useEffect(() => {
        // console.log('UserPostsProvider ampUserId', ampUserId);
        if (ampUserId) {
            const subscription = DataStore.observeQuery(Post,
                p => p.ampuserID.eq(ampUserId),
                {
                    sort: s => s.createdAt(SortDirection.ASCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    // console.log(`User Posts p [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                    // console.log('User Posts p [Snapshot] items', items);
                    if (isSynced) {
                        // Filter the items based on the related model's postStatus
                        const filteredItems = await Promise.all(items.filter(async item => {
                            const post = await DataStore.query(Post, item.postID);
                            return post.postStatus === "LIVE"; // TODO - remove this and show all posts instead?  what about archived posts? <- later version
                        }));
                        // Update the posts data
                        setUserPostsRecs(filteredItems);
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
    }, [ampUserId]); // Dependency array now includes regionId

    return (
        <UserPostsContext.Provider value={{ userPostRecs: userPostsRecs, setUserPostsShowLogs }}>
            {children}
        </UserPostsContext.Provider>
    );
};

export default UserPostsProvider;
