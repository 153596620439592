import {useEffect, useState, useContext} from "react";
import CreateUserSubForm from "../components/forms/CreateUserSubForm";
import {UserStatus, SubscriptionType} from "../models";
import "../styles/CreateSub.css";
import {useLocation} from "react-router-dom";
import AmpUserContext from "../data/contexts/AmpUserContext";

export default function CreateUserSubScreen(props) {
    const [subscriptionTypeEnum, setSubscriptionTypeEnum] = useState({});
    const [userStatusEnum, setUserStatusEnum] = useState({});
    const [ampUser, setAmpUser] = useState(null);
    const [locationState, setLocationState] = useState(null);

    const {ampUserRec} = useContext(AmpUserContext);
    // let navigate = useNavigate();
    let location = useLocation();

    // useEffect setAmpUser(props.ampUser)
    useEffect(() => {
        if (ampUserRec) {
            setAmpUser(ampUserRec);
        }
    }, [ampUser]);

    useEffect(() => {
        if (location.state) {
            setLocationState(location.state);
        }
    }, [location.state]);

    useEffect( () => {
        console.log('CreateUserSubScreen locationState', locationState);
    }, [locationState]);

    // useEffect setSubscriptionTypeEnum(PostStatus);
    useEffect(() => {
        if (SubscriptionType) {
            setSubscriptionTypeEnum(SubscriptionType);
        }
    }, []);

    // useEffect( console.log('subscriptionTypeEnum', subscriptionTypeEnum);
    // useEffect(() => {
    //     console.log('subscriptionTypeEnum', subscriptionTypeEnum);
    // }, [subscriptionTypeEnum]);

    // useEffect setUserStatusEnum(UserStatus);
    useEffect(() => {
        if (UserStatus) {
            setUserStatusEnum(UserStatus);
        }
    }, []);

    // useEffect( console.log('userStatusEnum', userStatusEnum);
    // useEffect(() => {
    //     console.log('userStatusEnum', userStatusEnum);
    // }, [userStatusEnum]);

    return (
        <div
            style={{
                height: '85vh',
            }}
        >
            <h2 className="createSubBody">Create Subscription</h2>
            <CreateUserSubForm
                ampUser={ampUser}
                userStatus={userStatusEnum}
                locationState={locationState}
            />
        </div>
    )
}