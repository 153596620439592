import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import PostCard from "../cards/PostCard";
import {modelIdNameFactory, lookingDoneNamesFactory, userShareAttrsFactory} from "../../consts";


export default function UserPostsCardList(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [userShareAttrs, setUserShareAttrs] = useState(null);
    const [ampUserId, setAmpUserId] = useState(null);

    const [regions, setRegions] = useState([]);
    const [regionIdNames, setRegionIdNames] = useState(null);

    const [fisheries, setFisheries] = useState([]);
    const [fisheryIdNames, setFisheryIdNames] = useState(null);

    const [ports, setPorts] = useState([]);
    const [portIdNames, setPortIdNames] = useState(null);

    const [gearTypes, setGearTypes] = useState([]);
    const [gearTypeIdNames, setGearTypeIdNames] = useState(null);

    const [userPosts, setUserPosts] = useState([]);
    const [showDelete, setShowDelete] = useState(false);

    // useEffect(() => { setAmpUser(props.ampUser); }, [props.ampUser]);
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
            setAmpUserId(props.ampUser.id);
        }
    }, [props.ampUser]);

    // useEffect( setUserShareAttrs(userShareAttrs);
    useEffect(() => {
        if (ampUser) {
            // setAmpUser(props.ampUser);
            const userShareAttrs = userShareAttrsFactory(ampUser);
            setUserShareAttrs(userShareAttrs);
        }
    }, [ampUser]);

    // useEffect(() => { setUserPosts(props.userPosts); }, [props.userPosts]);
    useEffect(() => {
        if (props?.userPosts) {
            setUserPosts(props.userPosts);
        }
    }, [props.userPosts]);

    // useEffect(() => { setShowDelete(props.showDelete); }, [props.showDelete]);
    useEffect(() => {
        if (props?.showDelete) {
            setShowDelete(props.showDelete);
        }
    }, [props.showDelete]);

    // useEffect(() => {
    //     console.log('showDelete', showDelete);
    // }, [showDelete]);

    // useEffect(() => setRegions(props.regions); setRegionIdNames(modelIdNameFactory(props.regions));
    useEffect(() => {
        if (props?.regions) {
            setRegions(props.regions);
            setRegionIdNames(modelIdNameFactory(props.regions));
        }
    }, [props.regions]);

    // useEffect(() => setPorts(props.ports); setPortIdNames(modelIdNameFactory(props.ports));
    useEffect(() => {
        if (props?.ports) {
            setPorts(props.ports);
            setPortIdNames(modelIdNameFactory(props.ports));
        }
    }, [props.ports]);

    // useEffect(() => setGearTypes(props.gearTypes); setGearTypeIdNames(modelIdNameFactory(props.gearTypes));
    useEffect(() => {
        if (props?.gearTypes) {
            setGearTypes(props.gearTypes);
            setGearTypeIdNames(modelIdNameFactory(props.gearTypes));
        }
    }, [props.gearTypes]);

    // useEffect( setFisheries(props.fisheries); setFisheryIdNames(modelIdNameFactory(props.fisheries));
    useEffect(() => {
        if (props?.fisheries) {
            setFisheries(props.fisheries);
            setFisheryIdNames(modelIdNameFactory(props.fisheries));
        }
    }, [props.fisheries]);


    function userPostCards(userPostRecsArr) {
        return(
            userPostRecsArr.map((postRec) => {
                // console.log('postRec', postRec);
                // TODO - ADD NAMES BY LOOKING OR DONE,
                //  TRY TO FIND A WAY TO NOT HAVE TO BRING IN ALL MODEL RECS OF PORTS, REGIONS, ETC
                    return(
                        // <ListItem key={postRec.id}>
                        <div
                            key={postRec.id}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                // justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                textAlign: 'left',
                            }}
                        >
                            <PostCard
                                showLogs={props?.showLogs || false}
                                ampUserId={ampUserId}
                                userShareAttrs={userShareAttrs}
                                showDelete={showDelete}
                                post={postRec}
                                doneFisheryNames={lookingDoneNamesFactory(postRec.doneFisheryIDs, fisheryIdNames)}
                                lookingFisheryNames={lookingDoneNamesFactory(postRec.lookingFisheryIDs, fisheryIdNames)}
                                doneGearTypeNames={lookingDoneNamesFactory(postRec.doneGearTypeIDs, gearTypeIdNames)}
                                lookingGearTypeNames={lookingDoneNamesFactory(postRec.lookingGearTypeIDs, gearTypeIdNames)}
                                donePortNames={lookingDoneNamesFactory(postRec.donePortIDs, portIdNames)}
                                lookingPortNames={lookingDoneNamesFactory(postRec.lookingPortIDs, portIdNames)}
                                // networkConnected={networkConnected}
                                doneRegionNames={lookingDoneNamesFactory(postRec.doneRegionIDs, regionIdNames)}
                                lookingRegionNames={lookingDoneNamesFactory(postRec.lookingRegionIDs, regionIdNames)}
                                // postRegionRecs={props.postRegionRecs}
                            />
                        </div>
                    )
                }
            )
        )
    }

    return (
        <Box sx={{
            width: '100%',
            minWidth: 420,
            color: 'black'
        }}>
            <h3>Your Posts</h3>
            <nav aria-label="user posts cards list">
                <List>
                    {userPostCards(props.userPosts)}
                </List>
            </nav>
        </Box>
    );
}
