import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import PostCard from "../cards/PostCard";
import {lookingDoneNamesFactory, userShareAttrsFactory} from "../../consts";

export default function FisheryPostsCardList(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [userShareAttrs, setUserShareAttrs] = useState(null);
    const [ampUserId, setAmpUserId] = useState(null);

    const [fisheryName, setFisheryName] = useState('');
    const [fisheryPostRecs, setFisheryPostRecs] = useState([]);

    /**
     TODO - ADD DEFAULT LOADING ELEMENT HERE, AS DEFAULT ARR FOR REGIONPOSTS
     */

    // useEffect(() => { setAmpUser(props.ampUser); }, [props.ampUser]);
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
            setAmpUserId(props.ampUser.id);
        }
    }, [props.ampUser]);

    // useEffect( setUserShareAttrs(userShareAttrs);
    useEffect(() => {
        if (ampUser) {
            const userShareAttrs = userShareAttrsFactory(ampUser);
            setUserShareAttrs(userShareAttrs);
        }
    }, [ampUser]);

    // useEffect(() => { setFisheryPostRecs(props.thisFisheryPosts);
    useEffect(() => {
        if (props?.thisFisheryPosts) {
            setFisheryPostRecs(props.thisFisheryPosts);
        }
    }, [props]);

    // useEffect(() => { setRegionName(props.regionName);
    useEffect(() => {
        if (props.fisheryName) {
            setFisheryName(props.fisheryName);
        }
    }, [props])


    function fisheryPostCards(fisheryPostRecs) {
        return(
            fisheryPostRecs.map((postRec) => {
                    return(
                        // <ListItem key={postRec.id}>
                        <div
                            key={postRec.id}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                // justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                textAlign: 'left',
                            }}
                        >
                            <PostCard
                                showLogs={props?.showLogs || false}
                                ampUserId={ampUserId}
                                userShareAttrs={userShareAttrs}
                                post={postRec}
                                doneFisheryNames={lookingDoneNamesFactory(postRec.doneFisheryIDs, props.fisheryIdNames)}
                                lookingFisheryNames={lookingDoneNamesFactory(postRec.lookingFisheryIDs, props.fisheryIdNames)}
                                doneRegionNames={lookingDoneNamesFactory(postRec.doneRegionIDs, props.regionIdNames)}
                                lookingRegionNames={lookingDoneNamesFactory(postRec.lookingRegionIDs, props.regionIdNames)}
                                doneGearTypeNames={lookingDoneNamesFactory(postRec.doneGearTypeIDs, props.gearTypeIdNames)}
                                lookingGearTypeNames={lookingDoneNamesFactory(postRec.lookingGearTypeIDs, props.gearTypeIdNames)}
                                donePortNames={lookingDoneNamesFactory(postRec.donePortIDs, props.portIdNames)}
                                lookingPortNames={lookingDoneNamesFactory(postRec.lookingPortIDs, props.portIdNames)}
                            />
                        </div>
                        // </ListItem>
                    )
                }
            )
        )
    }


    return (
        <Box sx={{
            width: '100%',
            minWidth: 420,
            // bgcolor: '#282c34'
            color: 'black'
        }}>
            <h2>{fisheryName} Posts</h2>
            {/*<NetworkConnPostsFetchModal openModal={networkConnected} />*/}
            <nav aria-label="region posts cards list">
                <List>
                    {fisheryPostCards(fisheryPostRecs)}
                </List>
            </nav>
        </Box>
    );
}
