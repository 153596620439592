import React, { useState, useEffect } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from "react-router-dom";
import Typography from '@mui/material/Typography';


function handleClick(event) {
    event.preventDefault();
    // console.info('You clicked a breadcrumb.');
}

// function breadcrumbRouterLinks(breadcrumbsArr) {
//     return breadcrumbsArr.map((eachLocationArr) => {
//         return(<Link
//             key={eachLocationArr.name}
//             underline="hover"
//             color="inherit"
//             to={eachLocationArr.breadcrumbRoute}
//             component={Typography}
//             variant="body2"
//             sx={{
//                 fontSize: {
//                     xs: '1rem', // for small screens
//                     sm: '1.5rem', // for medium screens
//                     md: '2rem', // for large screens
//                 },
//             }}
//         >
//             {eachLocationArr.name}
//         </Link>)
//     })
// }

function breadcrumbRouterLinks(breadcrumbsArr) {
    return breadcrumbsArr.map((eachLocationArr) => {
        return (
            <Typography
                key={eachLocationArr.name}
                variant="body2"
                sx={{
                    fontSize: {
                        xs: '1.25rem', // for small screens
                        sm: '1.5rem', // for medium screens
                        md: '1.5rem', // for large screens
                    },
                }}
            >
                <Link
                    underline="hover"
                    color="inherit"
                    to={eachLocationArr.breadcrumbRoute}
                >
                    {eachLocationArr.name}
                </Link>
            </Typography>
        );
    })
}


export default function RouterBreadcrumbs(props) {
    const [breadcrumbs, setBreadcrumbs] = useState([])

    useEffect(() => {
        setBreadcrumbs(props.breadcrumbsArr)
        // console.warn('breadcrumbs', breadcrumbs)
    }, [props])

    
    return (
        <div style={{'backgroundColor': 'lightgray'}} role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
                {breadcrumbRouterLinks(breadcrumbs)}
            </Breadcrumbs>
        </div>
    );
}
