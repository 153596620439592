import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { SortDirection } from "aws-amplify";
import {Post, PostPort} from '../../models';
import PostPortContext from "../contexts/PostPortContext";

const PostPortDataProvider = ({ children, portId }) => {
    const [postPortRecs, setPostPortRecs] = useState([]);

    useEffect(() => {
        // console.log('PostPortDataProvider portId', portId);
        if (portId) {
            const subscription = DataStore.observeQuery(PostPort,
                pp => pp.and(pp => [
                    pp.portId.eq(portId)
                ]),
                {
                    sort: s => s.createdAt(SortDirection.ASCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    // console.log(`PostPort pr [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                    // console.log('PostPort pr [Snapshot] items', items);
                    if (isSynced) {
                        // Filter the items based on the related model's postStatus
                        const filteredItems = await Promise.all(items.filter(async item => {
                            const post = await DataStore.query(Post, item.postID);
                            return post.postStatus === "LIVE";
                        }));
                        // Update the posts data
                        setPostPortRecs(filteredItems);
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
    }, [portId]); // Dependency array now includes portId

    return (
        <PostPortContext.Provider value={{ postPortRecs: postPortRecs }}>
            {children}
        </PostPortContext.Provider>
    );
};

export default PostPortDataProvider;
