import React, { createContext, useContext } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

// Create the context
const ResponsiveViewportContext = createContext();

// Provider Component
const ResponsiveViewportProvider = ({ children }) => {
    const isSmallScreen = useMediaQuery('(max-width:600px)'); // Adjust as needed
    const isLargeScreen = useMediaQuery('(min-width:1200px)'); // Adjust as needed

    return (
        <ResponsiveViewportContext.Provider value={{ isSmallScreen, isLargeScreen }}>
            {children}
        </ResponsiveViewportContext.Provider>
    );
};

// Custom hook for easier consumption
const useResponsiveViewport = () => {
    const context = useContext(ResponsiveViewportContext);
    if (context === undefined) {
        throw new Error('useResponsiveViewport must be used within a ResponsiveViewportProvider');
    }
    return context;
};

export { ResponsiveViewportProvider, useResponsiveViewport };
