import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { SortDirection } from "aws-amplify";
import { PostRegion, Post } from '../../models';
import PostRegionContext from "../contexts/PostRegionContext";

const PostRegionDataProvider = ({ children, regionId }) => {
    const [postRegionRecs, setPostRegionRecs] = useState([]);

    useEffect(() => {
        // console.log('PostRegionDataProvider regionId', regionId);
        if (regionId) {
            const subscription = DataStore.observeQuery(PostRegion,
                pr => pr.and(pr => [
                    pr.regionId.eq(regionId),
                ]),
                {
                    sort: s => s.createdAt(SortDirection.ASCENDING)
                })
                .subscribe(async msg => {
                    const {items, isSynced} = msg;
                    // console.log(`PostRegion pr [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                    // console.log('PostRegion pr [Snapshot] items', items);
                    if (isSynced) {
                        // Filter the items based on the related model's postStatus
                        const filteredItems = await Promise.all(items.filter(async item => {
                            const post = await DataStore.query(Post, item.postID);
                            return post.postStatus === "LIVE";
                        }));
                        // Update the posts data
                        setPostRegionRecs(filteredItems);
                    }
                });
            // Remember to unsubscribe when the component unmounts
            return () => subscription.unsubscribe();
        }
    }, [regionId]); // Dependency array now includes regionId

    return (
        <PostRegionContext.Provider value={{ postRegionRecs: postRegionRecs }}>
            {children}
        </PostRegionContext.Provider>
    );
};

export default PostRegionDataProvider;
