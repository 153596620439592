import Typography from '@mui/material/Typography';

const StyledPostSectionHeaderTypography = ({ text, ...props }) => {
    return (
        <Typography
            // gutterBottom
            variant={'h6'}
            sx={{
                // Add default styles specific to the Typography component
                // For example, setting font size, weight, color, etc.
                fontSize: '1.25rem', // Example font size
                // fontSize: ['1.25rem', '1.25rem', '1.25rem', '1.25rem'],
                fontWeight: 'bold', // Example font weight
                // color: 'text.primary', // Example color
                // marginBottom: 1, // Example margin at the bottom
                // margin: props.margin || 0,
                margin: props.margin || ['0, 0, 0.25rem, 0'],
                // Add more default styles if necessary
                textAlign: 'center',
                // Overwrite with props.sx to allow for custom styles
                ...props.sx,
            }}
            {...props} // Spread the rest of the props to the Typography component
        >
            {text}
        </Typography>
    );
}

export default StyledPostSectionHeaderTypography;
