import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

export default function BaseNavigateButton({ label, navTarget, navState, ...props }) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(navTarget, {
            state: navState,
        });
    };

    return (
        <Button
            variant="contained"
            onClick={handleClick}
            sx={{
                textTransform: 'none',
                fontSize: ['5vw', '3vw', '2vw', '1.5vw'],
                margin: '1rem',
                backgroundColor: '#5F9EA0',
                // ...props.style
            }}
            {...props}
        >
            {label}
        </Button>
    );
}
