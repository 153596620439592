import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { GearType } from '../../models';
import GearTypeContext from "../contexts/GearTypeContext";

const GearTypeDataProvider = ({ children }) => {
    const [gearTypeRecs, setGearTypeRecs] = useState([]);

    useEffect(() => {
        const subscription = DataStore.observeQuery(GearType)
            .subscribe(msg => {
                const { items, isSynced } = msg;
                // console.log(`GearType [msg] item count: ${items.length}, isSynced: ${isSynced}`);
                // console.log('GearType [Snapshot] items', items);
                setGearTypeRecs(msg.items);
            });

        // Remember to unsubscribe when the component unmounts
        return () => subscription.unsubscribe();
    }, []);

    return (
        <GearTypeContext.Provider value={{ gearTypeRecs: gearTypeRecs }}>
            {children}
        </GearTypeContext.Provider>
    );
};

export default GearTypeDataProvider;
