import {useEffect, useState, useContext} from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { Typography } from "@mui/material";
import BaseNavigateButton from "../links/BaseNavigateButton";
import "../../styles/CreateSub.css";
import {userSubDataObj} from "../../consts";
import AmpUserContext from "../../data/contexts/AmpUserContext";


export default function CreateUserSubForm(props) {
    const [ampUser, setAmpUser] = useState({});
    const [subLength, setSubLength] = useState('Quarter');
    const [locationState, setLocationState] = useState(null);
    const [userSubData, setUserSubData] = useState(userSubDataObj);


    const {ampUserRec} = useContext(AmpUserContext);

    // let navigate = useNavigate();

    // useEffect setAmpUser(props.ampUser)
    useEffect(() => {
        if (ampUserRec) {
            setAmpUser(ampUserRec);
        }
    }, []);

    useEffect(() => {
        if (props.locationState) {
            setLocationState(props.locationState);
            if (props.locationState.subLength) {
                setSubLength(props.locationState.subLength);
            }
        }
    }, [props.locationState]);

    useEffect( () => {
        console.log('CreateUserSubForm locationState', locationState);
    }, [locationState]);

    // handleSelectChange setSubLength(event.target.value);
    const handleSelectChange = (event) => {
        setSubLength(event.target.value);
    };

    // useEffect( console.log('subLength', subLength);
    // useEffect(() => {
    //     console.log('subLength', subLength);
    // }, [subLength]);

    // TODO - START HERE, MAKE SURE A 1 MONTH SUB WORKS
    return (
        <div className="createSubFormBody">
            <Divider>
                <Typography
                    sx={{
                        fontSize: ['5vw', '4vw', '3vw', '2vw'],
                        paddingTop: '1rem'
                    }}
                >Subscription Length</Typography>
            </Divider>
            <Typography
                sx={{
                    fontSize: ['5vw', '4vw', '3vw', '2vw'],
                    margin: '1rem',
                    paddingTop: '1rem'
                }}
            >
                {userSubData ? `${userSubData.Year.subLengthMonths} months, beginning today - $${userSubData.Year.subPrice}` : '12 months, beginning today'}
            </Typography>
            <Typography
                sx={{
                    fontSize: ['5vw', '4vw', '3vw', '2vw'],
                    margin: '1rem',
                    paddingBottom: '1rem'
                }}
            >
                {userSubData ? `${userSubData.Quarter.subLengthMonths} months, beginning today - $${userSubData.Quarter.subPrice}` : '3 months, beginning today'}
            </Typography>
            <Typography
                sx={{
                    fontSize: ['5vw', '4vw', '3vw', '2vw'],
                    margin: '1rem',
                    paddingBottom: '1rem'
                }}
            >
                {userSubData ? `${userSubData.Month.subLengthMonths} months, beginning today - $${userSubData.Month.subPrice}` : '1 month, beginning today'}
            </Typography>
            <div>
                <FormControl
                    row="true"
                    component="fieldset"
                    sx={{
                        fontSize: {
                            xs: '1.2rem',   // For extra-small screens
                            sm: '1.5rem', // For small screens
                            md: '1.7rem', // For medium screens
                            lg: '1.8rem', // For large screens
                            xl: '2rem', // For extra-large screens
                        },
                        width: {
                            xs: 200, // For extra-small screens
                            sm: 220, // For small screens
                            md: 280, // For medium screens
                            lg: 360, // For large screens
                            xl: 400, // For extra-large screens
                        },
                    }}
                >
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                            fontSize: {
                                xs: '1.2rem',   // 0px+
                                sm: '1.4rem', // 600px+
                                md: '1.5rem', // 900+
                                lg: '1.6rem', // 1200+
                                xl: '1.8rem', // 1536+
                            }
                        }}
                    >
                        Subscription Length
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={subLength}
                        label="Subscription Length"
                        onChange={handleSelectChange}
                        sx={{
                            fontSize: {
                                xs: '1.2rem',   // 0px+
                                sm: '1.4rem', // 600px+
                                md: '1.5rem', // 900+
                                lg: '1.6rem', // 1200+
                                xl: '1.8rem', // 1536+
                            }
                        }}
                    >
                        <MenuItem
                            value={userSubData.Month.subText}
                            // sx={{
                            //     fontSize: {
                            //         xs: '1.2rem',   // 0px+
                            //         sm: '1.4rem', // 600px+
                            //         md: '2rem', // 900+
                            //         lg: '1.6rem', // 1200+
                            //         xl: '1.8rem', // 1536+
                            //     }
                            // }}
                        >
                            {`${userSubData.Month.descriptionText} - $${userSubData.Month.subPrice}`}
                        </MenuItem>
                        <MenuItem
                            value={userSubData.Quarter.subText}
                            // sx={{
                            //     fontSize: {
                            //         xs: '1.2rem',   // 0px+
                            //         sm: '1.4rem', // 600px+
                            //         md: '1.5rem', // 900+
                            //         lg: '1.6rem', // 1200+
                            //         xl: '1.8rem', // 1536+
                            //     }
                            // }}
                        >
                            {`${userSubData.Quarter.descriptionText} - $${userSubData.Quarter.subPrice}`}
                        </MenuItem>
                        <MenuItem
                            value={userSubData.Year.subText}
                            // sx={{
                            //     fontSize: {
                            //         xs: '1.2rem',   // 0px+
                            //         sm: '1.4rem', // 600px+
                            //         md: '1.5rem', // 900+
                            //         lg: '1.6rem', // 1200+
                            //         xl: '1.8rem', // 1536+
                            //     }
                            // }}
                        >
                            {`${userSubData.Year.descriptionText} - $${userSubData.Year.subPrice}`}
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
            <Divider/>
            <BaseNavigateButton
                label="Preview Subscription"
                navState={{
                    ampUser,
                    subLength
                }}
                navTarget={'/subscription-preview'}
            >
            </BaseNavigateButton>
            <Divider/>
        </div>
    )
}