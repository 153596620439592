import React, { useState, useEffect } from 'react';
import DevStateContext from "../contexts/DevStateContext";

// TODO - START HERE, TRY TO FIND OTHER PLACES TO USE THIS PROVIDER INSTEAD
const DevStateProvider = ({ children }) => {
    const [devStateObj, setDevStateObj] = useState({});
    const [devStateNow, setDevStateNow] = useState("prod");

    useEffect(() => {
        // console.log(`devStateNow: ${devStateNow}`);
        // console.log(`devState[devStateNow]: ${devState[devStateNow]}`);
        setDevStateObj(devState[devStateNow]);
    }, [devStateNow]);

    // useEffect(() => {
    //     console.log('DevStateProvider devStateObj', devStateObj);
    // }, [devStateObj]);

    const devState = {
        dev: {
            stateNow: "dev",
            showLogs: true,
            stripeSuccessURL: 'http://localhost:3000/subscription-success',
            stripeCancelURL: 'http://localhost:3000/subscription-cancel',
            staticSiteUrl: 'http://localhost:3000',
            blogUrl: 'https://www.fishngigs.com/blog',
            termsUrl: 'https://www.fishngigs.com/terms',
            contactUrl: 'https://www.fishngigs.com/contact',
            aboutUrl: 'https://www.fishngigs.com/about',
            worksUrl: 'https://www.fishngigs.com/works',
        },
        prod: {
            stateNow: "prod",
            showLogs: false,
            // stripeSuccessURL: 'https://www.fishingigs.com/subscription-success',
            stripeSuccessURL: 'https://www.fishingigs.com/',
            // stripeCancelURL: 'https://www.fishingigs.com/subscription-cancel',
            stripeCancelURL: 'https://www.fishingigs.com/',
            staticSiteUrl: 'https://www.fishngigs.com',
            blogUrl: 'https://www.fishngigs.com/blog',
            termsUrl: 'https://www.fishngigs.com/terms',
            contactUrl: 'https://www.fishngigs.com/contact',
            aboutUrl: 'https://www.fishngigs.com/about',
            worksUrl: 'https://www.fishngigs.com/works',
        },
    };

    return (
        <DevStateContext.Provider value={{ devStateObj }}>
            {children}
        </DevStateContext.Provider>
    );
};

export default DevStateProvider;
