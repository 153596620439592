import React, {useState, useEffect} from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';
import {useNavigate, useLocation} from "react-router-dom";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import {devStateNow} from "../consts";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function SinglePostScreen(props) {
    const [postRec, setPostRec] = useState(null);
    const [ampUser, setAmpUser] = useState({});
    const [startDateTrunc, setStartDateTrunc] = useState('');
    const [endDateTrunc, setEndDateTrunc] = useState('');
    const [regions, setRegions] = useState([]);
    const [fisheries, setFisheries] = useState([]);
    const [gearTypes, setGearTypes] = useState([]);
    const [lookingRegionNames, setLookingRegionNames] = useState([]);
    const [doneRegionNames, setDoneRegionNames] = useState([]);
    const [lookingFisheryNames, setLookingFisheryNames] = useState([]);
    const [doneFisheryNames, setDoneFisheryNames] = useState([]);
    const [lookingGearTypeNames, setLookingGearTypesNames] = useState([]);
    const [doneGearTypeNames, setDoneGearTypeNames] = useState([]);
    const [lookingFor, setLookingFor] = useState('');
    const [experiencedIn, setExperiencedIn] = useState('');
    const [showLogs, setShowLogs] = useState(false);
    const [isContactInfoObscured, setIsContactInfoObscured] = useState(true);

    let navigate = useNavigate();
    let location = useLocation();

    // useEffect( setShowLogs(
    useEffect(() => {
        if (devStateNow.stateNow === "prod"){
            setShowLogs(false);
        } else {
            setShowLogs(true);
        }
    }, [devStateNow]);

    // useEffect( setLookingFor(postRec.postBody.lookingTextFieldValue);
    useEffect(() => {
        if (postRec?.postBody) {
            if (postRec.postBody?.lookingTextFieldValue) {
                setLookingFor(postRec.postBody.lookingTextFieldValue);
            }
        }
    }, [postRec]);

    // useEffect( setExperiencedIn(postRec.postBody.doneTextFieldValue);
    useEffect(() => {
        if (postRec?.postBody) {
            if (postRec.postBody?.doneTextFieldValue) {
                setExperiencedIn(postRec.postBody.doneTextFieldValue);
            }
        }
    }, [postRec]);

    // useEffect( setPostRec(props.postRec || location.state.postRec); setStartDate(startDateModel); setEndDate(endDateModel);
    useEffect(() => {
        if (props.postRec) {
            setPostRec(props.postRec);
            const startDateModel = new Date(props.postRec.begin * 1000);
            setStartDateTrunc(startDateModel.toDateString());
            const endDateModel = new Date(props.postRec.end * 1000);
            setEndDateTrunc(endDateModel.toDateString());
        }
        // else if (location.state.postRec) {
        //     setPostRec(location.state.postRec);
        //     const startDateModel = new Date(location.state.postRec.begin * 1000);
        //     setStartDateTrunc(startDateModel.toDateString());
        //     const endDateModel = new Date(location.state.postRec.end * 1000);
        //     setEndDateTrunc(endDateModel.toDateString());
        // }
    }, [props, location, postRec]);

    // useEffect( console.log('SinglePostScreen() location', location);
    // useEffect(() => {
    //     console.log('SinglePostScreen() location', location);
    // }, [location]);

    // useEffect( console.log('startDate', startDate); console.log('endDate', endDate);
    useEffect(() => {
        if (showLogs) {
            console.log('SinglePostScreen() postRec', postRec);
        }
        // console.log('SinglePostScreen() startDateTrunc', startDateTrunc);
        // console.log('SinglePostScreen() endDateTrunc', endDateTrunc);
    }, [postRec, startDateTrunc, endDateTrunc, showLogs]);

    // useEffect( console.log('SinglePostScreen() postRec', postRec);
    // useEffect(() => {
    //     console.log('SinglePostScreen() postRec', postRec);
    // }, [postRec]);

    // useEffect( setAmpUser(props.ampUser[0]) || setAmpUser(location.state.ampUser);
    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
        }
        // else if (location.state.ampUser) {
        //     setAmpUser(location.state.ampUser);
        // }
    }, [props, location]);

    // useEffect( console.log('SinglePostScreen() ampUser', ampUser);
    // useEffect(() => {
    //     console.log('SinglePostScreen() ampUser', ampUser);
    // }, [ampUser]);

    // useEffect( setRegions(props.regions);
    useEffect(() => {
        if (props?.regions) {
            setRegions(props.regions);
        }
    }, [props, regions]);

    // useEffect( console.log('SinglePostScreen() regions', regions);
    // useEffect(() => {
    //     console.log('SinglePostScreen() regions', regions);
    // }, [regions]);

    // useEffect( setFisheries(props.fisheries);
    useEffect(() => {
        if (props?.fisheries) {
            setFisheries(props.fisheries);
        }
    }, [props, fisheries]);

    // useEffect( console.log('SinglePostScreen() fisheries', fisheries);
    // useEffect(() => {
    //     console.log('SinglePostScreen() fisheries', fisheries);
    // }, [fisheries]);

    // useEffect( setGearTypes(props.gearTypes);
    useEffect(() => {
        if (props?.gearTypes) {
            setGearTypes(props.gearTypes);
        }
    }, [props, gearTypes]);

    // useEffect( console.log('SinglePostScreen() gearTypes', gearTypes);
    // useEffect(() => {
    //     console.log('SinglePostScreen() gearTypes', gearTypes);
    // }, [gearTypes]);

    // postRecLookingAttrsList ()
    function postRecLookingAttrsList () {
        return (
            <div>
                {postRecAttrsLookingItems()}
            </div>
        )
    }

    // function ampUserAttrsList ()
    function ampUserAttrsList () {
        return (
            <div>
                <Typography>
                    User:
                </Typography>
                {ampUserAttrsItems()}
            </div>
        )
    }

    // function ampUserAttrsItems ()
    function ampUserAttrsItems () {
        return <Item key={`${Date.now() * Math.random()}`}>
            <Typography>
                name: {ampUser ? ampUser.name : 'user name'}
            </Typography>
            <Typography>
                phone: {ampUser ? ampUser.phone : 'user phone'}
            </Typography>
        </Item>
    }

    // function postAttrsList ()
    function postAttrsList () {
        return (
            <div>
                <Typography>
                    Post:
                </Typography>
                {postAttrsItems()}
            </div>
        )
    }

    // postAttrsItems ()
    function postAttrsItems () {
        return <Item key={`${Date.now() * Math.random()}`}>
            <Typography>
                Title: {postRec ? postRec.title : 'post title'}
            </Typography>
            <Typography>
                Post Type: {postRec ? postRec.postType : 'postRec.postType'}
            </Typography>
            <Typography>
                Status: {postRec ? postRec.status : 'post status'}
            </Typography>
            <Typography>
                Expire Date: {endDateTrunc ? endDateTrunc : 'endDate Truncated'}
            </Typography>
        </Item>
    }

    // postRecAttrsLookingItems ()
    function postRecAttrsLookingItems () {
        return (
            <div>
                <Typography>
                    Looking for:
                </Typography>
            <Item key={`${Date.now() * Math.random()}`}>
                <Typography>
                    Looking Regions: {lookingRegionNames.join(' ')}
                </Typography>
                <Typography>
                    Looking Fisheries: {lookingFisheryNames.join(' ')}
                </Typography>
                <Typography>
                    Looking Gear Types: {lookingGearTypeNames.join(' ')}
                </Typography>
                {lookingFor ? <Typography>
                    Looking For: {lookingFor}
                </Typography> : null}
            </Item>
            </div>
        )
    }

    // postRecAttrsDoneItems ()
    function postRecAttrsDoneItems () {
        return (
            <div>
                <Typography>
                     Experience:
                </Typography>
                <Item key={`${Date.now() * Math.random()}`}>
                    <Typography>
                        Done Regions: {doneRegionNames.join(' ')}
                    </Typography>
                    <Typography>
                        Done Fisheries: {doneFisheryNames.join(' ')}
                    </Typography>
                    <Typography>
                        Done Gear Types: {doneGearTypeNames.join(' ')}
                    </Typography>
                    {experiencedIn ? <Typography>
                        Experienced In: {experiencedIn}
                    </Typography> : null}
                </Item>
            </div>
        )
    }

    // useEffect( sets model names based on postRec attrs
    useEffect(() => {
        if (postRec) {
            if (regions) {
                if (postRec.lookingRegionIDs) {
                    populateLookingRegionNames();
                }
                if (postRec.doneRegionIDs) {
                    populateDoneRegionNames();
                }
            }
            if (fisheries) {
                if (postRec.lookingFisheryIDs) {
                    populateLookingFisheryNames();
                }
                if (postRec.doneFisheryIDs) {
                    populateDoneFisheryNames();
                }
            }
            if (gearTypes) {
                if (postRec.lookingGearTypeIDs) {
                    populateLookingGearTypeNames();
                }
                if (postRec.doneGearTypeIDs) {
                    populateDoneGearTypeNames();
                }
            }
        }
    }, [regions, postRec, fisheries, gearTypes]);

    function populateLookingFisheryNames() {
        if (postRec.lookingFisheryIDs) {
            const lookingFisheryNames = filterModelNamesByIDs(fisheries, postRec.lookingFisheryIDs);
            // console.log('lookingFisheryNames', lookingFisheryNames);
            setLookingFisheryNames(lookingFisheryNames);
        }
    }

    function populateDoneFisheryNames() {
        if (postRec.doneFisheryIDs) {
            const doneFisheryNames = filterModelNamesByIDs(fisheries, postRec.doneFisheryIDs);
            // console.log('doneFisheryNames', doneFisheryNames);
            setDoneFisheryNames(doneFisheryNames);
        }
    }

    function populateLookingRegionNames() {
        if (postRec.lookingRegionIDs) {
            const lookingRegionNames = filterModelNamesByIDs(regions, postRec.lookingRegionIDs);
            // console.log('lookingRegionNames', lookingRegionNames);
            setLookingRegionNames(lookingRegionNames);
        }
    }

    function populateDoneRegionNames() {
        if (postRec.doneRegionIDs) {
            const doneRegionNames = filterModelNamesByIDs(regions, postRec.doneRegionIDs);
            // console.log('doneRegionNames', doneRegionNames);
            setDoneRegionNames(doneRegionNames);
        }
    }

    function populateLookingGearTypeNames() {
        if (postRec.lookingGearTypeIDs) {
            const lookingGearTypeNames = filterModelNamesByIDs(gearTypes, postRec.lookingGearTypeIDs);
            // console.log('lookingGearTypeNames', lookingGearTypeNames);
            setLookingGearTypesNames(lookingGearTypeNames);
        }
    }

    function populateDoneGearTypeNames() {
        if (postRec.doneGearTypeIDs) {
            const doneGearTypeNames = filterModelNamesByIDs(gearTypes, postRec.doneGearTypeIDs);
            // console.log('doneGearTypeNames', doneGearTypeNames);
            setDoneGearTypeNames(doneGearTypeNames);
        }
    }

    function filterModelNamesByIDs (modelRecs, modelAttrs) {
        // console.log('filterModelNamesByIDs modelRecs', modelRecs);
        // console.log('filterModelNamesByIDs modelAttrs', modelAttrs);
        const namesArr = [];
        modelRecs.forEach((eachModel) => {
            // console.log('eachModel', eachModel);
            const idxOf = modelAttrs.indexOf(eachModel.id);
            // console.log('idxOf', idxOf);
            if (idxOf !== -1) {
                namesArr.push(eachModel.name);
            }
        })
        return namesArr
    }

    // useEffect( console.log('postModelNames', postModelNames);
    // useEffect(() => {
    //     console.log('postModelNames', postModelNames);
    // }, [postModelNames]);

    // function postRecAttrsLookingItems (eachGridItem, idx) {
    //     return (
    //         <div>
    //             <p>
    //                 Looking
    //             </p>
    //             <Item key={`${idx}-${eachGridItem[0]}`} elevation={idx}>
    //                 <Typography>
    //                     Title: {eachGridItem[1].title}
    //                 </Typography>
    //                 <Typography>
    //                     Begin: {startDateTrunc ? startDateTrunc : eachGridItem[1].begin}
    //                 </Typography>
    //             </Item>
    //         </div>
    //     )
    // }

    // useEffect(() => {
    //
    // }, [ampUser, postRec])

    return (
        <div>
            <div>
                <Typography>Single Post Screen</Typography>
                <Fab sx={{ mb: 1 }} variant="extended" color="primary" aria-label="add">
                    <NavigationIcon sx={{ mr: 1 }} />
                    Reply
                </Fab>
            </div>
            <div>
                <Stack
                    // direction={{ xs: 'column', sm: 'row' }}
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="stretch"
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                >
                    <Divider />
                    {postAttrsList()}
                    <Divider />
                    {ampUserAttrsList()}
                    <Divider />
                    {postRecLookingAttrsList()}
                    <Divider />
                    {postRecAttrsDoneItems()}
                </Stack>
            </div>
            <Divider />
            <div>
                <Fab sx={{ mt: 1 }} variant="extended" color="primary" aria-label="add">
                    <NavigationIcon sx={{ mr: 1 }} />
                    Reply
                </Fab>
            </div>
        </div>
    )
}