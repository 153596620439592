import React, { useEffect, useState, useContext } from "react";
import RouterBreadcrumbs from "../components/router/RouterBreadcrumbs";
import { useParams } from "react-router-dom";
import {breadcrumbRoutesFisheriesScreen, routerPathsToNames} from "../consts";
import BasicRouterMenu from "../components/router/BasicRouterMenu";
import {breadcrumbRoutesSingleFishery} from "../consts";
import { useLocation } from "react-router-dom";
import FisheryPostsCardList from "../components/lists/FisheryPostsCardList";
import {modelIdNameFactory} from "../consts";
import {groupPostFisheryRecsByFishery} from "../data/group/postFisheries";
import PostFisheryContext from "../data/contexts/PostFisheryContext";
import PortContext from "../data/contexts/PortContext";
import FisheryContext from "../data/contexts/FisheryContext";
import GearTypeContext from "../data/contexts/GearTypeContext";
import RegionContext from "../data/contexts/RegionContext";
// import {groupPostRegionRecsByRegion} from "../data/group/postRegions";
import DevStateContext from "../data/contexts/DevStateContext";


export default function FisheryPostsScreen(props) {
    const [fisheryRouterPaths, setFisheryRouterPaths] = useState({})
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([])
    const [fisheryName, setFisheryName] = useState('')
    const [thisFisheryPosts, setThisFisheryPosts] = useState([])
    const [regionIdNames, setRegionIdNames] = useState({});
    const [fisheryIdNames, setFisheryIdNames] = useState({});
    const [gearTypeIdNames, setGearTypeIdNames] = useState({});
    const [portIdNames, setPortIdNames] = useState([]);
    const [postRecsByFishery, setPostRecsByFishery] = useState({});
    const [showLogs, setShowLogs] = useState(false);

    const { portRecs } = useContext(PortContext);
    const { fisheryRecs } = useContext(FisheryContext);
    const { gearTypeRecs } = useContext(GearTypeContext);
    const { regionRecs } = useContext(RegionContext);
    const { postFisheryRecs } = useContext(PostFisheryContext);
    const { devStateObj } = useContext(DevStateContext);

    const location = useLocation();

    // useEffect(() => { setShowLogs(devStateObj.showLogs);
    useEffect(() => {
        if (devStateObj) {
            setShowLogs(devStateObj.showLogs);
        }
    }, [devStateObj]);

    // useEffect( console.log('FisheryPostsScreen devStateObj', devStateObj);
    useEffect(() => {
        if (showLogs) {
            console.log('FisheryPostsScreen devStateObj', devStateObj);
        }
    }, [devStateObj, showLogs]);

    // setFisheryName(location.state.fisheryRec.name);
    useEffect(() => {
        if (location.state) {
            setFisheryName(location.state.fisheryRec.name);
        }
    }, [props, location]);

    // setFisheryRouterPaths(routerPathsToNamesRes['fishery']);
    useEffect(() => {
        const routerPathsToNamesRes = routerPathsToNames(props.routerPaths.routerPaths)
        setFisheryRouterPaths(routerPathsToNamesRes['fishery']);
    }, [props]);

    const params = useParams();
    const fisheryPath = params['fisheryName'];

    // setThisFisheryPosts(thisFisheryPostsVal);
    useEffect(() => {
        if (params) {
            const thisFisheryPostsVal = postRecsByFishery[fisheryPath];
            setThisFisheryPosts(thisFisheryPostsVal);
        }
    }, [postRecsByFishery, location]);

    // setBreadcrumbRoutes(breadcrumbRoutesSingleRegion(fisheryPath)
    useEffect(() => {
        if (fisheryPath) {
            setBreadcrumbRoutes(breadcrumbRoutesSingleFishery(fisheryPath) || [])
        } else {
            setBreadcrumbRoutes(breadcrumbRoutesFisheriesScreen() || [])
        }
    }, [props]);

    // useEffect( setFisheries(props.fisheries); setFisheryIdNames(modelIdNameFactory(props.fisheries));
    useEffect(() => {
        if (fisheryRecs) {
            // setFisheries(props.fisheries);
            setFisheryIdNames(modelIdNameFactory(fisheryRecs));
        }
    }, [fisheryRecs]);

    // useEffect( setRegions(props.regions); setRegionIdNames(modelIdNameFactory(props.regions));
    useEffect(() => {
        if (regionRecs) {
            setRegionIdNames(modelIdNameFactory(regionRecs));
        }
    }, [regionRecs]);

    // useEffect( setGearTypes(props.gearTypes); setGearTypeIdNames(modelIdNameFactory(props.gearTypes));
    useEffect(() => {
        if (gearTypeRecs) {
            setGearTypeIdNames(modelIdNameFactory(gearTypeRecs));
        }
    }, [gearTypeRecs]);

    // useEffect( setPorts(props.ports); setPortIdNames(modelIdNameFactory(props.ports));
    useEffect(() => {
        if (portRecs) {
            setPortIdNames(modelIdNameFactory(portRecs));
        }
    }, [portRecs]);

    // useEffect( setPostRecsByFishery(groupPostFisheryRecsByFishery(props.postFisheryRecs));
    useEffect(() => {
        if (postFisheryRecs) {
            (async () => {
                const recsByFishery = await groupPostFisheryRecsByFishery(postFisheryRecs);
                setPostRecsByFishery(recsByFishery);
            })();
        }
    }, [postFisheryRecs]);

    // useEffect( console.log('postRecsByFishery', postRecsByFishery);
    useEffect(() => {
        if (showLogs) {
            console.log('postRecsByFishery', postRecsByFishery);
        }
    }, [postRecsByFishery, showLogs]);

    // <FisheryPostsCardList
    function fisheryUserPostsList() {
        if (postFisheryRecs) {
            return (
                <FisheryPostsCardList
                    showLogs={showLogs}
                    ampUser={props.ampUser}
                    fisheryName={fisheryName}
                    fisheryPosts={postRecsByFishery}
                    thisFisheryPosts={thisFisheryPosts}
                    fisheryIdNames={fisheryIdNames}
                    regionIdNames={regionIdNames}
                    gearTypeIdNames={gearTypeIdNames}
                    portIdNames={portIdNames}
                />
            )
        } else {
            // console.error('render default transition element, e.g. a spinner')
            return (
                <div>
                    <p>
                        default transition element
                    </p>
                </div>
            )
        }
    }

    return (
        // <div className={"Posts-body"}>
        <div style={{
            backgroundColor: 'lightgray',
            minHeight: '15vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 'calc(10px + 2vmin)',
            color: 'white',
            marginBottom: '5vh'
        }}>
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes}/>
        <div>
        </div>
            <BasicRouterMenu menuRouterPaths={fisheryRouterPaths}/>
            <div>
                {fisheryUserPostsList()}
            </div>
        </div>
    )
}