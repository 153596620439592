import React, { useEffect, useState, useContext } from "react";
import RouterBreadcrumbs from "../components/router/RouterBreadcrumbs";
import {breadcrumbRoutesUserPostsScreen, routerPathsToNames, modelIdNameFactory} from "../consts";
import BasicRouterMenu from "../components/router/BasicRouterMenu";
import { useLocation } from "react-router-dom";
import UserPostsCardList from "../components/lists/UserPostsCardList";
import FisheryContext from "../data/contexts/FisheryContext";
import GearTypeContext from "../data/contexts/GearTypeContext";
import PortContext from "../data/contexts/PortContext";
import RegionContext from "../data/contexts/RegionContext";
import UserPostsContext from "../data/contexts/UserPostsContext";
import DevStateContext from "../data/contexts/DevStateContext";


export default function UserPostsScreen(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [userPosts, setUserPosts] = useState([])
    const [userPostsRouterPaths, setUserPostsRouterPaths] = useState({})
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([])
    // const [networkConnected, setNetworkConnected] = useState( true)
    // const [portName, setPortName] = useState('')
    // const [postPortRecs, setPostPortRecs] = useState([])
    // const [thisPortPosts, setThisPortPosts] = useState([])

    const [fisheries, setFisheries] = useState([]);
    const [fisheryIdNames, setFisheryIdNames] = useState({});
    // const [lookingFisheryIdNames, setLookingFisheryIdNames] = useState({});
    // const [doneFisheryIdNames, setDoneFisheryIdNames] = useState({});

    const [gearTypes, setGearTypes] = useState([]);
    const [gearTypeIdNames, setGearTypeIdNames] = useState({});
    // const [lookingGearTypeIdNames, setLookingGearTypeIdNames] = useState({});
    // const [doneGearTypeIdNames, setDoneGearTypeIdNames] = useState({});

    const [ports, setPorts] = useState([]);
    const [portIdNames, setPortIdNames] = useState([]);
    // const [lookingPortIdNames, setLookingPortIdNames] = useState({});
    // const [donePortIdNames, setDonePortIdNames] = useState({});

    const [regions, setRegions] = useState([]);
    const [regionIdNames, setRegionIdNames] = useState({});
    // const [lookingRegionIdNames, setLookingRegionIdNames] = useState({});
    // const [doneRegionIdNames, setDoneRegionIdNames] = useState({});

    const [showDelete, setShowDelete] = useState(false);
    const [showLogs, setShowLogs] = useState(false);

    const { regionRecs } = useContext(RegionContext);
    const { portRecs } = useContext(PortContext);
    const { gearTypeRecs } = useContext(GearTypeContext);
    const { fisheryRecs } = useContext(FisheryContext);
    const { userPostRecs } = useContext(UserPostsContext);
    const { devStateObj } = useContext(DevStateContext);

    const location = useLocation();

    // useEffect( setShowLogs(devStateObj.showLogs);
    useEffect(() => {
        if (devStateObj) {
            setShowLogs(devStateObj.showLogs);
        }
    }, [devStateObj]);

    // useEffect( console.log('UserPostsScreen devStateObj', devStateObj);
    useEffect(() => {
        if (showLogs) {
            console.log('UserPostsScreen devStateObj', devStateObj);
        }
    }, [devStateObj, showLogs]);

    // setPortName(location.state.portRec.name) setPostPortRecs(location.state.postPortRecs);
    useEffect(() => {
        if (location.state) {
            setAmpUser(location.state.ampUser)
            setShowDelete(location.state.showDelete)
        } else {
            setAmpUser(props.ampUser);
        }
    }, [props, location]);

    // useEffect(() => { console.log('location.state', location.state);
    useEffect(() => {
        if (showLogs) {
            console.log('location.state', location.state);
        }
        // console.log('location.state', location.state);
    }, [location, showLogs]);

    // useEffect(() => { console.log('ampUser', ampUser);
    useEffect(() => {
        if (showLogs) {
        console.log('ampUser', ampUser);
        }
    }, [ampUser, showLogs]);

    // useEffect(() => { setUserPosts(userPostRecs);
    useEffect(() => {
        if (userPostRecs) {
            setUserPosts(userPostRecs);
        }
    }, [userPostRecs]);

    // setPortRouterPaths(routerPathsToNamesRes['port']);
    useEffect(() => {
        const routerPathsToNamesRes = routerPathsToNames(props.routerPaths.routerPaths)
        setUserPostsRouterPaths(routerPathsToNamesRes['port']);
    }, [props]);

    // setBreadcrumbRoutes(breadcrumbRoutesUserPostsScreen() || []);
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesUserPostsScreen() || [])
    }, [props]);

    // useEffect( setFisheries(props.fisheries); setFisheryIdNames(modelIdNameFactory(props.fisheries));
    useEffect(() => {
        if (fisheryRecs) {
            setFisheries(fisheryRecs);
            setFisheryIdNames(modelIdNameFactory(fisheryRecs));
        }
    }, [fisheryRecs]);

    // useEffect( setGearTypes(props.gearTypes); setGearTypeIdNames(modelIdNameFactory(props.gearTypes));
    useEffect(() => {
        if (gearTypeRecs) {
            setGearTypes(gearTypeRecs);
            setGearTypeIdNames(modelIdNameFactory(gearTypeRecs));
        }
    }, [gearTypeRecs]);

    // useEffect( setPorts(props.ports); setPortIdNames(modelIdNameFactory(props.ports));
    useEffect(() => {
        if (portRecs) {
            setPorts(portRecs);
            setPortIdNames(modelIdNameFactory(portRecs));
        }
    }, [portRecs]);

    // useEffect( setRegions(props.regions); setRegionIdNames(modelIdNameFactory(props.regions));
    useEffect(() => {
        if (regionRecs) {
            setRegions(regionRecs);
            setRegionIdNames(modelIdNameFactory(regionRecs));
        }
    }, [regionRecs]);

    // useEffect(() => {
    //     setNetworkConnected(props.networkConn);
    // }, [props.networkConn]);

    // <PortPostsCardList
    function userPostsList() {
        if (userPosts && ampUser) {
            return (
                <UserPostsCardList
                    showLogs={showLogs}
                    ampUser={ampUser}
                    showDelete={showDelete}
                    userPosts={userPosts}
                    fisheries={fisheries}
                    gearTypes={gearTypes}
                    ports={ports}
                    regions={regions}
                    fisheryIdNames={fisheryIdNames}
                    gearTypeIdNames={gearTypeIdNames}
                    portIdNames={portIdNames}
                    // networkConnected={networkConnected}
                    regionIdNames={regionIdNames}
                />
            )
        } else {
            // console.error('render default transition element, e.g. a spinner')
            return (
                <div>
                    <p>
                        default transition element
                    </p>
                </div>
            )
        }
    }

    return (
        <div style={{
            backgroundColor: 'lightgray',
            minHeight: '15vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 'calc(10px + 2vmin)',
            color: 'white',
            marginBottom: '5vh'
        }}>
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes}/>
            <div>
            </div>
            <BasicRouterMenu menuRouterPaths={userPostsRouterPaths}/>
            <div>
                {userPostsList()}
            </div>

        </div>
    )
}