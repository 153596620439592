import React, {useState, useCallback, useEffect} from 'react';
import AmpUserContext from "../contexts/AmpUserContext";
import {devStateNow} from "../../consts";

const AmpUserDataProvider = ({ children }) => {
    const [ampUserRec, setAmpUserRec] = useState(null);

    // useEffect( console.log('AmpUserDataProvider ampUserRec', ampUserRec);
    useEffect(() => {
        if (devStateNow.stateNow === 'dev') {
        console.log('AmpUserDataProvider ampUserRec', ampUserRec);
        }
    }, [ampUserRec]);

    return (
        <AmpUserContext.Provider value={{ ampUserRec: ampUserRec, setAmpUserRec }}>
            {children}
        </AmpUserContext.Provider>
    );
};

export default AmpUserDataProvider;
