import React, { useState, useEffect, useContext } from 'react';
import './styles/App.css';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { Amplify, DataStore, SortDirection } from "aws-amplify";
import FisheryDataProvider from "./data/providers/FisheryDataProvider";
import GearTypeDataProvider from "./data/providers/GearTypeDataProvider";
import PortDataProvider from "./data/providers/PortDataProvider";
import RegionDataProvider from "./data/providers/RegionDataProvider";
import RegionContext from "./data/contexts/RegionContext";
import AmpUserContext from "./data/contexts/AmpUserContext";
import SubPaymentContext from "./data/contexts/SubPaymentContext";
import SubPaymentDataProvider from "./data/providers/SubPaymentDataProvider";
import {RequireAuth} from "./components/auth/RequireAuth";
import {Login} from "./components/auth/Login";
import onAmpUserCreate from "./data/create/ampUser";
import { devStateNow } from "./consts";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import aws_exports from './aws-exports';
import HomeScreen from "./screens/HomeScreen";
import RegionsScreen from "./screens/RegionsScreen";
import PortsScreen from "./screens/PortsScreen";
import AmpUserDataProvider from "./data/providers/AmpUserDataProvider";
import FisheriesScreen from "./screens/FisheriesScreen";
import GearTypesScreen from "./screens/GearTypesScreen";
import PrimarySearchAppBar from "./components/header/PrimarySearchAppBar";
import {AmpUser} from "./models";
import CreateUserSubScreen from "./screens/CreateUserSubScreen";
import ApplyForUserSubScreen from "./screens/ApplyForUserSubScreen";
import UserSubPreviewScreen from "./screens/UserSubPreviewScreen";
import SubscribeCancelScreen from "./screens/SubscribeCancelScreen";
import SubscribeSuccessScreen from "./screens/SubscribeSuccessScreen";
import PostPreviewScreen from "./screens/PostPreviewScreen";
import PostSuccessScreen from "./screens/PostSuccessScreen";
import CountryDataProvider from "./data/providers/CountryDataProvider";
import WaitlistScreen from "./screens/WaitlistScreen";
import WrappedRegionPosts from "./components/router/WrappedRegionPosts";
import WrappedPortPosts from "./components/router/WrappedPortPosts";
import WrappedFisheryPosts from "./components/router/WrappedFisheryPosts";
import WrappedGearTypePosts from "./components/router/WrappedGearTypePosts";
import UserDetailsScreen from "./screens/UserDetailsScreen";
import WrappedUserPosts from "./components/router/WrappedUserPosts";
import WrappedSinglePost from "./components/router/WrappedSinglePost";
import DevStateProvider from "./data/providers/DevStateProvider";
import WrappedCreatePost from "./components/router/WrappedCreatePost";
import MobileFooter from "./components/footer/MobileFooter";
import {ResponsiveViewportProvider} from "./data/providers/ResponsiveViewportProvider";
Amplify.configure(aws_exports);

// TODO - MAKE A FINAL PASS TO REMOVE ALL CONSOLE.LOGS,
//  ENSURE EVERYTHING IS WORKING AS EXPECTED,
//  THEN MERGE

function App() {
  const [ampUser, setAmpUser] = useState(null);
  const [ampUserSubPaymentRecs, setAmpUserSubPaymentRecs] = useState([]);
  const [updateAmpUser, setUpdateAmpUser] = useState(null);
  const [currentUserSub, setCurrentUserSub] = useState(null);
  const [currentSubPayment, setCurrentSubPayment] = useState(null);
  const [regionData, setRegionData] = useState([]);
  const [showLogs, setShowLogs] = useState(false);
  const [routerPaths, setRouterPaths] = useState({});

  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const { authStatus } = useAuthenticator(context => [context.authStatus]);

    const { regionRecs } = useContext(RegionContext);
    const { setAmpUserRec } = useContext(AmpUserContext);

    const { currentSubPaymentRec: currentSubPaymentRecContext,
        setShowLogsSubPayment,
        setAmpUserRecSubPayment,
        currentUserSub: currentUserSubContext,
        triggerUpdatePaymentSubRecs,
        resetOnSignOut } = useContext(SubPaymentContext);

    // useEffect( setAmpUserRec(ampUser);
    // useEffect(() => {
    //     if (showLogs) {
    //     console.log('APP ampUser', ampUser);
    //     }
    //     setAmpUserRec(ampUser);
    // }, [ampUser, showLogs]);

      useEffect(() => {
          if (regionRecs) {
              setRegionData(regionRecs);
          }
      }, [regionRecs]);

    // useEffect( DataStore.clear();
    // useEffect(() => {
    //     DataStore.clear();
    // }, []);

    // useEffect( setShowLogs(
    useEffect(() => {
        if (devStateNow.stateNow === "prod"){
            setShowLogs(false);
        } else {
            setShowLogs(true);
        }
    }, [devStateNow]);

    // useEffect( setShowLogs( Context
    useEffect(() => {
        setShowLogsSubPayment(showLogs);
    }, [showLogs]);

    // useEffect( setAmpUserRecSubPayment(ampUser); setAmpUserRecUserSub(ampUser);
    useEffect(() => {
        if (showLogs) {
            console.log('App.js setAmpUserRecSubPayment(ampUser);', ampUser);
        }
        // console.log('App.js setAmpUserRecSubPayment(ampUser);', ampUser);
        setAmpUserRecSubPayment(ampUser);
    }, [ampUser]);

    // useEffect( setCurrentSubPayment(currentSubPaymentRecContext); setCurrentSubPaymentUserSubContext(currentSubPaymentRecContext);
    useEffect(() => {
        setCurrentSubPayment(currentSubPaymentRecContext);
    }, [currentSubPaymentRecContext]);

    // useEffect( setCurrentUserSub(currentUserSubRecContext);
    useEffect(() => {
        if (currentUserSubContext) {
            setCurrentUserSub(currentUserSubContext);
        }
    }, [currentUserSubContext]);

    // useEffect( reset state values on user sign out
    // useEffect(() => {
    //     if (authStatus !== 'authenticated') {
    //         setAmpUserSubPaymentRecs([]);
    //         setCurrentSubPayment(null);
    //         setCurrentUserSub(null);
    //         // setCreateUserSub(false);
    //         // setRegions([]);
    //         setAmpUser(null);
    //         setAmpUserRec(null); // to the provider
    //         // localStorage.clear();
    //         resetOnSignOut(true);
    //     }
    // }, [user, authStatus, ampUser]);

    function updateOnSignOut() {
        // if (authStatus !== 'authenticated') {
            setAmpUserSubPaymentRecs([]);
            setCurrentSubPayment(null);
            setCurrentUserSub(null);
            // setCreateUserSub(false);
            // setRegions([]);
            setAmpUser(null);
            setAmpUserRec(null); // to the provider
            // localStorage.clear();
            resetOnSignOut(true);
        // }
    }

    function signOutAmpUser(){
        updateOnSignOut();
        signOut();
        // .then(data => console.log(data))
        // .catch(err => console.log(err));
        // window.location.reload();
    }

    // useEffect( signOutAmpUser();
    useEffect(() => {
        if (!user) {
            signOutAmpUser();
        }
        if (showLogs) {
            console.log('current user', user);
        }
    }, [user, showLogs]);

    // useEffect( console.log('auth user', user);
    useEffect(() => {
        if (showLogs) {
            console.log('$$$$$$ auth user', user);
        }
    }, [user, showLogs, authStatus]);

    // useEffect( console.log('auth user', user);
    useEffect(() => {
        if (showLogs) {
        console.log('authStatus', authStatus);
        }
    }, [authStatus]);

    function triggerAmpUserUpdate(triggerUpdate) {
        // console.log('triggerAmpUserUpdate triggerUpdate', triggerUpdate);
        setUpdateAmpUser(triggerUpdate);
        // console.log('triggerUpdatePaymentSubRecs', triggerUpdatePaymentSubRecs);
        // console.log('triggerUpdatePaymentSubRecs triggerUpdate', triggerUpdate);
        triggerUpdatePaymentSubRecs(triggerUpdate);
    }

    // useEffect( console.log('updateAmpUser', updateAmpUser);
    useEffect(() => {
        if (showLogs) {
            console.log('updateAmpUser', updateAmpUser);
        }
    }, [updateAmpUser, showLogs]);

    // DataStore.observeQuery(AmpUser
    useEffect (() => {
        // if (user.attributes.verified) {
        if (authStatus === 'authenticated' && user) {
            const subscription = DataStore.observeQuery(
                AmpUser,
                f => f.email.eq(user.attributes.email),
                // f => f,
                {
                    sort: s => s.name(SortDirection.ASCENDING)
                }
            ).subscribe(snapshot => {
                const { items, isSynced } = snapshot;
                if (showLogs) {
                    console.log(`AMPUSER [Snapshot] item count: ${items.length}, isSynced: ${isSynced}`);
                    console.log('AMPUSER [Snapshot] items', items);
                }
                if (isSynced) {
                    if (items.length > 0) {
                        if (ampUser !== items[0]) {
                            setAmpUser(items[0]);
                            setUpdateAmpUser(false);
                        }
                    } else {
                        // TODO - NEED BETTER TOGGLE LOGIC HERE, CREATES DUPE RECORDS,
                        //  IF A COGNITO USER EXISTS, ALWAYS CREATES AMP USER,
                        //  CORRECT AMP USER HAS EMAIL REC
                        if (authStatus === 'authenticated') {
                            onAmpUserCreate(user);
                        }
                    }
                }
            });
        }
    }, [user, authStatus, showLogs, updateAmpUser]);
    // }, [user, authStatus, showLogs]);

    // useEffect( console.log('ampUserSubPaymentRecs', ampUserSubPaymentRecs)
    useEffect(() => {
        if (showLogs) {
            console.log('>>> ampUserSubPaymentRecs', ampUserSubPaymentRecs)
        }
    }, [ampUserSubPaymentRecs, showLogs]);

    // async function updateAmpUser() {
    //     // direct query AmpUser rec
    //     DataStore.clear()
    //         .then((res) => {
    //             // DataStore.clear()
    //             //     .then((res) => {
    //             DataStore.start()
    //                 .then((res) => {
    //                     const currentAmpUserId = `${ampUser.id}`;
    //                     // const updatedAmpUser = await DataStore.query(AmpUser, currentAmpUserId);
    //                     return DataStore.query(AmpUser, currentAmpUserId)
    //                 })
    //                 .then((res) => {
    //                     console.log('!!! return DataStore.query(AmpUser, currentAmpUserId) res', res)
    //                     if (ampUser && res) {
    //                         setAmpUser(res);
    //                     }
    //                     setTimeout(() => {
    //                         window.location.reload();
    //                     }, 2000);
    //                 })
    //             // })
    //         })
    //     ////////////////
    //     // const currentAmpUserId = `${ampUser.id}`;
    //     // const updatedAmpUser = await DataStore.query(AmpUser, currentAmpUserId);
    //     // if (ampUser && updatedAmpUser) {
    //     //     setAmpUser(updatedAmpUser)
    //     // }
    // }

    // async function updateAmpUser(ampUserId) {
    //     console.log('ampUserId', ampUserId);
    //     // direct query AmpUser rec
    //     console.log('ampUser', ampUser);
    //     const currentAmpUserId = `${ampUser.id}`;
    //     console.log('currentAmpUserId', currentAmpUserId);
    //     // const updatedAmpUser = await DataStore.query(AmpUser, currentAmpUserId);
    //     // if (ampUser && updatedAmpUser) {
    //     //     setAmpUser(updatedAmpUser)
    //     //     setTimeout(() => {
    //     //         window.location.reload();
    //     //     }, 500);
    //     // }
    // }

    // // DataStore.observeQuery( SubPayment
    // useEffect (() => {
    //     if (ampUser) {
    //         const ampUserId = ampUser.id
    //         const subscription = DataStore.observeQuery(
    //             SubPayment,
    //             // sb => sb,
    //             sb => sb.ampuserID.eq(ampUserId),
    //             {
    //                 sort: s => s.createdAt(SortDirection.ASCENDING)
    //             }
    //         ).subscribe(snapshot => {
    //             const { items, isSynced } = snapshot;
    //             // console.log(`SubPayment pr [Snapshot] item count: ${items.length}, isSynced: ${isSynced}`);
    //             // console.log('SubPayment pr [Snapshot] items', items);
    //             if (isSynced) {
    //                 setAmpUserSubPaymentRecs(items);
    //             }
    //         });
    //     }
    // }, [ampUser]);
    //
    // // useEffect( setCurrentSubPayment(newestLivePayment[0]);
    // useEffect(() => {
    //     const successLivePayments = ampUserSubPaymentRecs.filter(eachRec => eachRec.success === true);
    //     // TODO - IF > 1 UNEXPIRED ITEM IN ARRAY,
    //     // console.log('successLivePayments', successLivePayments);
    //     // TODO - VALIDATE THIS ACTUALLY RETURNS THE MOST RECENT REC (IF NOT, CHANGE THE OPERANDS)
    //     const newestLivePayment = successLivePayments.sort((a, b) => {
    //         if (a.processorEventCreated < b.processorEventCreated) {
    //             return -1;
    //         }
    //         if (a.processorEventCreated > b.processorEventCreated) {
    //             return 1;
    //         }
    //         return 0;
    //     });
    //     if (showLogs) {
    //         console.log('newestLivePayment', newestLivePayment);
    //     }
    //     if (successLivePayments && newestLivePayment.length > 0) {
    //         setCurrentSubPayment(newestLivePayment[0]);
    //         successLivePayments.slice(1).forEach(async (eachElem) => {
    //             // if model is expired, update .status to EXPIRED
    //             const paymentModelIsExpired = subPaymentModelIsExpired(eachElem);
    //             if (paymentModelIsExpired) {
    //                 await updateSubPaymentStatus(eachElem, SubPaymentStatus.EXPIRED);
    //             }
    //         });
    //         // TODO - NOW Check for a matching A USERSUB
    //     }
    // }, [ampUserSubPaymentRecs, showLogs]);

    // useEffect( console.log('currentUserSub', currentUserSub);
    useEffect(() => {
        if (showLogs) {
            console.log('currentUserSub', currentUserSub || 'missing currentUserSub');
        }
    }, [currentUserSub, currentSubPayment, showLogs]);

    // async function clearStartDataStore()
    // async function clearStartDataStore() {
    //     DataStore.start().then((res) => {
    //         // console.log('DataStore.start() res', res);
    //         DataStore.stop().then((res) => {
    //             // console.log('DataStore.stop() res', res);
    //             // return res;
    //             DataStore.start().then((res) => {
    //                 // console.log('Datastore start clear start res', res)
    //             }).catch((err) => {
    //                 console.error('err', err)
    //             })
    //         }).catch((err) => {
    //             console.error('err', err)
    //         })
    //     }).catch((err) => {
    //         console.error('err', err)
    //     })
    //     window.location.reload();
    // }

    // setRouterPaths(allPaths)
    useEffect(() => {
        const allPaths = {
            // 'country': countries,
            'region': regionData,
        }
        setRouterPaths(allPaths)
        // console.warn('App update state: allPaths', allPaths || 'no allPaths')
    },[regionData]);

    // TODO - START HERE, WHY ARE ROUTES FOR MODELS NOT FOUND?
  return (
    <div className="App">
        <AmpUserDataProvider>
        <BrowserRouter>
            <div>
                <DevStateProvider>
                <PrimarySearchAppBar
                    ampUser={ampUser}
                    routerPaths={{routerPaths}}
                    routerPathNames={{routerPaths}}
                    authStatus={authStatus}
                    signOut={signOutAmpUser}
                    userSub={currentUserSub}
                    subPayment={currentSubPayment}
                />
                </DevStateProvider>
            </div>
            <div className="app-container">
                <div className="main-content">
            <Routes>
                <Route path="/" element={<HomeScreen />} />
                <Route path="waitlist" element={<WaitlistScreen />} />
                <Route path="/login" element={<Login />} />
                <Route path="/regions"
                       element={
                           <RegionDataProvider>
                           <RegionsScreen />
                            </RegionDataProvider>
                       } />
                <Route path="region/:regionName"
                       element={<WrappedRegionPosts routerPaths={{routerPaths}} ampUser={ampUser} />}/>
                <Route path="/ports"
                       element={
                    <PortDataProvider>
                           <PortsScreen />
                    </PortDataProvider>
                       } />
                <Route path="ports/:portName"
                       element={<WrappedPortPosts routerPaths={{routerPaths}} ampUser={ampUser} />}/>
                <Route path="/fisheries"
                       element={
                    <FisheryDataProvider>
                           <FisheriesScreen />
                    </FisheryDataProvider>
                       } />
                <Route path="fishery/:fisheryName"
                       element={<WrappedFisheryPosts routerPaths={{routerPaths}} ampUser={ampUser} />}/>
                <Route path="/gear-types"
                       element={
                    <GearTypeDataProvider>
                           <GearTypesScreen />
                    </GearTypeDataProvider>
                       } />
                <Route path="gear-type/:gearTypeName"
                       element={<WrappedGearTypePosts routerPaths={{routerPaths}} ampUser={ampUser} />}/>
                <Route path="create"
                       element={<WrappedCreatePost routerPaths={routerPaths} ampUser={ampUser}/>}/>
                <Route path="post-preview"
                       element={
                        <PortDataProvider>
                            <RegionDataProvider>
                           <RequireAuth>
                               <PostPreviewScreen ampUser={ampUser} showLogs={showLogs}/>
                           </RequireAuth>
                            </RegionDataProvider>
                            </PortDataProvider>
                       }/>
                <Route path="post-success" // TODO - make this a wrapped component
                       element={
                    <RegionDataProvider>
                        <FisheryDataProvider>
                            <GearTypeDataProvider>
                                <CountryDataProvider>
                           <RequireAuth>
                               <PostSuccessScreen ampUser={ampUser} />
                           </RequireAuth>
                                </CountryDataProvider>
                            </GearTypeDataProvider>
                        </FisheryDataProvider>
                    </RegionDataProvider>
                       }/>
                <Route path="subscribe"
                       element={
                           <RequireAuth>
                               <CreateUserSubScreen routerPaths={routerPaths} />
                           </RequireAuth>
                       } />
                <Route path="subscription-preview"
                       element={
                            <DevStateProvider>
                               <RequireAuth>
                                   <UserSubPreviewScreen routerPaths={routerPaths} ampUser={ampUser} />
                               </RequireAuth>
                            </DevStateProvider>
                       }/>
                <Route path="subscription-cancel"
                       element={
                           // <RequireAuth>
                           <SubscribeCancelScreen routerPaths={routerPaths} />
                           // </RequireAuth>
                       }
                />
                <Route path="subscription-success"
                       element={
                           // <RequireAuth>
                           <SubscribeSuccessScreen
                               ampUser={ampUser}
                               routerPaths={routerPaths}
                               currentUserSub={currentUserSub}
                               currentSubPayment={currentSubPayment}
                               showLogs={showLogs}
                               setUpdateAmpUser={setUpdateAmpUser}
                               triggerAmpUserUpdate={triggerAmpUserUpdate}
                           />
                           // </RequireAuth>
                       }
                />
                <Route path="apply-for-subscription"
                       element={ // TODO - START HERE, UPDATE COMPONENT TO USE PROVIDER, CONTINUE ON CREATE POST PATH
                           <RequireAuth>
                               <ApplyForUserSubScreen showLogs={showLogs} routerPaths={routerPaths} />
                           </RequireAuth>
                       }/>
                <Route path="/user"
                       element={
                           <RequireAuth>
                               <UserDetailsScreen
                                   showLogs={showLogs}
                                   routerPaths={{routerPaths}}
                                   ampUser={ampUser}
                                   userSub={currentUserSub}
                                   subPayment={currentSubPayment}
                                   triggerAmpUserUpdate={triggerAmpUserUpdate}
                               />
                           </RequireAuth>
                    }
                />
                <Route path="user-posts"
                       element={
                            <WrappedUserPosts
                                routerPaths={{routerPaths}}
                                ampUser={ampUser}
                                userSub={currentUserSub}
                                subPayment={currentSubPayment} />
                    }
                />
                <Route path="/post/:postRouterPath"
                       element={
                               <WrappedSinglePost
                                    routerPaths={{routerPaths}}
                                    ampUser={ampUser}
                                    showLogs={showLogs}
                               />
                       }
                />
            </Routes>
                </div>
            <DevStateProvider>
                <MobileFooter className="mobileFooter"/>
            </DevStateProvider>
            </div>
        </BrowserRouter>
        </AmpUserDataProvider>
    </div>
  );
}

export default () => (
    <Authenticator.Provider>
        <ResponsiveViewportProvider>
        <AmpUserDataProvider>
        <RegionDataProvider>
            <SubPaymentDataProvider>
            <App />
            </SubPaymentDataProvider>
        </RegionDataProvider>
        </AmpUserDataProvider>
        </ResponsiveViewportProvider>
    </Authenticator.Provider>
);
