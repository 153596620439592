import { useLocation, Navigate } from 'react-router-dom';
import {UserStatus} from "../../models";

export function RequireSub({ children, ampUser }) {
    const location = useLocation();

    // Check if ampUser.userStatus is 'SUBSCRIBER'
    if (ampUser?.userStatus !== UserStatus.SUBSCRIBER) {
        return <Navigate to="/apply-for-subscription" state={{ from: location }} replace />;
    }

    return children;
}
