import React from 'react';
import Button from '@mui/material/Button';

export default function ExternalLinkButton({ url, label, ...props }) {
    // You can include additional styles here if needed
    const styles = {
        // Your styles here
    };

    return (
        <Button
            component="a"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            size={props.size || "small"}
            variant={props.variant || "contained"}
            sx={{
                textTransform: 'none',
                fontSize: ['5vw', '3vw', '2vw', '1.5vw'],
                margin: props.margin || '1rem',
                backgroundColor: '#5F9EA0',
                color: 'white',
                ...styles,
                ...props.sx }} // combines the styles with any sx props passed
            {...props} // Passes any additional props to the Button component
        >
         <span style={{ padding: '0 10px' }}>{label}</span>
        </Button>
    );
}
