import React, { useEffect, useState } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import StyledListItemText from "../text/StyledListItemText";
// import ListSubheader from '@mui/material/ListSubheader';
import StyledUserAttrListItem from "./StyledUserAttrListItem";
import {Button} from "@mui/material";
import BaseStyledButton from "../buttons/BaseStyledButton";
import BaseStyledLinkButton from "../links/BaseStyledLinkButton";
import BaseNavigateButton from "../links/BaseNavigateButton";
import StyledExternalLinkButton from "../links/StyledExternalLinkButton";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
// import TextField from "@mui/material/TextField";
import { useNavigate, } from 'react-router-dom';
import {updateAmpUserUserAttrs,
    fetchAmpUser,
    convertTimestampToDate,
    convertTimestampToDateWithOffset, toDollarString} from '../../consts'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import {useTheme} from "@mui/material/styles";
import Box from '@mui/material/Box';
import StyledUserAttrListSection from "./StyledUserAttrListSection";
import StyledTextField from "../text/StyledTextField";
import Stack from '@mui/material/Stack';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, shareAttrs, theme) {
    return {
        fontWeight:
            shareAttrs.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const shareContactAttrs = [
    'email',
    'phone',
    ]

export default function UserAttributesList(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [ampUserName, setAmpUserName] = useState(null);
    const [ampUserPhone, setAmpUserPhone] = useState(null);
    const [userSub, setUserSub] = useState(null);
    const [subPayment, setSubPayment] = useState(null);
    const [newUserValues, setNewUserValues]  = useState(false)
    // const [initShareAttrs, setInitShareAttrs] = useState([]);
    const [shareAttrs, setShareAttrs] = useState([]);
    const [selectedShareAttrs, setSelectedShareAttrs] = useState([]);
    // const [disableUpdateShareAttrsBtn, setDisableUpdateShareAttrsBtn] = useState(true);
    const [receiptURL, setReceiptURL] = useState(null);
    const [showLogs, setShowLogs] = useState(false);

    // const location = useLocation();
    let navigate = useNavigate();
    const theme = useTheme();

    const isMobile = useMediaQuery('(max-width:1200px)'); // 600px is typically the width for 'sm' breakpoint

    useEffect(() => {
        if (props?.showLogs) {
            setShowLogs(props.showLogs);
        }
    }, [props.showLogs]);

    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
            setAmpUserName(props.ampUser.name);
            setAmpUserPhone(props.ampUser.phone);
            setShareAttrs(props.ampUser.share_attrs);
            // setInitShareAttrs(props.ampUser.share_attrs);
            setSelectedShareAttrs(props.ampUser.share_attrs);
        }
    }, [props.ampUser]);

    function arraysAreIdentical(arr1, arr2) {
        if (arr1.length !== arr2.length) return false;
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) return false;
        }
        return true;
    }

    function arraysHaveSameElements(arr1, arr2) {
        const sortedArr1 = arr1.slice().sort();
        const sortedArr2 = arr2.slice().sort();
        return arraysAreIdentical(sortedArr1, sortedArr2);
    }

    // useEffect to log ampUser
    // useEffect(() => {
    //     console.log('ampUser', ampUser);
    // }, [ampUser]);

    useEffect(() => {
        if (subPayment) {
            setReceiptURL(subPayment?.bodyBlob?.event?.data?.object?.charges?.data[0]?.receipt_url);
        }
    }, [subPayment]);

    useEffect(() => {
        if (showLogs) {
        console.log('>>> receiptURL', receiptURL);
        }
    }, [receiptURL, showLogs]);

    // useEffect to log newUserValues
    // useEffect(() => {
    //     console.log('newUserValues', newUserValues);
    // }, [newUserValues]);

    useEffect(() => {
        if (props?.userSub) {
            setUserSub(props.userSub);
        }
    }, [props.userSub]);

    useEffect(() => {
        if (showLogs) {
            console.log('userSub', userSub);
        }
    }, [userSub, showLogs]);

    useEffect(() => {
        if (props?.subPayment) {
            setSubPayment(props.subPayment);
        }
    }, [props.subPayment]);

    useEffect(() => {
        if (showLogs) {
            console.log('subPayment', subPayment);
        }
    }, [subPayment, showLogs]);

    // handleLookingTextFieldChange setLookingTextFieldValue(event.target.value);
    const handleAmpUserNameTextFieldChange = (event) => {
        setAmpUserName(event.target.value);
    };

    // useEffect to log ampUserName
    // useEffect(() => {
    //     console.log('ampUserName', ampUserName);
    // }, [ampUserName]);

    const handleAmpUserPhoneTextFieldChange = (event) => {
        setAmpUserPhone(event.target.value);
    };

    // useEffect to log ampUserPhone
    // useEffect(() => {
    //     console.log('ampUserPhone', ampUserPhone);
    // }, [ampUserPhone]);

    useEffect(() => {
        if (ampUser) {
            if (ampUserName !== ampUser.name) {
                setNewUserValues(true);
            }
            if (ampUserPhone !== ampUser.phone) {
                setNewUserValues(true);
            }
            const shareAttrsChanged = !arraysHaveSameElements(selectedShareAttrs, ampUser.share_attrs);
            if (shareAttrsChanged) {
                setNewUserValues(true);
            }
            if (ampUserName === ampUser.name
                && ampUserPhone === ampUser.phone
                && !shareAttrsChanged) {
                setNewUserValues(false);
            }
        }
    }, [ampUserPhone, ampUserName, selectedShareAttrs, ampUser, shareAttrs]);
    const handleShareAttrsChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedShareAttrs(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    // TODO - MAKE SURE OTHER AMPUSER RECS DON'T LEAK INTO THIS ONE
    return (
        <List
            sx={{
                // width: '100%',
                width: {
                    xs: '100%',    // 0px Full width on extra-small and small devices
                    sm: '100%',     // 600px Full width on extra-small and small devices
                    md: 420,       // 900px Fixed width on medium devices and up
                    // md: '100%',       // Fixed width on medium devices and up
                    lg: 600,        // 1200px Fixed width on large devices and up
                },
                maxWidth: 600,
                minWidth: 300,
                bgcolor: 'background.paper',
                color: 'black',
                position: 'relative',
                overflowX: 'hidden',
                overflowY: 'auto',
                '& ul': { padding: 0 },
            }}
        >
            {ampUser ? <Stack>
                <ListItemText
                    sx={{
                        color: 'black'
                }}
                >
                    {<h3>Your Account</h3>}
                </ListItemText>
                <StyledUserAttrListItem key={`item-${ampUser.userStatus}-n`}>
                    <div>
                        <FormControl
                            row="true"
                            component="fieldset"
                            variant="standard"
                        >
                            <StyledTextField
                                id="filled-multiline-flexible"
                                label="Name"
                                maxRows={4}
                                value={ampUserName}
                                onChange={handleAmpUserNameTextFieldChange}
                                sx={{
                                    // Any additional styles you want to apply
                                    backgroundColor: 'white',
                                }}
                            />
                        </FormControl>
                    </div>
                </StyledUserAttrListItem>
                <StyledUserAttrListItem key={`item-${ampUser.email}`}>
                    <StyledListItemText
                        text={`email: ${ampUser.email}`}
                        style={{
                            padding: '0 1rem'
                        }}
                    />
                </StyledUserAttrListItem>
                <StyledUserAttrListItem key={`item-${ampUser.userStatus}-k`}>
                    <div>
                        <FormControl
                            row="true"
                            component="fieldset"
                            variant="standard"
                        >
                            <StyledTextField
                                id="filled-multiline-flexible"
                                label="Phone"
                                type="tel"
                                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                required
                                maxLength="10"
                                maxRows={4}
                                value={ampUserPhone}
                                onChange={handleAmpUserPhoneTextFieldChange}
                                variant="outlined"
                            />
                        </FormControl>
                    </div>
                </StyledUserAttrListItem>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        margin: '1rem'
                    }}
                >
                <StyledUserAttrListItem
                    key={`item-${ampUser.userStatus}`}
                    style={{
                        margin: '0vh 0.75vw 0vh 0.75vw',
                        padding: '0vh 0.75vw 0vh 0.75vw'
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: ['5vw', '3vw', '2vw', '1.5vw'],
                            // marginBottom: '1rem'
                            margin: '0',
                            padding: '0'
                        }}
                    >
                        {`Status: ${ampUser.userStatus}`}
                    </Typography>
                </StyledUserAttrListItem>
                    <StyledUserAttrListItem
                        key={`item-${ampUser.userStatus}-x`}
                        style={{
                            margin: '0vh 0.75vw 0vh 0.75vw',
                            padding: '0vh 0.75vw 0vh 0.75vw'
                        }}
                    >
                    {ampUser.userStatus !== 'SUBSCRIBER' ? <BaseStyledLinkButton
                        linkTarget={'/create'}
                        buttonText={'Subscribe'}
                        style={{
                            // marginBottom: '1rem'
                        }}
                    /> : null}
                </StyledUserAttrListItem>
                </Box>
                <Divider />
                <StyledUserAttrListSection key={`item-share-p`}>
                    <ListItemText
                        primary={
                            <h4 style={{ margin: 0, textAlign: 'center' }}>Share Contact Info</h4>
                        }
                    />
                </StyledUserAttrListSection>
                <StyledUserAttrListItem key={`item-${ampUser.userStatus}-l`}>
                    <div>
                        <FormControl
                            sx={{
                                m: 1,
                                // width: 300
                                width: {
                                    xs: '90%',    // 0px Full width on extra-small and small devices
                                    sm: '90%',     // 600px Full width on extra-small and small devices
                                    // md: 420,       // 900px Fixed width on medium devices and up
                                    // md: '100%',       // Fixed width on medium devices and up
                                    // lg: 600,        // 1200px Fixed width on large devices and up
                                },
                                maxWidth: 420,
                                minWidth: 300,
                        }}
                        >
                    <InputLabel id="demo-multiple-chip-label">Share</InputLabel>
                    <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={selectedShareAttrs}
                        onChange={handleShareAttrsChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {shareContactAttrs.map((name) => (
                            <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, shareContactAttrs, theme)}
                            >
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                        </FormControl>
                    </div>
                </StyledUserAttrListItem>
                {ampUser ? <BaseStyledButton
                    disabled={!newUserValues}
                    onClick={() => updateAmpUserUserAttrs(ampUser, {
                        name: ampUserName,
                        phone: ampUserPhone,
                        share_attrs: selectedShareAttrs
                    })
                        .then(() => {
                            fetchAmpUser(ampUser.id).then((res) => {
                                setAmpUser(res);
                                setNewUserValues(false);
                                setAmpUserName(res.name);
                                setAmpUserPhone(res.phone);
                            })
                        })}
                    label={'Update User Data'}
                    style={{
                      // marginBottom: '1rem'
                      margin: '0.25rem 1rem 1rem 1rem'
                    }}
                    size={'large'}
                />
                    : null}
                <Divider />
                {subPayment && userSub ? <div>
                    <ListItemText
                        sx={{
                            color: 'black'
                        }}
                    >
                        {<h3>Payment</h3>}
                    </ListItemText>
                    <ListItem key={`item-${ampUser.userStatus}-g`}>
                        <StyledListItemText
                            text={`amount: ${toDollarString(subPayment.amount)}`}
                            style={{
                                padding: '0 1rem'
                            }}
                        />
                    </ListItem>
                    { receiptURL ? <StyledExternalLinkButton
                            url={`${receiptURL}`}
                            label={'Payment Receipt'}

                        /> : null}
                    <Divider />
                    <ListItemText
                        sx={{
                            color: 'black'
                        }}
                    >
                        {<h3>Subscription</h3>}
                    </ListItemText>
                    <ListItem key={`item-userSub.status-${userSub.status}`}>
                        <StyledListItemText
                            text={`status: ${userSub.userSubStatus}`}
                            style={{
                                padding: '0 1rem'
                            }}
                        />
                    </ListItem>
                    <ListItem key={`item-userSub.subscriptionType-${userSub.subscriptionType}`}>
                        <StyledListItemText
                            text={`subscription type: ${userSub.subscriptionType}`}
                            style={{
                                padding: '0 1rem'
                            }}
                        />
                    </ListItem>
                    <ListItem key={`item-userSub.end-${userSub.begin}`}>
                        <StyledListItemText
                            text={`subscription start: ${convertTimestampToDate(userSub.begin)}`}
                            style={{
                                padding: '0 1rem'
                            }}
                        />
                    </ListItem>
                    <ListItem key={`item-userSub.end-${userSub.end}`}>
                        <StyledListItemText
                            text={`subscription ends: ${convertTimestampToDateWithOffset(userSub.end, subPayment.amount)}`}
                            style={{
                                padding: '0 1rem'
                            }}
                        />
                    </ListItem>
                    <Divider />
                </div> : ampUser.userStatus === 'SUBSCRIBER' ? <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        margin: '1rem'
                    }}
                    >
                    <Typography>
                        We are still adding your subscription info...
                    </Typography>
                    <Typography>
                        Please check back later.
                    </Typography>
                </Box> : <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        margin: '1rem'
                    }}
                    >
                    <Typography
                        sx={{
                            fontSize: ['5vw', '3vw', '2vw', '1.5vw'],
                            marginBottom: '0.5rem'
                        }}
                    >
                        You have no active subscription
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: ['5vw', '3vw', '2vw', '1.5vw'],
                            marginBottom: '1rem'
                        }}
                    >
                        Subscribe to create posts
                    </Typography>
                    <BaseStyledLinkButton
                        linkTarget={'/create'}
                        buttonText={'Subscribe'}
                        style={{
                          marginBottom: '1rem'
                        }}
                    />
                </Box>}
                <Divider />
                {ampUser ? <BaseNavigateButton
                    label='Your Posts'
                    navTarget='/user-posts'
                    navState={{
                        ampUser: props.ampUser,
                        showDelete: true
                    }}
                /> : null}
            </Stack> : <Button>Sign In</Button>}
        </List>
    );
}