import React, { useEffect, useState, useContext } from "react";
import Stack from '@mui/material/Stack';
import RouterBreadcrumbs from "../components/router/RouterBreadcrumbs";
import {breadcrumbRoutesPortsScreen, deployState} from "../consts"
import PortCardList from "../components/lists/PortCardList";
import PortContext from "../data/contexts/PortContext";
import WaitlistUserCreateForm from "../ui-components/WaitlistUserCreateForm";

export default function PortsScreen(props) {
    const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([])

    const { portRecs } = useContext(PortContext);

    // setBreadcrumbRoutes
    useEffect(() => {
        setBreadcrumbRoutes(breadcrumbRoutesPortsScreen() || [])
        // console.error('breadcrumbRoutes', breadcrumbRoutes)
    }, []);

    return (
        <div className={"home-screen-cards"}>
            {deployState === 'signup' ? <WaitlistUserCreateForm onSuccess={() => {
                alert('Added to the Fishingigs wait list successfully!');
            }
            }/> : null}
            <RouterBreadcrumbs breadcrumbsArr={breadcrumbRoutes} />
            <Stack spacing={2}>
                <PortCardList
                    portRecs={portRecs}
                />
            </Stack>
        </div>
    );
}