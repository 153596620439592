import React, {useState, useEffect} from 'react';
import { Button } from '@mui/material';
import {useNavigate, useLocation} from "react-router-dom";
import RegionContext from "../data/contexts/RegionContext";
import FisheryContext from "../data/contexts/FisheryContext";
import GearTypeContext from "../data/contexts/GearTypeContext";
import CountryContext from "../data/contexts/CountryContext";
import BaseStyledLinkButton from "../components/links/BaseStyledLinkButton";
import BaseNavigateButton from "../components/links/BaseNavigateButton";

// TODO - start here, verify crete new post flow works
export default function PostSuccessScreen(props) {
    const [postRec, setPostRec] = useState(null);
    const [ampUser, setAmpUser] = useState(null);

    let navigate = useNavigate();
    let location = useLocation();

    // TOOD - TOGGLE BACK ON TO VIEW NEW POST AS SINGLE POST
    // useEffect( setPostRec(location.state.newRec);
    // useEffect(() => {
    //     if (location.state.newRec) {
    //         setPostRec(location.state.newRec);
    //     }
    // }, [location]);

    // useEffect( setAmpUser(location.state.ampUser);
    useEffect(() => {
        if (location?.state?.ampUser) {
            setAmpUser(location.state.ampUser);
        } else {
            if (props?.ampUser) {
                setAmpUser(props.ampUser);
            }
        }
    }, [location, props.ampUser]);



    return (
        <div>
            <h4>Post Success!</h4>
            <div className={'Post-success-body'}>
                {/*<Button onClick={() => navigate(`/post/${postRec.routerPath}`, { state: {ampUser, postRec} })} >*/}
                {/*    view new post*/}
                {/*</Button>*/}
                <Button onClick={() => navigate('/')} >
                    Home
                </Button>
                <BaseStyledLinkButton
                    buttonText={'Home'}
                    linkTarget={`/`}
                />
                <BaseStyledLinkButton
                    buttonText={'View Your Posts'}
                    linkTarget={'/user-posts'}
                />
                <BaseNavigateButton
                    label='View Your Posts'
                    navTarget='/user-posts'
                    navState={{
                        ampUser: ampUser,
                        showDelete: true
                    }}
                />
            </div>
        </div>
    )
}