import React, {useState, useEffect} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { CircularProgress } from '@mui/material';
import {AmpUser, UserStatus} from "../models";
import {fetchAmpUser, updateAmpUserUserStatus} from "../consts";
import {Amplify} from "aws-amplify";
// import BaseLinkButton from "../components/links/BaseLinkButton";
import BaseStyledLinkButton from "../components/links/BaseStyledLinkButton";
import VerticalButtonStack from "../components/buttons/VerticalButtonStack";
import {DataStore} from "@aws-amplify/datastore";
import aws_exports from '../aws-exports'
Amplify.configure(aws_exports);

export default function SubscribeSuccessScreen(props) {
    const [ampUser, setAmpUser] = useState(null);
    const [updatedAmpUser, setUpdatedAmpUser] = useState(null);
    const [ampUserIsSubscriber, setAmpUserIsSubscriber] = useState(false);
    const [currentUserSub, setCurrentUserSub] = useState(null);
    const [currentSubPayment, setCurrentSubPayment] = useState(null);
    const [showLogs, setShowLogs] = useState(false);
    const [lastSyncTimestamp, setLastSyncTimestamp] = useState(Date.now());
    const [isLoadingAmpUser, setIsLoadingAmpUser] = useState(true);
    const [isLoadingCurrentUserSub, setIsLoadingCurrentUserSub] = useState(true);
    const [isLoadingCurrentSubPayment, setIsLoadingCurrentSubPayment] = useState(true);
    const [sessionIdParam, setSessionIdParam] = useState(null);

    const externalReferrer = document.referrer;
    const queryParams = new URLSearchParams(window.location.search);
    const sessionID = queryParams.get('session_id');

    // useEffect( setShowLogs(
    useEffect(() => {
        if (props?.showLogs){
            setShowLogs(props.showLogs);
        } else {
            setShowLogs(false);
        }
    }, [props]);

    useEffect(() => {
        if (sessionID) {
            setSessionIdParam(sessionID);
        }
    }, [sessionID]);

    useEffect(() => {
        if (showLogs) {
            console.log('SubscribeSuccessScreen(props) sessionID', sessionID);
        }
    }, [sessionID, showLogs]);

    useEffect(() => {
        if (showLogs) {
            console.log('!!!>>> SubscribeSuccessScreen(props) document.referrer', document.referrer);
            console.log('!!!>>> SubscribeSuccessScreen(props) externalReferrer', externalReferrer);
        }
    }, [externalReferrer, showLogs, document.referrer]);

    // useEffect(() => {
    //     if (props?.ampUser && props?.ampUser?.userStatus !== UserStatus.SUBSCRIBER) {
    //         updateAmpUserUserStatus(props.ampUser, UserStatus.SUBSCRIBER)
    //     }
    // }, [props]);

    useEffect(() => {
        // Define an async function inside the effect
        const updateStatus = async () => {
            if (props?.ampUser && props?.ampUser.userStatus !== UserStatus.SUBSCRIBER) {
                if (props?.ampUser?.lastPaymentHash === null) {
                    setAmpUserIsSubscriber(false);
                }
                else if (props?.ampUser?.lastPaymentHash === sessionIdParam) {
                    await updateAmpUserUserStatus(props.ampUser, UserStatus.SUBSCRIBER);
                    props.triggerAmpUserUpdate(Date.now());
                }
            }
        };
        // Call the async function
        updateStatus();
    }, [props.ampUser, sessionIdParam]); // Add specific dependencies if possible instead of the whole props object


    useEffect(() => {
        if (props?.ampUser) {
            setAmpUser(props.ampUser);
            setAmpUserIsSubscriber(props.ampUser.userStatus === UserStatus.SUBSCRIBER);
            setIsLoadingAmpUser(false);
        }
    }, [props]);

    useEffect(() => {
        if (showLogs) {
            console.log('SubscribeSuccessScreen(props) ampUser', ampUser);
            console.log('SubscribeSuccessScreen(props) ampUserIsSubscriber', ampUserIsSubscriber);
        }
    }, [ampUser, showLogs, ampUserIsSubscriber]);

    // useEffect(() => {
    //     // Set the state to the current timestamp when the component mounts
    //     const currentTimestamp = Date.now();
    //     setLastSyncTimestamp(currentTimestamp);
    // }, []); // The empty dependency array ensures this runs only once, when the component mounts

    // useEffect(() => {
    //     // Function to update the lastSyncTimestamp
    //     const updateSyncTimestamp = () => {
    //         setLastSyncTimestamp((prevTimestamp) => {
    //             const baseTimestamp = prevTimestamp ?? Date.now();
    //             return baseTimestamp + 5000; // Adds 5 seconds
    //         });
    //     };
    //
    //     let timer;
    //     if (currentUserSub === null) {
    //         // Set a timer to update the timestamp after 5 seconds
    //         timer = setTimeout(updateSyncTimestamp, 5000);
    //     }
    //
    //     return () => {
    //         // Clear the timer when the component unmounts or when currentUserSub changes
    //         clearTimeout(timer);
    //     };
    // }, [currentUserSub]); // Dependency on currentUserSub

    useEffect(() => {
        // Only start the interval if currentUserSub is null
        let interval;
        if (currentUserSub === null) {
            interval = setInterval(() => {
                setLastSyncTimestamp((prevTimestamp) => {
                    // If prevTimestamp is null, initialize it with the current time
                    const baseTimestamp = prevTimestamp ?? Date.now();
                    return baseTimestamp + 60000; // Adds 7 seconds
                });
            }, 60000); // Update every 7 seconds
        }

        return () => {
            // Clear the interval when the component unmounts or when currentUserSub changes
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [currentUserSub]); // Dependency array includes currentUserSub


    useEffect(() => {
        if (showLogs) {
            console.log('SubscribeSuccessScreen lastSyncTimestamp', lastSyncTimestamp);
        }
    }, [lastSyncTimestamp, showLogs]);

    // TODO - START HERE, GET AMPUSER TO UPDATE ON SUBSCRIBE SUCCESS
    // useEffect(() => {
    //     if (props?.setUpdateAmpUser) {
    //         props.setUpdateAmpUser(true);
    //     }
    // }, []);

    // TODO - START HERE, KEEP WORKING ON UPDATING AMPUSER ON SUBSCRIBE SUCCESS, PLUS OTHER RECS
    useEffect(() => {
        if (currentUserSub === null && props?.triggerAmpUserUpdate) {
            props.triggerAmpUserUpdate(lastSyncTimestamp);
        }
        return () => {
            // Cleanup code goes here
            // This will be executed when the component unmounts
            setCurrentUserSub(null);
        };
    }, [lastSyncTimestamp, currentUserSub]);

    // TODO - START HERE, CHECK NEW ATTRS ON AMPUSER, UPDATE STATUS TO SUBSCRIBER IF NEEDED, BASED ON NEW HASH ATTR
    // useEffect(() => {
    //     if (!ampUserIsSubscriber && ampUser) {
    //         // setAmpUserIsSubscriber(props.ampUser.userStatus === UserStatus.SUBSCRIBER);
    //         const updatedAmpUserRes = fetchAmpUser(ampUser.id).then((res) => {
    //             if (showLogs) {
    //                 console.log('@@@ updatedAmpUserRes', res)
    //             }
    //             return res;
    //         });
    //         console.log('@@@ updatedAmpUserRes', updatedAmpUserRes)
    //         if (updatedAmpUserRes.userStatus === UserStatus.SUBSCRIBER) {
    //             // setUpdatedAmpUser(updatedAmpUserRes);
    //             setAmpUserIsSubscriber(true);
    //         }
    //     }
    // }, [ampUser, lastSyncTimestamp]);

    // useEffect(() => {
    //     if (ampUserIsSubscriber === null || ampUserIsSubscriber === false) {
    //         // Refresh the browser screen
    //         window.location.reload();
    //     }
    // }, [lastSyncTimestamp, ampUserIsSubscriber]); // Depend on lastSyncTimestamp and ampUserIsSubscriber


    // TODO - FIGURE OUT HOW TO CLEAR DATA STORE AND NOT THROW, may need to add some ampuser model attrs

    // useEffect( DataStore.clear();
    // useEffect(() => {
    //     DataStore.clear();
    // }, []);

    // RECENT
    // useEffect(() => {
    //     setIsLoadingAmpUser(true); // Start loading when the effect runs
    //
    //     // ... rest of your code ...
    //
    //     if (props.ampUser) {
    //         setAmpUser(props.ampUser);
    //         setIsLoadingAmpUser(false); // End loading when ampUser is set
    //     }
    // }, [props]);


    // RECENT
    // useEffect(() => {
    //     // const promisesArr = []
    //     // if (props.clearDataStore) {
    //     //     props.clearDataStore()
    //     // }
    //     // DataStore.clear()
    //     //     .then((res) => {
    //     //         if (props.updateAmpUser) {
    //     //             props.updateAmpUser()
    //     //         }
    //     //     })
    //     //     .catch((err) => {
    //     //         console.error('err', err);
    //     //     })
    //     console.log('!!! SubscribeSuccessScreen(props) ampUser', ampUser);
    //     if (props?.updateAmpUser && ampUser?.id) {
    //         // if (ampUser.userStatus !== 'SUBSCRIBER') {
    //         // if (ampUser.userStatus !== 'Subscriber') {
    //         if (ampUser.userStatus !== UserStatus.SUBSCRIBER) {
    //             props.updateAmpUser(ampUser.id)
    //                 .then((res) => {
    //                     if (showLogs) {
    //                         console.log('NOT SUBSCRIBER updateAmpUser res', res);
    //                     }
    //             })
    //         } else {
    //             if (showLogs) {
    //                 console.log('IS SUBSCRIBER ampUser', ampUser);
    //             }
    //         }
    //     }
    // }, [ampUser, props]);

    // useEffect(() => {
    //     if (ampUser) {
    //         const fetchData = async () => {
    //             if (ampUserIsSubscriber === null || ampUserIsSubscriber === false) {
    //                 // Call your DataStore query
    //                 const updatedAmpUser = await DataStore.query(AmpUser, c => c.id.eq(ampUser.id));
    //                 // Handle the result of the query
    //                 if (showLogs) {
    //                     console.log('>>> SubscribeSuccessScreen(updatedAmpUser)', updatedAmpUser);
    //                 }
    //             }
    //         };
    //         fetchData();
    //     }
    // }, [lastSyncTimestamp, ampUserIsSubscriber]); // Depend on lastSyncTimestamp and ampUserIsSubscriber

    // Rest of your component code...

    // useEffect( setAmpUser(props.ampUser);
    // useEffect(() => {
    //     if (props.ampUser) {
    //         setAmpUser(props.ampUser);
    //     }
    // }, [props]);

    // useEffect( setCurrentUserSub(props.currentUserSub);
    useEffect(() => {
        if (props?.currentUserSub) {
            setCurrentUserSub(props.currentUserSub);
        }
    }, [props]);

    // useEffect( setCurrentUserSub(props.currentUserSub);
    useEffect(() => {
        if (props?.currentSubPayment) {
            setCurrentSubPayment(props.currentSubPayment);
        }
    }, [props]);

    // useEffect( console.log('SubscribeSuccessScreen(props) currentUserSub', currentUserSub);
    useEffect(() => {
        if (showLogs) {
            console.log('SubscribeSuccessScreen(props) currentUserSub', currentUserSub || 'missing currentUserSub');
        }
    }, [currentUserSub, showLogs]);

    // useEffect( setCurrentSubPayment(props.currentSubPayment);
    useEffect(() => {
        if (props?.currentSubPayment) {
            setCurrentSubPayment(props.currentSubPayment);
        }
    }, [props]);

    // useEffect( console.log('SubscribeSuccessScreen(props) currentSubPayment', currentSubPayment);
    useEffect(() => {
        if (showLogs) {
            console.log('SubscribeSuccessScreen(props) currentSubPayment', currentSubPayment || 'missing currentSubPayment');
        }
    }, [currentSubPayment, showLogs]);

    // useEffect( console.log('currentUserSub', currentUserSub);
    useEffect(() => {
        if (showLogs) {
            console.log('currentUserSub', currentUserSub || 'missing currentUserSub');
        }
    }, [currentUserSub, showLogs]);

    return (
        <div className={'Post-success-body'}>
            <Card
                sx={{
                    maxWidth: 480,
                    minWidth: 320,
                    marginBottom: '1rem'
            }}
            >
                <CardContent>
                    <Typography
                    sx={{
                        fontSize: ['5vw', '4vw', '3vw', '2vw']
                    }}
                    >
                        Subscription Success!
                    </Typography>
                </CardContent>
                <Divider />
            </Card>
            {/*{isLoadingAmpUser ? (*/}
            {/*    <div>*/}
            {/*        <Typography>Loading...</Typography>*/}
            {/*    <CircularProgress />*/}
            {/*    </div>*/}
            {!ampUserIsSubscriber ? (
                    <div
                        style={{
                            marginBottom: '1rem'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: ['5vw', '4vw', '3vw', '2vw'],
                                color: 'black'
                            }}
                        >
                            Updating your user profile...
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: ['4vw', '3vw', '2vw', '1.5vw'],
                                color: 'black'
                            }}
                        >
                            (this may take a few seconds)
                        </Typography>
                        {/*<CircularProgress />*/}
                    </div>
                ) : null}
            {!currentSubPayment || !currentUserSub ? (
                    <div
                        style={{
                            marginBottom: '1rem'
                        }}
                    >
                        <Divider
                            color={'black'}
                        />
                        <Typography
                            sx={{
                                fontSize: ['5vw', '4vw', '3vw', '2vw'],
                                color: 'black'
                            }}
                        >
                            Creating your new subscription...
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: ['4vw', '3vw', '2vw', '1.5vw'],
                                color: 'black'
                            }}
                        >
                            (this may take a few minutes)
                        </Typography>
                        {/*<CircularProgress />*/}
                    </div>
                ) : null}
            {/*{ampUserIsSubscriber && currentSubPayment && currentUserSub ? (*/}
            {ampUserIsSubscriber ? (
                <VerticalButtonStack>
                    <BaseStyledLinkButton
                        buttonText="Create Post"
                        linkTarget="/create"
                    />
                    <BaseStyledLinkButton
                        buttonText="Home"
                        linkTarget="/"
                    />
                    <BaseStyledLinkButton
                        buttonText="Fisheries"
                        linkTarget="/fisheries"
                    />
                    <BaseStyledLinkButton
                        buttonText="Regions"
                        linkTarget="/regions"
                    />
                    <BaseStyledLinkButton
                        buttonText="Gear Types"
                        linkTarget="/gear-types"
                    />
                </VerticalButtonStack>
            ) : <CircularProgress />}
        </div>
    )
}